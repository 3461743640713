import React, { Component } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Transition, Dimmer, Loader } from "semantic-ui-react";

import { WelcomeScreen, SCREENS, AuthenticationScreen, CheckInfoScreen, OrderScreen, ThankYouScreen, KioskDisabled, SelectLabelScreen } from "./screens/index";
import APP_CONFIG from "../../util/app-config";
import Service from "./service";

const initialState = {
  screen: SCREENS.FIRST_SCREEN,
  communication: null,
  extraPhone: null,
  additionalRemark: null,
  customerAcceptedAgreementsIDs: null,
  customerName: "",
  customerSignature: "",
  isLoading: false,
};

class Kiosk extends Component {
  state = {
    ...initialState,
    kiosk: null,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { match, i18n } = this.props;

    this.setState({ isLoading: true }, () => {
      Service.init({ private_key: match.params.kioskKey, frontend_version: APP_CONFIG.FRONTEND_VERSION })
        .then(res => {
          const kiosk = res?.data?.data ? res.data.data : {};
          i18n.changeLanguage(kiosk.language_code || "en-GB");
          this.setState({ kiosk, isLoading: false });
        })
        .catch(err => {
          console.error("Error getting Kiosk", err);

          setTimeout(() => {
            this.init();
          }, 60000);

          this.setState({ isLoading: false });
        });
    });
  };

  handleStartOver = (stateUpdate = {}) => {
    if (stateUpdate.kiosk) {
      this.handleResetState(stateUpdate);
      return;
    }

    this.setState({ isLoading: true }, () => {
      Service.getStatus({ private_key: this.props.match.params.kioskKey, frontend_version: APP_CONFIG.FRONTEND_VERSION })
        .then(response => {
          const { active, confirmation_title, confirmation_image_url } = response?.data?.data ? response.data.data : {};
          this.handleResetState({ kiosk: { ...this.state.kiosk, active, confirmation_title, confirmation_image_url } });
        })
        .catch(() => this.handleResetState(stateUpdate));
    });
  };

  handleStatusUpdate = () => {
    Service.getStatus({ private_key: this.props.match.params.kioskKey })
      .then(response => {
        const { active, confirmation_title, confirmation_image_url } = response?.data?.data ? response.data.data : {};
        this.setState(prevState => ({ kiosk: { ...prevState.kiosk, active, confirmation_title, confirmation_image_url } }));
      })
      .catch(() => console.error("Failed to get status"));
  };

  handleGoBackToStartScreen = () => this.setState({ screen: SCREENS.FIRST_SCREEN });

  handleGoToNextScreen = () => this.setState({ screen: this.state.screen + 1 });

  handleGoToNextScreenWithState = state => this.setState({ ...state, screen: this.state.screen + 1 });

  handleGoToOrderScreenWithState = state => this.setState({ ...state, screen: SCREENS.ORDER });

  handleGoToPreviousScreen = () => this.setState({ screen: this.state.screen - 1 });

  handleResetState = (stateUpdate = {}) => {
    this.setState({ ...initialState, kiosk: { ...this.state.kiosk, ...stateUpdate.kiosk } });
  };

  render() {
    const { screen, communication, kiosk, extraPhone, additionalRemark, customerAcceptedAgreementsIDs, customerName, customerSignature, isLoading } = this.state;
    const { softmode } = this.props;

    if (isLoading) {
      return (
        <Transition visible={true} animation="fade" duration={300}>
          <Dimmer active={true}>
            <Loader size="massive" />
          </Dimmer>
        </Transition>
      );
    }

    if (!kiosk) return null;

    if (!kiosk.active) return <KioskDisabled />;

    switch (screen) {
      default:
      case SCREENS.WELCOME:
        return <WelcomeScreen kiosk={kiosk} onStatusUpdate={this.handleStatusUpdate} onGoToNextScreen={this.handleGoToNextScreen} softmode={softmode} />;

      case SCREENS.AUTH_OPTIONS:
        return (
          <AuthenticationScreen
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
            onGoToNextScreenWithState={this.handleGoToNextScreenWithState}
            onGoToOrderScreenWithState={this.handleGoToOrderScreenWithState}
            onGoBackToStartScreen={this.handleGoBackToStartScreen}
            onAbort={this.handleStartOver}
            onStartOver={this.handleStartOver}
            kiosk={kiosk}
          />
        );

      case SCREENS.CHECK_INFO:
        return <CheckInfoScreen onGoToNextScreenWithState={this.handleGoToNextScreenWithState} communication={communication} onAbort={this.handleStartOver} />;

      case SCREENS.ORDER:
        return <OrderScreen onGoToNextScreenWithState={this.handleGoToNextScreenWithState} communication={communication} kiosk={kiosk} onAbort={this.handleStartOver} />;

      case SCREENS.SELECT_LABEL:
        return (
          <SelectLabelScreen
            kiosk={kiosk}
            communication={communication}
            additionalRemark={additionalRemark}
            agreements={customerAcceptedAgreementsIDs}
            extraPhone={extraPhone}
            customerName={customerName}
            customerSignature={customerSignature}
            onGoToNextScreen={this.handleGoToNextScreen}
            onAbort={this.handleStartOver}
          />
        );

      case SCREENS.THANK_YOU:
        return <ThankYouScreen onStartOver={this.handleStartOver} kiosk={kiosk} />;
    }
  }
}

export default withRouter(withTranslation()(Kiosk));
