import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox, faSlash } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import MainLayout from "../../../../layout";

import "./index.scss";

const KeylockerDisabledScreen = ({ t }) => {
  const content = (
    <div className="KeylockerDisabledScreen">
      <div className="KeyLockerTitle">
        <div className="KeylockerDisabledCompositeIcon">
          <FontAwesomeIcon icon={faSlash} />
          <br />
          <FontAwesomeIcon icon={faInbox} />
        </div>
        <span>{t("kl_temporarily_unavailable").message || "Temporarily unavailable"}!</span>
      </div>
      <div className="KeylockerDisabledMessage">
        {t("kl_disabled_message").message || "This key locker is currently unavailable, we apologize for any inconvenience."}
      </div>
    </div>
  );
  return <MainLayout content={content} />;
};

export default withTranslation()(KeylockerDisabledScreen);
