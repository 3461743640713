import React from "react";
import { withTranslation } from "react-i18next";

import "./index.scss";

const PageNotFound = ({ t }) => {
  return (
    <div className="PageNotFoundContainer">
      <div className="PageNotFound">
        <div className="header">
          <div>Whoops!</div>
          <div>{t("page_not_found").message || "Page not found"}</div>
        </div>
        <div>
          <img src="/Car_image_404.png" alt="car background" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PageNotFound);
