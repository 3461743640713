import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Transition, Loader, Dimmer } from "semantic-ui-react";
import ReactGA from "react-ga";

import { normalizeCommunicationData, QUESTION_RESULT_STATUS } from "../../util/common";
import { ANSWERS } from "../../util/answers";
import { TYRE_POSITION } from "../../util/tyre";
import { DeskCommunication } from "../../modules/";

import { IENotSupported, PageNotFound } from "../";

import Service from "./service";

class DeskCommunicationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failedToLoad: false,
      communication: null,
      settings: null,
      routeKey: props.location.hash ? props.location.hash.substring(1) : null,
    };
  }

  componentDidMount = () => {
    const { routeKey } = this.state;

    if (routeKey) {
      this.setState({ loading: true }, () => {
        Service.loadCommunication(routeKey)
          .then(result => {
            const communication = result.data?.data || {};

            if (normalizeCommunicationData(communication, this.props.i18n)) {
              if (communication.results?.length) {
                communication.results.forEach(r => {
                  if (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements?.length) {
                    r.tyre_replacements.forEach(rep => {
                      rep.selected = rep.customer_answer;
                    });
                  }

                  if(r.status === ANSWERS.NOT_ANSWERED && (r.question_status === QUESTION_RESULT_STATUS.ADVISED || r.question_status === QUESTION_RESULT_STATUS.NECESSARY))
                    r.status = r.customer_approved ? ANSWERS.ADD_TO_CART : ANSWERS.NOT_OK;
                });
              }

              this.setState({
                communication,
                settings: communication.settings,
                loading: false,
              });

              if (communication.settings.google_analytics_id) ReactGA.initialize(communication.settings.google_analytics_id);
            } else {
              this.setState({ failedToLoad: true, loading: false });
              console.log("Failed to load the communication", result);
            }
          })
          .catch(error => {
            this.setState({ failedToLoad: true, loading: false });
            console.log("Failed to load the communication", error);
          });
      });
    }
  };

  render() {
    const { failedToLoad, loading, communication, settings, routeKey } = this.state;
    const { match, t } = this.props;

    if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) return <IENotSupported />;

    if (failedToLoad) return <PageNotFound />;

    if (loading) {
      return (
        <Transition visible={true} animation="fade" duration={300}>
          <Dimmer active={true}>
            <Loader size="massive">{t("cc_Loading").message || "Loading"}</Loader>
          </Dimmer>
        </Transition>
      );
    }

    if (!communication || !settings) return <PageNotFound />;

    return <DeskCommunication communication={communication} settings={settings} screen={match.params.screen} routeKey={routeKey} />;
  }
}

export default withRouter(withTranslation()(DeskCommunicationView));
