import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import "./index.scss";

const ErrorModal = ({ isOpen, onClose, onReload, message, t }) => {
  return (
    <Modal open={isOpen} size="tiny" className="error-modal-container">
      <Modal.Header className="error-modal-header">
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span>{t("warning").message || "Warning"}</span>
        </div>
        <Icon name="close" className="close-icon" onClick={onClose} />
      </Modal.Header>

      <Modal.Content className="error-modal-content">{message ? message : t("failed_error_message").message || "Something went wrong, please try again."}</Modal.Content>

      <Modal.Actions className="error-modal-actions">
        {onReload ? (
          <Button basic onClick={onReload}>
            {t("reload").message || "Reload"}
          </Button>
        ) : (
          <Button basic onClick={onClose}>
            {t("continue").message || "Continue"}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(ErrorModal);
