import React from "react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";

import "./index.scss";

const ImageGallery = ({ lettersMap, t }) => {
  if (!Array.isArray(lettersMap)) return null;

  const urls = Object.keys(lettersMap);
  if (!urls.length) return null;

  return (
    <div className="images-container">
      {urls.map(url => (
        <div key={url} className="image-container">
          <div className="img-label">
            <div className="img-icon-wrapper">
              <FontAwesomeIcon className="image-video-icon" icon={faCamera} />
              <span className="report-car-images-letter">{lettersMap[url]}</span>
            </div>
          </div>
          <img alt="" className="image-style" src={url} />
        </div>
      ))}
    </div>
  );
};

const TranslatedImageGallery = withTranslation()(ImageGallery);

TranslatedImageGallery.getLettersMap = (appointment, fixedItems, notFixedItems, workingItems) => {
  let map = [];
  let currentLetter = 97;
  let currentNumber = 1;

  const setLetter = url => {
    if (currentLetter < 123) {
      map[url] = String.fromCharCode(currentLetter);
      currentLetter++;
    } else {
      map[url] = currentNumber;
      currentNumber++;
    }
  };

  if (Array.isArray(appointment.final_check_images)) {
    appointment.interventions
      .filter(i => i.mechanic_fixed)
      .forEach(i => {
        appointment.final_check_images
          .filter(img => img.intervention_index === i.intervention_index)
          .forEach(img => {
            setLetter(img.url);
          });
      });
  }

  fixedItems.forEach(i => {
    if (Array.isArray(i.images)) {
      i.images.forEach(img => {
        setLetter(img.url);
      });
    }
  });

  if (Array.isArray(appointment.final_check_images)) {
    appointment.interventions
      .filter(i => !i.mechanic_fixed)
      .forEach(i => {
        appointment.final_check_images
          .filter(img => img.intervention_index === i.intervention_index)
          .forEach(img => {
            setLetter(img.url);
          });
      });
  }

  notFixedItems.forEach(i => {
    if (Array.isArray(i.images)) {
      i.images.forEach(img => {
        setLetter(img.url);
      });
    }
  });

  workingItems.forEach(i => {
    if (Array.isArray(i.images)) {
      i.images.forEach(img => {
        setLetter(img.url);
      });
    }
  });

  return map;
};

export default TranslatedImageGallery;
