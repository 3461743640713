import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { Cancel } from "../../../../components";
import { normalizeCommunicationData } from "../../../../../../util/common";

import AuthenticationError from "../AuthenticationError";
import EnterRegistration from "./EnterRegistration";

import Service from "./service";

const SUBSCREENS = {
  MAIN: 1,
  CANCEL: 2,
  ERROR: 3,
};

class EnterRegistrationScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    registrationNumber: "",
    loading: false,
  };

  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToErrorScreen = () => this.setState({ screen: SUBSCREENS.ERROR });

  onChange = registrationNumber => registrationNumber.length <= 6 && this.setState({ registrationNumber });

  handleConfirm = () => {
    const { i18n } = this.props;

    this.setState({ loading: true }, async () => {
      try {
        const response = await Service.openCheckin({ private_key: this.props.match.params.kioskKey, reg_nr: this.state.registrationNumber });
        const communication = response?.data?.data ? response.data.data : {};
        if (normalizeCommunicationData(communication, i18n)) {
          this.setState({ loading: false }, () => this.props.onGoToNextScreenWithState({ communication }));
        } else {
          this.setState({ loading: false }, () => this.goToErrorScreen());
        }
      } catch (err) {
        this.setState({ loading: false }, () => {
          this.goToErrorScreen();
        });
      }
    });
  };

  render() {
    const { screen, registrationNumber, loading } = this.state;
    const { renderLayout, onAbort, onGoBack, t } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <EnterRegistration
            renderLayout={renderLayout}
            onGoBack={onGoBack}
            onGoToCancelScreen={this.goToCancel}
            onConfirm={this.handleConfirm}
            registrationNumber={registrationNumber}
            onChange={this.onChange}
            loading={loading}
          />
        );

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={renderLayout}
            onGoBack={this.goBack}
            message={
              t("kiosk_authentication_error").message || "We could not find a valid appointment to proceed with the check-in, please retry or contact a receptionist"
            }
          />
        );
    }
  }
}

export default withRouter(withTranslation()(EnterRegistrationScreen));
