import axios from "axios";

import ENV from "../../util/env-config";

class Service {
  static sendAnswer(requestData) {
    return axios.post(ENV.deskComBase + "/customers/set_answer", requestData);
  }
}

export default Service;
