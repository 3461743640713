import axios from "axios";

import ENV from "../../../../../../../util/env-config";

class Service {
  static auth(requestData) {
    return axios.post(ENV.keylockerBase + "/pickback/auth", requestData);
  }
}

export default Service;
