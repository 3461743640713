import React, { useRef } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import "./uploadButton.scss";

const UploadButton = ({ handleUploadURL, translation_key, loading, disabled }) => {
  const { t } = useTranslation();
  const fileref = useRef(null);
  return (
    <div className="add-remark-upload-file-button-container">
      <input
        onClick={event => {
          event.target.value = null;
        }}
        ref={fileref}
        hidden
        onChange={handleUploadURL}
        type="file"
      />
      <Button
        disabled={disabled}
        loading={loading}
        onClick={() => !disabled && !loading && fileref.current.click()}
        className="add-remark-upload-file-button bg-color hover-bg-color focus-bg-style"
      >
        {t(translation_key).message || "Choose File"}
      </Button>
    </div>
  );
};

export default UploadButton;
