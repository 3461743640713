import React from "react";
import { withTranslation } from "react-i18next";

import { NavigationButton, Error } from "../../../../components";

const AuthenticationError = ({ renderLayout, onGoBack, message, t }) => {
  const content = <Error>{message}</Error>;

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  return renderLayout({ leftButton, content });
};

export default withTranslation()(AuthenticationError);
