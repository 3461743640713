import axios from "axios";

import ENV from "../../util/env-config";

class Service {
  static loadCommunication(routeKey) {
    return axios.post(`${ENV.deskComBase}/customers/get_communication`, {
      key: routeKey,
    });
  }
}

export default Service;
