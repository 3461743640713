import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";

import { faArrowRight, faArrowLeft } from "@fortawesome/pro-light-svg-icons";

import { MobileOnly } from "../../../../layout";
import "./index.scss";

class NavigationButtons extends Component {
  state = {
    loading: false,
  };

  renderButtons = () => {
    const {
      SCREENS,
      currentScreen,
      onGoToPreviousScreen,
      onGoToNextScreen,
      onGoToSuccessScreen,
      onShowAgreementError,
      onShowCustomerNameRequiredError,
      customerName,
      customerNameVisible,
      approved,
      answered,
      answerLabel,
      onSendAnswer,
      onShowErrorModal,
      backOnly,
      allowedToOrder,
      isLastOptionalServiceNotVisible,
      showMaintenanceModal,
      t,
    } = this.props;
    const { loading } = this.state;

    if (currentScreen === SCREENS.FIRST_SCREEN) {
      return (
        <button className="next-btn large bg-color" onClick={onGoToNextScreen}>
          {t("cc_NEXT").message || "NEXT"}
          <FontAwesomeIcon icon={faArrowRight} className="next-arrow" />
        </button>
      );
    }

    if (backOnly) {
      return (
        <button className="back-btn large color" onClick={onGoToPreviousScreen}>
          <FontAwesomeIcon icon={faArrowLeft} className="back-arrow color" />
          {t("cc_BACK").message || "BACK"}
        </button>
      );
    }

    if (currentScreen === SCREENS.LAST_SCREEN) {
      return null;
    }

    let answerButton = false;
    if (currentScreen === SCREENS.ORDER && !answered) {
      answerButton = true;
    }

    const handleClickNext = () => {
      if (loading) return;
      if (isLastOptionalServiceNotVisible) {
        window.scrollTo(0, window.innerHeight);

        return;
      }

      if (answerButton) {
        onShowAgreementError && onShowAgreementError(!approved);
        onShowCustomerNameRequiredError && onShowCustomerNameRequiredError(customerNameVisible && !customerName);

        if (!approved || (customerNameVisible && !customerName)) return;
      }

      if (!answerButton) onGoToNextScreen();
      else if (approved && (!allowedToOrder || allowedToOrder())) {
        const promise = onSendAnswer();
        if (promise) {
          this.setState({ loading: true });
          promise
            .then(result => {
              const { keylocker_pin, acses_pin, acses_locker_name } = result?.data?.data || { keylocker_pin: null, acses_pin: null, acses_locker_name: null };
              this.setState({ loading: false });
              onGoToSuccessScreen({ keylocker_pin, acses_pin, acses_locker_name });
            })
            .catch(error => {
              console.log("handleSendAnswer error", error);

              this.setState({ loading: false });

              const errorMessage = error?.response?.data?.errors?.shift();

              if (errorMessage?.includes("maintenance_mode")) {
                showMaintenanceModal();
              } else {
                onShowErrorModal && onShowErrorModal(true);
              }
            });
        }
      }
    };

    return (
      <>
        <div className="back-btn color" onClick={onGoToPreviousScreen}>
          <FontAwesomeIcon icon={faArrowLeft} className="back-arrow color" />
          {t("cc_BACK").message || "BACK"}
        </div>
        <Button className="next-btn bg-color" loading={loading} disabled={loading} onClick={handleClickNext}>
          {answerButton && answerLabel}
          {!answerButton && (t("cc_NEXT").message || "NEXT")}
          <FontAwesomeIcon icon={faArrowRight} className="next-arrow" />
        </Button>
      </>
    );
  };

  render() {
    return (
      <MobileOnly>
        <div className="navigation-container">{this.renderButtons()}</div>
      </MobileOnly>
    );
  }
}

export default withTranslation()(NavigationButtons);
