import WelcomeScreen from "./Welcome";
import AuthenticationScreen from "./Authentication";
import PickUpKeyScreen from "./PickUpKey";
import ThankYouScreen from "./ThankYou";

const SCREENS = {
  FIRST_SCREEN: 1,
  WELCOME: 1,
  AUTH: 2,
  PICKUP_KEY: 3,
  THANK_YOU: 4,
  LAST_SCREEN: 4,
};

export default { SCREENS, WelcomeScreen, AuthenticationScreen, PickUpKeyScreen, ThankYouScreen };
