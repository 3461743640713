import React from "react";
import { withTranslation } from "react-i18next";

import { Help, NavigationButton } from "../../../components";

const PickUpKeyHelp = ({ renderLayout, onGoBack, onGoToCallForHelp, t }) => {
  const content = (
    <Help className="PickUpKeyHelp">
      <div>
        <div>{t("kl_pick_up_key_help1_part1").message || "Once a Box number is display on the screen,"}</div>
        <div>{t("kl_pick_up_key_help1_part2").message || "go to that Box, pick up you car key, close it"}</div>
        <div>{t("kl_pick_up_key_help1_part3").message || "come back and press on Confirm."}</div>
      </div>
      <div>
        <div>{t("kl_drop_key_help2_part1").message || "If the locker shown on the screen did not"}</div>
        <div>{t("kl_drop_key_help2_part2").message || "properly open, or if you have any problem"}</div>
        <div>{t("kl_drop_key_help2_part3").message || "with closing the locker, please"}</div>
        <div>{t("kl_drop_key_help2_part4").message || "press on Call for Help."}</div>
      </div>
    </Help>
  );

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  const rightButton = (
    <NavigationButton icon="phone" onClick={onGoToCallForHelp}>
      {t("kl_call_for_help").message || "Call For Help"}
    </NavigationButton>
  );

  return renderLayout({ content, leftButton, rightButton });
};

export default withTranslation()(PickUpKeyHelp);
