import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { CustomerCommunication, DeskCommunication, PageNotFound, Report } from "./views/";
import { CounterTablet, KeyLocker, Kiosk } from "./modules";

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/report/:routeKey">
          <Report />
        </Route>
        <Route path="/countertablet/">
          <CounterTablet />
        </Route>
        <Route path="/keylocker/:keylockerKey">
          <KeyLocker />
        </Route>
        <Route path="/softkeylocker/:keylockerKey">
          <KeyLocker softmode={true} />
        </Route>
        <Route path="/kiosk/:kioskKey">
          <Kiosk />
        </Route>
        <Route path="/deskcheckin/:screen(\d+)?">
          <DeskCommunication />
        </Route>
        <Route exact path="/:screen(\d+)?">
          <CustomerCommunication />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};
