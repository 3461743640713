import React, { useState } from "react";
import { Modal, Grid, Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faInfo } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { LeftImage, Content, MessageContent, MessageHeader, MessageBottom, RepairOverviewNote, ContactUsSectionRepairOverview } from "./index.style";

import "./index.scss";

const PopUpMessage = ({ type, communication, settings }) => {
  const { note } = communication;
  const { repair_overview_welcome_text, color, welcome_picture, phone_number, email } = settings;

  const [modalOpen, setModalOpen] = useState(true);
  const { t } = useTranslation();

  const closeModal = () => setModalOpen(false);

  const renderContactUsSection = () => {
    return (
      <ContactUsSectionRepairOverview color={color}>
        <div className="contact-us-section-post-comm">
          <span>{t("cc_CONTACT US").message || "CONTACT US"}:</span>
          <span className="contact-us-icons">
            <FontAwesomeIcon icon={faPhone} /> {phone_number}
          </span>
          <span className="contact-us-icons">
            <FontAwesomeIcon icon={faEnvelope} /> {email}
          </span>
        </div>
        <div>
          <Button className="post-comm-welcome-modal-close-btn" basic onClick={closeModal}>
            {t("close").message || "Close"}
          </Button>
        </div>
      </ContactUsSectionRepairOverview>
    );
  };

  const renderWelcomeMessage = () => {
    let welcomeMsg = t("cc_Welcome").message || "Welcome";
    if (communication?.customer?.title && communication.customer.surname) {
      welcomeMsg += ` ${communication.customer.title}`;
      if (communication.customer.firstname) welcomeMsg += ` ${communication.customer.firstname}`;

      welcomeMsg += ` ${communication.customer.surname}`;
    }

    return (
      <Content>
        <MessageHeader color={color}>
          <h1>{welcomeMsg},</h1>
        </MessageHeader>
        <MessageContent>{repair_overview_welcome_text}</MessageContent>
        {note && (
          <RepairOverviewNote color={color}>
            <FontAwesomeIcon icon={faInfo} />
            {note}
          </RepairOverviewNote>
        )}
        <MessageBottom>{renderContactUsSection()}</MessageBottom>
      </Content>
    );
  };

  const messageRenderController = type => {
    switch (type) {
      case "welcome":
        return renderWelcomeMessage();
      default:
        return null;
    }
  };

  return (
    <Modal className="post-comm-popup-modal" size="small" open={modalOpen} dimmer closeOnDimmerClick={false}>
      <Modal.Content className="modal-content">
        <Grid>
          <Grid.Column computer={4} only="computer">
            <LeftImage welcomePicture={welcome_picture} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={12}>
            {messageRenderController(type)}
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default PopUpMessage;
