import React, { useState } from "react";
import { Grid, Input, Form, TextArea } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faUpload, faFileImage } from "@fortawesome/pro-light-svg-icons";

import { MobileOnly, DesktopOnly, Responsive } from "../../../../layout";
import UploadButton from "./uploadButton";
import MobileRemarks from "./mobileRemarks";
import "./index.scss";

const CustomRemarks = ({ addMore, routeKey, readOnly, showMobile, remarks, addNewRemark, updateRemarks, uploadImages }) => {
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorBtn, setErrorBtn] = useState(null);
  const { t } = useTranslation();
  addMore = !!addMore && !remarks.some(r => !r.title && !r.description && r.attachments.length < 1);

  if (readOnly && remarks.length === 0) return null;

  const handleUploadURL = (evt, i) => {
    if (readOnly || loading !== null) return;

    evt.persist();
    let fd = new FormData();
    fd.append("file", evt.target.files[0]);
    fd.append("key", routeKey);
    setLoading(i);
    setErrorMsg("");
    setErrorBtn(i);
    uploadImages(fd)
      .then(res => {
        setLoading(null);
        remarks[i].attachments = remarks[i].attachments.concat({ url: res.data?.data?.url, name: evt.target.files[0].name });
        updateRemarks(remarks);
      })
      .catch(err => {
        console.log("upload files error", err);
        err.response && err.response.data && setErrorMsg(err.response.data.errors[0]);
        setLoading(null);
      });
  };

  const handleChangeRemarkTitle = (v, i) => {
    if (readOnly) return;

    remarks[i].title = v;
    updateRemarks(remarks);
  };

  const handleChangeRemarkDescription = (v, i) => {
    if (readOnly) return;

    remarks[i].description = v;
    updateRemarks(remarks);
  };

  const handleRemoveUploadedFile = (remarkOffset, attachmentOffset) => {
    remarks[remarkOffset].attachments.splice(attachmentOffset, 1);
    updateRemarks(remarks);
  };

  const renderDesktopVersion = () => {
    return (
      <DesktopOnly>
        {remarks.map((r, i) => {
          return (
            <Grid key={"remark_desktop_" + i} className="custom-remarks-container component-container">
              <Grid.Row>
                <Grid.Column computer={16}>
                  <div className="custom-remarks-header">
                    <FontAwesomeIcon icon={faPencil} className="custom-remarks-header-icon" />
                    <h4>{t("cc_additional_remarks").message || "Additional remarks"}</h4>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={8}>
                  <h5 className="add-remark-title">{t("cc_add_additional_remark").message || "Add additional remark"}</h5>
                  <div>
                    <p className="add-remark-label">{t("cc_enter_subject").message || "Enter your subject"}:</p>
                    <Input className="add-remark-title-field" disabled={readOnly} value={r.title} onChange={v => handleChangeRemarkTitle(v.target.value, i)} />
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    <p className="add-remark-label">{t("cc_description").message || "Description"}:</p>
                    <Form className="add-remark-description-field">
                      <TextArea disabled={readOnly} value={r.description} onChange={v => handleChangeRemarkDescription(v.target.value, i)} />
                    </Form>
                  </div>
                </Grid.Column>
                <Grid.Column computer={8}>
                  {(!readOnly || (r.attachments && r.attachments.length > 0)) && (
                    <div className="add-remark-upload-files-container">
                      <div
                        className={
                          r.attachments && r.attachments.length > 0 ? "add-remark-upload-file-section-container" : "add-remark-upload-file-section-container full"
                        }
                      >
                        <div className="add-remark-upload-section">
                          <div className="add-remark-upload-file-icon-container">
                            <FontAwesomeIcon icon={faUpload} className="add-remark-upload-file-icon" />
                          </div>
                          <div className="add-remark-upload-file-text-container">
                            <span className="add-remark-upload-file-text">{t("cc_upload_images_videos").message || "Upload additional photos and videos if needed"}</span>
                          </div>
                          <UploadButton
                            disabled={readOnly || (loading !== null && loading !== i)}
                            loading={loading === i}
                            handleUploadURL={v => handleUploadURL(v, i)}
                            translation_key="cc_choose_file"
                          />
                        </div>
                      </div>

                      {r.attachments && r.attachments.length > 0 && (
                        <div className="add-remark-uploaded-files-container">
                          <h5>{t("cc_uploaded_files").message || "Uploaded files"}:</h5>
                          {r.attachments.map((a, idx) => (
                            <div className="add-remark-uploaded-files-item-container" key={"attachement" + idx}>
                              <div className="add-remark-uploaded-files-file-container">
                                <FontAwesomeIcon className="add-remark-uploaded-files-image-icon" icon={faFileImage} />
                                <a rel="noopener noreferrer" href={a.url} target="_blank">
                                  <span className="add-remark-uploaded-files-attachement-name">{a.name}</span>
                                </a>
                              </div>
                              {!readOnly && (
                                <FontAwesomeIcon className="add-remark-uploaded-files-icon" icon={faTrash} onClick={() => handleRemoveUploadedFile(i, idx)} />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column computer={8} floated="right">
                  <p className="custom-remarks-error-msg">{errorBtn === i && errorMsg}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        })}
      </DesktopOnly>
    );
  };

  const renderMobileVersion = () => {
    return (
      <MobileOnly>
        {remarks.map((r, i) => (
          <MobileRemarks
            key={"remark_mobile_" + i}
            errorMsg={errorBtn === i && errorMsg}
            shown={i === 0 || (remarks.length === i + 1 && r.title === "" && r.description === "" && !r.attachments.length)}
            disabled={loading !== null && loading !== i}
            loading={loading === i}
            readOnly={readOnly}
            t={t}
            remark={r}
            handleRemoveUploadedFile={v => handleRemoveUploadedFile(i, v)}
            handleChangeRemarkTitle={v => handleChangeRemarkTitle(v.target.value, i)}
            handleChangeRemarkDescription={v => handleChangeRemarkDescription(v.target.value, i)}
            handleUploadURL={v => handleUploadURL(v, i)}
          />
        ))}
      </MobileOnly>
    );
  };

  return (
    <Responsive showMobile={showMobile}>
      {renderDesktopVersion()}
      {renderMobileVersion()}
      {!readOnly && addMore && (
        <div className="add-remark-container" onClick={addNewRemark}>
          <span className="add-remark-plus-sign">+</span>
          <span className="add-remark-text">{t("cc_add_remark").message || "Add another remark"}</span>
        </div>
      )}
    </Responsive>
  );
};

export default CustomRemarks;
