import React, { useState } from "react";
import { Checkbox, Button, Modal, Input } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoVideo } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { MobileOnly, DesktopOnly } from "../../../../../layout";
import { round2 } from "../../../../../util/common";
import { ANSWERS } from "../../../../../util/answers";

import ErrorModal from "../../../components/ErrorModal";

import "./index.scss";

const Order = ({
  readOnly,
  approvedInterventions,
  pendingInterventions,
  settings,
  remarks,
  agreements,
  approved,
  onAgreed,
  allowedToOrder,
  onSendAnswer,
  onGoToSuccessScreen,
  showAgreementError,
  onShowAgreementError,
  onShowCustomerNameRequiredError,
  onCustomerNameChange,
  customerName,
  customerNameVisible,
  showCustomerNameRequiredError,
  bill,
  acsesIsAvailable,
  onDropInAcsesChange,
  dropInAcsesLocker,
  showMaintenanceModal,
}) => {
  const { t } = useTranslation();
  const { include_vat, vat } = settings;
  const [visibleAgreement, setVisibleAgreement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const displayInterventionPrice = price => {
    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="order-scheduled_services-item-price">€ {round2(price).toFixed(2)}</span>;
  };

  const renderApprovedServices = () => {
    return approvedInterventions.map((intervention, i) => (
      <div key={"scheduled" + i} className="order-scheduled_services-item">
        <span className="order-scheduled_services-item-title">{intervention.title}</span>
        {displayInterventionPrice(intervention.price)}
      </div>
    ));
  };

  const renderPendingServicesToFix = () => {
    return pendingInterventions
      .filter(intervention => intervention.communication_result_status === ANSWERS.ADD_TO_CART)
      .map((intervention, i) => (
        <div key={"scheduled" + i} className="order-scheduled_services-item">
          <span className="order-scheduled_services-item-title">{intervention.title}</span>
          {displayInterventionPrice(intervention.price)}
        </div>
      ));
  };

  const renderPendingServicesToContact = () => {
    return pendingInterventions
      .filter(intervention => intervention.communication_result_status === ANSWERS.CONTACT_ME)
      .map((intervention, i) => (
        <div key={"scheduled" + i} className="order-scheduled_services-item">
          <span className="order-scheduled_services-item-title">{intervention.title}</span>
        </div>
      ));
  };

  const renderRemarks = () => {
    return remarks
      .filter(r => r.title || r.description || r.attachments.length > 0)
      .map((remark, i) => {
        let title = remark.title;
        if (!title && remark.description) {
          title = remark.description.substr(0, 35);
          if (remark.description.length > 35) title += "...";
        }

        return (
          <div key={"remark" + i} className="order-mobile-remarks-container">
            <span className="order-mobile-remarks-title">{title}</span>
            {remark.attachments.length > 0 && (
              <span>
                <FontAwesomeIcon icon={faPhotoVideo} className="order-mobile-remarks-icon" />
                {remark.attachments.length}
              </span>
            )}
          </div>
        );
      });
  };

  const getTotalAmount = () => {
    let total = 0;
    let vat = include_vat ? 1 + settings.vat / 100 : 1;

    if (Array.isArray(approvedInterventions)) {
      total += approvedInterventions.reduce((prev, cur) => {
        if (!(cur.price > 0.01)) return prev;
        return prev + round2(cur.price * vat);
      }, 0);
    }

    if (Array.isArray(pendingInterventions)) {
      total += pendingInterventions.reduce((prev, cur) => {
        if (cur.communication_result_status === ANSWERS.ADD_TO_CART && cur.price > 0.01) {
          return prev + round2(cur.price * vat);
        }
        return prev;
      }, 0);
    }

    return total;
  };

  const handleAgreementChecked = id => {
    if (readOnly) return;

    onAgreed(id);
  };

  const downloadBill = async () => {
    try {
      const response = await axios.get(bill.url, { responseType: "blob" });
      saveAs(new Blob([response.data]), bill.name);
    } catch (error) {
      console.error("Error downloading the bill:", error);
    }
  };

  const handleSendAnswer = () => {
    if (readOnly || loading || !allowedToOrder()) return;

    onShowAgreementError && onShowAgreementError(!approved);
    onShowCustomerNameRequiredError && onShowCustomerNameRequiredError(customerNameVisible && !customerName);
    setOpenErrorModal(!approved);

    if (!approved || (customerNameVisible && !customerName)) return;

    let promise = onSendAnswer();
    if (promise) {
      setLoading(true);
      promise
        .then(result => {
          const { keylocker_pin, acses_pin, acses_locker_name } = result?.data?.data || { keylocker_pin: null, acses_pin: null, acses_locker_name: null };
          setLoading(false);
          onGoToSuccessScreen({ keylocker_pin, acses_pin, acses_locker_name });
        })
        .catch(error => {
          console.log("handleSendAnswer error", error);
          setLoading(false);

          const errorMessage = error?.response?.data?.errors?.shift();

          if (errorMessage?.includes("maintenance_mode")) {
            showMaintenanceModal();
          } else {
            setOpenErrorModal(true);
          }
        });
    }
  };

  const totalAmount = getTotalAmount();

  return (
    <>
      {
        <Modal open={visibleAgreement !== null} size="fullscreen" detachable={false}>
          <Modal.Actions>
            <Button onClick={() => setVisibleAgreement(null)} negative icon="times" />
          </Modal.Actions>
          <Modal.Content scrolling>
            <div dangerouslySetInnerHTML={{ __html: visibleAgreement }} />
          </Modal.Content>
        </Modal>
      }

      <MobileOnly>
        <h4 className="mobile-order-header">{t("cc_YOUR_SUMMARY").message || "YOUR SUMMARY"}</h4>
      </MobileOnly>

      <div className="order-container">
        {Array.isArray(approvedInterventions) && approvedInterventions.length > 0 && (
          <div>
            <h4 className="order-scheduled_services-heading">{t("cc_approved_services").message || "Approved services"}</h4>
            <div className="order-scheduled_services-container">{renderApprovedServices()}</div>
          </div>
        )}

        {Array.isArray(pendingInterventions) && pendingInterventions.length > 0 && pendingInterventions.some(i => i.communication_result_status === ANSWERS.ADD_TO_CART) && (
          <div>
            <h4 className="order-scheduled_services-heading">{t("cc_additional_services").message || "Additional services"}</h4>
            <div className="order-scheduled_services-container">{renderPendingServicesToFix()}</div>
          </div>
        )}

        {Array.isArray(pendingInterventions) && pendingInterventions.length > 0 && pendingInterventions.some(i => i.communication_result_status === ANSWERS.CONTACT_ME) && (
          <div>
            <h4 className="order-scheduled_services-heading">{t("cc_you_will_be_contacted_for").message || "You will be contacted for"}</h4>
            <div className="order-scheduled_services-container">{renderPendingServicesToContact()}</div>
          </div>
        )}

        {Array.isArray(remarks) && remarks.some(r => r.title || r.description || r.attachments.length > 0) && (
          <MobileOnly>
            <h4 className="order-optional_services-heading">{t("cc_remarks").message || "Remarks"}</h4>
            <div className="order-optional_services-remarks">{renderRemarks()}</div>
          </MobileOnly>
        )}
        {totalAmount > 0.01 && (
          <div className="order-total-amount-container">
            <div className="order-total-amount-money-container">
              <span className="order-total-amount-money-title">{`${t("cc_TOTAL_AMOUNT").message || "TOTAL AMOUNT"}:`}</span>
              <span className="order-total-amount-money-amount">€ {totalAmount.toFixed(2)}</span>
            </div>
            {include_vat && (
              <div className="order-total-amount-vat-container">
                <span className="order-total-amount-vat-title">
                  *{t("cc_including").message || "Including"} {vat}% {t("cc_vat").message || "VAT"}
                </span>
                <span className="order-total-amount-vat-amount">€ {((totalAmount / (1 + vat / 100)) * (vat / 100)).toFixed(2)}</span>
              </div>
            )}

            {bill && (
              <div className="download-bill-link" onClick={downloadBill}>
                {t("download_your_bill").message || "Download your bill"}
              </div>
            )}
          </div>
        )}

        {Array.isArray(agreements) && (
          <div className="order-terms-container">
            {agreements.map((a, i) => (
              <>
                <div key={"agreement" + i} className="order-terms-item">
                  <Checkbox defaultChecked={a.accepted} disabled={readOnly} onChange={() => handleAgreementChecked(a.id)} className="big-box" />
                  <Button className="order-terms-btn" onClick={() => setVisibleAgreement(a.text)}>
                    {a.name}
                  </Button>
                </div>
                <div>
                  {!a.accepted && !a.optional_customcom && showAgreementError && (
                    <div className="order-terms-error">{t("cc_accept_agreements").message || "*Please accept the agreement above to continue!"}</div>
                  )}
                </div>
              </>
            ))}
          </div>
        )}

        {!readOnly && acsesIsAvailable && (
          <div className="acses-container">
            <Checkbox checked={dropInAcsesLocker} onChange={onDropInAcsesChange} className="big-box" />
            <label>{t("cc_book_automated_locker_to_drop_key").message || "Book an automated locker to drop the car key"}</label>
          </div>
        )}

        {!readOnly && customerNameVisible && (
          <div className={`customer-name-container ${showCustomerNameRequiredError ? "required" : ""} `}>
            <label>{t("cc_enter_your_name").message || "Please enter your name"}</label>
            <Input type="text" fluid name="customer_name" value={customerName} onChange={e => onCustomerNameChange(e.target.value)} />
          </div>
        )}

        {!readOnly && (
          <DesktopOnly className="order-confirm-container">
            <Button className="bg-color" loading={loading} onClick={handleSendAnswer}>
              {t("cc_SUBMIT").message || "SUBMIT"}
            </Button>
          </DesktopOnly>
        )}

        <ErrorModal
          isOpen={openErrorModal}
          onClose={() => setOpenErrorModal(false)}
          message={
            showAgreementError
              ? t("cc_accept_agreements").message || "*Please accept the agreement above to continue!"
              : t("cc_error_please_refresh").message || "Something went wrong. Please try to refresh the page if the problem persists."
          }
          onReload={showAgreementError ? null : () => window.location.reload(window.location.hash?.substring(1) || null)}
        />
      </div>
    </>
  );
};

export default Order;
