import React, { useState } from "react";
import { Grid, Table, Modal, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faArrowToRight, faTimes } from "@fortawesome/pro-solid-svg-icons";

import { withTranslation } from "react-i18next";

import { round2, renderInterventionElementType } from "../../../../../../util/common";

import "./index.scss";

const ScheduledServices = ({ interventions, readOnly, include_vat, vat, declinedInterventionIds, onUpdateDeclinedInterventionIDs, display_intervention_elements, t }) => {
  const [showRemarkID, setShowRemarkID] = useState(null);
  const [selectedIntervention, setSelectedIntervention] = useState(null);

  if (!interventions?.length) return null;

  const displayPrice = price => {
    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="desk-checkin-scheduled-services-price">€ {round2(price).toFixed(2)}</span>;
  };

  const toggleRemark = id => {
    if (showRemarkID === id) {
      setShowRemarkID(null);
      return;
    }

    setShowRemarkID(id);
  };

  const renderInterventionElementsModal = () => {
    return (
      <Modal open={!!selectedIntervention} className="scheduled-services-elements-modal-desk-checkin" size="small">
        <Modal.Header>
          <div className="scheduled-services-elements-title">{selectedIntervention?.title}</div>
          <FontAwesomeIcon icon={faTimes} onClick={() => setSelectedIntervention(null)} />
        </Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Body>
              {selectedIntervention?.elements.map(e => (
                <Table.Row key={e.id}>
                  <Table.Cell>
                    {e.parent_dms_nr && (
                      <div className="intervention-element-arrow-to-right">
                        <FontAwesomeIcon icon={faArrowToRight} />
                      </div>
                    )}
                    {renderInterventionElementType(e.element_type)}
                    <div className="intervention-element-description">{e.description}</div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  };

  const renderUpdateInterventionIcons = (id, mechanic_fixed) => {
    if (declinedInterventionIds?.includes(id))
      return (
        <Icon name="delete" color="red" disabled={readOnly || mechanic_fixed} onClick={evt => onUpdateDeclinedInterventionIDs(evt, id)} style={{ cursor: "pointer" }} />
      );

    return (
      <Icon name="check" color="green" disabled={readOnly || mechanic_fixed} onClick={evt => onUpdateDeclinedInterventionIDs(evt, id)} style={{ cursor: "pointer" }} />
    );
  };

  return (
    <div className="desk-checkin-scheduled-services-outter-container">
      <Grid className="desk-checkin-scheduled-services-container">
        <Grid.Row className="desk-checkin-scheduled-services-header">
          <FontAwesomeIcon icon={faCog} className="desk-checkin-scheduled-services-header-icon" />
          <h4>{t("cc_scheduled_services").message || "Scheduled services"}</h4>
        </Grid.Row>

        {interventions.map(intervention => (
          <Grid.Row
            onClick={() => {
              intervention.description && toggleRemark(intervention.id);
            }}
            key={intervention.id}
            className="desk-checkin-scheduled-services-item"
          >
            <Grid.Column width={11}>
              <div className="desk-checkin-scheduled-services-name-container">
                <div>
                  {display_intervention_elements && intervention.elements.length > 0 && (
                    <Icon name="info circle" color="black" onClick={() => setSelectedIntervention(intervention)} />
                  )}
                  <span className="desk-checkin-scheduled-services-name">{intervention.title}</span>
                </div>
                {intervention.description?.trim() && <Icon name={showRemarkID === intervention.id ? "caret up" : "caret down"} style={{ color: "#1C3E52" }} />}
              </div>
            </Grid.Column>
            <Grid.Column width={3} textAlign="right" className="desk-checkin-scheduled-services-price-container">
              {displayPrice(intervention.price)}
            </Grid.Column>
            <Grid.Column width={2} textAlign="center">
              {renderUpdateInterventionIcons(intervention.id, intervention.mechanic_fixed)}
            </Grid.Column>
            {showRemarkID === intervention.id && (
              <Grid.Column width={16}>
                <div className="desk-checkin-scheduled-services-remark">{intervention.description}</div>
              </Grid.Column>
            )}
          </Grid.Row>
        ))}
      </Grid>

      {renderInterventionElementsModal()}
    </div>
  );
};

export default withTranslation()(ScheduledServices);
