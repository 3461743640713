import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import "./index.scss";

const Error = ({ t, children }) => {
  return (
    <div className="ErrorScreen">
      <div className="KioskTitle">
        <FontAwesomeIcon icon={faExclamationCircle} className="ErrorIcon" />
        <span>{t("kiosk_try_again").message || "Try again"}!</span>
      </div>
      <div className="ErrorMessage">{children}</div>
    </div>
  );
};

export default withTranslation()(Error);
