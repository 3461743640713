import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import MainLayout from "../../layout";
import { ScheduledServices } from "./components";

import "./index.scss";

class ScheduledServicesScreen extends Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    const { communication, settings, readOnly, onGoToNextScreen, onGoToPreviousScreen, declinedInterventionIds, onUpdateDeclinedInterventionIDs, t } = this.props;
    const { interventions } = communication.appointment;
    const { include_vat, vat, display_intervention_elements } = settings;

    const content = (
      <div className="desk-ScheduledServicesScreen">
        <ScheduledServices
          interventions={interventions}
          include_vat={include_vat}
          vat={vat}
          declinedInterventionIds={declinedInterventionIds}
          onUpdateDeclinedInterventionIDs={onUpdateDeclinedInterventionIDs}
          display_intervention_elements={display_intervention_elements}
          readOnly={readOnly}
        />
      </div>
    );

    const navigationButtons = (
      <>
        <Button className="DeskCheckinNavigationLeftButton" onClick={onGoToPreviousScreen} loading={loading} disabled={loading}>
          <FontAwesomeIcon icon={faArrowLeft} className="navigation-icon" />
          <span className="navigation-text">{t("cc_back").message || "BACK"}</span>
        </Button>
        <Button className="DeskCheckinNavigationRightButton" onClick={onGoToNextScreen} loading={loading} disabled={loading}>
          <span className="navigation-text">{t("cc_next").message || "NEXT"}</span>
          <FontAwesomeIcon icon={faArrowRight} className="navigation-icon" />
        </Button>
      </>
    );

    return <MainLayout content={content} navigationButtons={navigationButtons} showTitle isCheckingOut={communication.is_checking_out} />;
  }
}

export default withTranslation()(ScheduledServicesScreen);
