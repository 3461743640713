import React from "react";
import { withTranslation } from "react-i18next";

import "./index.scss";

const MainLayout = ({ content, showTitle, navigationButtons, isCheckingOut, t }) => {
  return (
    <div className="DeskCheckin">
      {showTitle && (
        <div className="DeskCheckinTitle">
          {isCheckingOut ? <h1>{t("cc_desk_checkout").message || "Desk check-out"}</h1> : <h1>{t("cc_desk_checkin").message || "Desk check-in"}</h1>}
        </div>
      )}

      <div className="DeskCheckinContent">{content}</div>

      <div className="DeskCheckinNavigation">{navigationButtons}</div>
    </div>
  );
};

export default withTranslation()(MainLayout);
