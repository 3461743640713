import React from "react";
import moment from "moment";
import { Grid, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCalendarAlt, faInfoCircle, faMapMarkedAlt, faCar, faEnvelope, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationTriangle, faExclamationCircle, faUserCheck } from "@fortawesome/pro-light-svg-icons";

import Print from "./Print";
import Save from "./Save";
import { MobileOnly, DesktopOnly } from "../../../../layout";
import { renderCarRegistration } from "../../../../util/common";

import "./index.scss";

const Header = ({
  title,
  communication,
  settings,
  showMobile,
  routeKey,
  correctEmail,
  correctPhone,
  showFooter,
  showPrintDownload,
  showActionLegend,
  showStatusLegend,
}) => {
  const { t } = useTranslation();
  const { appointment } = communication;
  showFooter = !!showFooter;
  showPrintDownload = !!showPrintDownload && !communication.is_receptionist;
  showActionLegend = !!showActionLegend;
  showStatusLegend = !!showStatusLegend;

  const renderAppointmentInformation = () => {
    return (
      <>
        {communication.appointment.time_car_app && (
          <div className="margin-bottom-8">
            <FontAwesomeIcon icon={faCalendarAlt} className="header-info-icon color" />
            <span className="header-info-text">
              {communication.appointment.due_in
                ? moment(communication.appointment.due_in)
                    .format("DD-MM-YYYY HH:mm")
                    .replace(" 00:00", "")
                : moment(communication.appointment.time_car_app).format("DD-MM-YYYY")}
            </span>
          </div>
        )}
        {communication.appointment.wo_nr && (
          <div className="margin-bottom-8">
            <FontAwesomeIcon icon={faInfoCircle} className="header-info-icon color" />
            <span className="header-info-text">{communication.appointment.wo_nr}</span>
          </div>
        )}
        {settings.location_street && settings.location_city && (
          <div>
            <FontAwesomeIcon icon={faMapMarkedAlt} className="header-info-icon color" />
            <span className="header-info-text">{`${settings.location_street}, ${settings.location_city}`}</span>
          </div>
        )}
      </>
    );
  };

  const renderCarInformation = () => {
    return (
      <>
        {renderCarRegistration(settings.location_country, communication.appointment.reg_number)}

        {communication.appointment.vin_nr && (
          <div className="margin-bottom-8">
            <Popup
              trigger={<FontAwesomeIcon icon={faInfoCircle} className="header-info-icon color" />}
              content={t("cc_VIN_number").message || "VIN Number"}
              position="bottom center"
              inverted
            />
            <span className="header-info-text">{communication.appointment.vin_nr}</span>
          </div>
        )}
        {(communication.appointment.car_make || communication.appointment.car_model) && (
          <div>
            <FontAwesomeIcon icon={faCar} className="header-info-icon color" />
            <span className="header-info-text">
              {communication.appointment.car_make || ""} {communication.appointment.car_model || ""}
            </span>
          </div>
        )}
      </>
    );
  };

  const renderCustomerInformation = () => {
    let fullname = "";

    if (communication.customer) {
      if (communication.customer.title) fullname += communication.customer.title + " ";

      if (communication.customer.firstname) fullname += communication.customer.firstname + " ";

      if (communication.customer.surname) fullname += communication.customer.surname;
    }

    return (
      <>
        {fullname && (
          <div className="margin-bottom-8">
            <FontAwesomeIcon icon={faUser} className="header-info-icon color" />
            <span className="header-info-text">{fullname}</span>
          </div>
        )}
        {correctEmail && (
          <div className="margin-bottom-8">
            <FontAwesomeIcon icon={faEnvelope} className="header-info-icon color" />
            <span className="header-info-text">{correctEmail}</span>
          </div>
        )}
        {correctPhone && (
          <div>
            <FontAwesomeIcon icon={faPhone} className="header-info-icon color" />
            <span className="header-info-text">{correctPhone}</span>
          </div>
        )}
      </>
    );
  };

  const renderExplanation = () => (
    <>
      <div className="header-info-explanation-text">{t("cc_select_possible_action").message || "Select a possible action for each question item."}</div>
      <div className="header-info-explanation-text">
        {t("cc_confirm_fixing_by_pressing_buttons").message ||
          "Confirm fixing the item by pressing Add to cart button or Contact me button in case you need more information."}
      </div>
      <div className="header-info-explanation-text">{t("cc_selected_will_change_color").message || "When selected, button will change its color."}</div>
    </>
  );

  const formatDateIfValid = (dateToFormat, formatInto = "DD-MM-YYYY") => {
    let formattedDate = "";

    if (dateToFormat && (moment(dateToFormat).isValid() || !dateToFormat.startsWith("0001-01-01T00:00:00"))) {
      const md = moment(dateToFormat);
      formattedDate = md.format(formatInto);
    }
    return formattedDate;
  };

  const renderFooter = () => {
    let appNextDate = formatDateIfValid(appointment.next_date);
    let apkDate = formatDateIfValid(appointment.car_apk_date);

    return (
      <Grid.Row className="header-footer">
        {apkDate && (
          <Grid.Column className="header-footer-column" mobile={16}>
            <span className="header-footer-label">{t("cc_apk_date").message || "APK date"}:</span> <span className="header-footer-data">{apkDate}</span>
          </Grid.Column>
        )}
        <Grid.Column className="header-footer-column" mobile={16}>
          <span className="header-footer-label">{t("cc_current_kilometers").message || "Current kilometers"}:</span>{" "}
          <span className="header-footer-data">{appointment.current_km} km</span>
        </Grid.Column>
        <Grid.Column className="header-footer-column" mobile={16}>
          <span className="header-footer-label">{t("cc_next_maintenance").message || "Next maintenance"}:</span>{" "}
          <span className="header-footer-data">
            {appointment.next_km} km {appointment.next_date && (t("cc_or_before").message || "or before")}
          </span>{" "}
          <span className="header-footer-date">{appNextDate}</span>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const renderLegend = () => {
    return (
      <div className="header-status-container">
        {showStatusLegend && (
          <>
            <div className="header-status">
              <div className="header-status-box all-right">
                <FontAwesomeIcon icon={faUserCheck} />
              </div>
              <span className="header-status-text">{t("cc_all_right").message || "All right"}</span>
            </div>
            <div className="header-status">
              <div className="header-status-box advisable">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <span className="header-status-text">{t("cc_advisable").message || "Advisable"}</span>
            </div>
            <div className="header-status">
              <div className="header-status-box neccessary">
                <FontAwesomeIcon icon={faExclamationCircle} />
              </div>
              <span className="header-status-text">{t("cc_necessary").message || "Necessary"}</span>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="header-container">
      <DesktopOnly>
        {(title || showPrintDownload) && (
          <div className="header-title-container">
            <h1 className="header-title">{title}</h1>
            {showPrintDownload && (
              <div>
                <Print communication={communication} settings={settings} t={t} />
                <Save appointment={communication.appointment} routeKey={routeKey} t={t} />
              </div>
            )}
          </div>
        )}

        <Grid className="header-info-container component-container">
          <Grid.Row>
            <Grid.Column width={5}>
              <span className="header-info-heading">{t("cc_appointment_information").message || "Appointment information"}</span>
            </Grid.Column>
            <Grid.Column width={7}>
              <span className="header-info-heading">{t("cc_car_information").message || "Car information"}</span>
            </Grid.Column>
            <Grid.Column width={4}>
              <span className="header-info-heading">{t("cc_customer_information").message || "Customer information"}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="header-info-content">
            <Grid.Column width={5}>{renderAppointmentInformation()}</Grid.Column>
            <Grid.Column width={7}>{renderCarInformation()}</Grid.Column>
            <Grid.Column width={4}>{renderCustomerInformation()}</Grid.Column>
          </Grid.Row>

          {showFooter && renderFooter()}
        </Grid>
      </DesktopOnly>

      {showMobile && (
        <MobileOnly>
          <div className="header-info-container component-container mobile">
            <div className="header-info-heading">{t("cc_appointment_information").message || "Appointment information"}</div>
            <div className="header-info-content">{renderAppointmentInformation()}</div>
          </div>
          <div className="header-info-container component-container mobile">
            <div className="header-info-heading">{t("cc_car_information").message || "Car information"}</div>
            <div className="header-info-content">{renderCarInformation()}</div>
          </div>
          <div className="header-info-container component-container mobile">
            <div className="header-info-heading">{t("cc_customer_information").message || "Customer information"}</div>
            <div className="header-info-content">{renderCustomerInformation()}</div>
          </div>

          {showActionLegend && (
            <div className="header-info-container component-container mobile">
              <div className="header-info-heading">{t("cc_explanation_for_actions").message || "Explanation for action buttons"}</div>
              <div className="header-info-content">{renderExplanation()}</div>
            </div>
          )}

          {showFooter && (
            <div className="header-info-container component-container mobile">
              <div className="header-info-content">{renderFooter()}</div>
            </div>
          )}

          {showStatusLegend && (
            <div className="header-info-container component-container mobile">
              <div className="header-info-heading">{t("cc_legend_for_statuses").message || "Legend for statuses"}</div>
              <div className="header-info-content">{renderLegend()}</div>
            </div>
          )}
        </MobileOnly>
      )}
    </div>
  );
};

export default Header;
