import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  AuthenticationOptions,
  AuthenticationHelp,
  EnterPINScreen,
  EnterRegistrationScreen,
  EnterPhoneScreen,
  ScanQRCodeScreen,
  ConfirmAuthenticationScreen,
  ConfirmAuthenticationWithout2FA,
} from "./subscreens";

import { Cancel, CallForHelp } from "../../../components";

import MainLayout from "../../../layout";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  CALL_FOR_HELP: 4,
  ENTER_PIN: 5,
  ENTER_REGISTRATION: 6,
  ENTER_PHONE: 7,
  SCAN_QR_CODE: 8,
  CONFIRM_AUTHENTICATION: 9,
  CONFIRM_AUTHENTICATION_WITHOUT_2FA: 10,
};

const STEP = 1;
class AuthenticationScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    reg_nr: null,
    phone_nr: null,
    emailAddress: "",
    displayedPhoneNr: null,
    communicationChannel: null,
    appointment_id: null,
  };

  startInactivityTimer = () => (this.inactivityTimer = setTimeout(() => this.props.onGoBackToStartScreen(), 60000));

  handleResetInactivityTimer = () => {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  };

  componentDidMount() {
    this.startInactivityTimer();
    document.addEventListener("click", this.handleResetInactivityTimer);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleResetInactivityTimer);
    clearTimeout(this.inactivityTimer);
  }

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kl_step").message || "Step") + " " + STEP;
    const title = t("kl_authentication").message || "Authentication";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToEnterPinScreen = () => this.setState({ screen: SUBSCREENS.ENTER_PIN });
  goToEnterRegNrScreen = () => this.setState({ screen: SUBSCREENS.ENTER_REGISTRATION });
  goToEnterPhoneNrScreen = () => this.setState({ screen: SUBSCREENS.ENTER_PHONE });
  goToScanQRCode = () => this.setState({ screen: SUBSCREENS.SCAN_QR_CODE });
  goToConfirmAuthenticationScreen = data =>
    this.setState({
      ...data,
      screen: this.props.keylocker.is_2fa_to_drop_key_enabled ? SUBSCREENS.CONFIRM_AUTHENTICATION : SUBSCREENS.CONFIRM_AUTHENTICATION_WITHOUT_2FA,
    });

  handleSelectAuthenticationMethod = communicationChannel => this.setState({ communicationChannel });

  render() {
    const { screen, communicationChannel, emailAddress, phone_nr, reg_nr, displayedPhoneNr, appointment_id } = this.state;
    const { onGoToNextScreenWithState, onGoToOrderScreenWithState, onGoToPreviousScreen, onAbort, softmode } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <AuthenticationOptions
            renderLayout={this.renderLayout}
            onGoBack={onGoToPreviousScreen}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToCallForHelp={this.goToCallForHelp}
            onGoToEnterPinScreen={this.goToEnterPinScreen}
            onGoToEnterRegNrScreen={this.goToEnterRegNrScreen}
            onGoToEnterPhoneNrScreen={this.goToEnterPhoneNrScreen}
            onGoToScanQRCode={this.goToScanQRCode}
          />
        );

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={this.renderLayout} onGoBack={this.goBack} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;

      case SUBSCREENS.ENTER_PIN:
        return (
          <EnterPINScreen
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoBack={this.goBack}
            onGoToCancelScreen={this.goToCancel}
            communicationChannel={communicationChannel}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
            onAbort={onAbort}
          />
        );

      case SUBSCREENS.ENTER_REGISTRATION:
        return (
          <EnterRegistrationScreen
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoBack={this.goBack}
            onGoToCancelScreen={this.goToCancel}
            onGoToConfirmAuthenticationScreen={this.goToConfirmAuthenticationScreen}
            onAbort={onAbort}
          />
        );

      case SUBSCREENS.ENTER_PHONE:
        return (
          <EnterPhoneScreen
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoBack={this.goBack}
            onGoToCancelScreen={this.goToCancel}
            onGoToConfirmAuthenticationScreen={this.goToConfirmAuthenticationScreen}
            onAbort={onAbort}
          />
        );

      case SUBSCREENS.SCAN_QR_CODE:
        return <ScanQRCodeScreen renderLayout={this.renderLayout} onGoBack={this.goBack} onAbort={onAbort} softmode={softmode} />;

      case SUBSCREENS.CONFIRM_AUTHENTICATION:
        return (
          <ConfirmAuthenticationScreen
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToEnterPinScreen={this.goToEnterPinScreen}
            handleSelectAuthenticationMethod={this.handleSelectAuthenticationMethod}
            communicationChannel={communicationChannel}
            emailAddress={emailAddress}
            phone_nr={phone_nr}
            displayedPhoneNr={displayedPhoneNr}
            reg_nr={reg_nr}
            onAbort={onAbort}
          />
        );

      case SUBSCREENS.CONFIRM_AUTHENTICATION_WITHOUT_2FA:
        return (
          <ConfirmAuthenticationWithout2FA
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToOrderScreenWithState={onGoToOrderScreenWithState}
            emailAddress={emailAddress}
            appointment_id={appointment_id}
            onAbort={onAbort}
          />
        );
    }
  }
}

export default withTranslation()(AuthenticationScreen);
