import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { HelpButton, CancelButton, NavigationButton } from "..";
import { NumPad } from "../../../../components";

import "./index.scss";

class PhonePad extends Component {
  state = { phone: this.props.phone || "" };

  handleNumPress = num => {
    if (this.state.phone.length === 10) return;
    this.setState(({ phone }) => ({
      phone: (phone += num),
    }));
  };

  handleErasePhoneNumber = () => {
    if (!this.state.phone.length) return;
    this.setState(({ phone }) => ({
      phone: phone.substring(0, phone.length - 1),
    }));
  };

  render() {
    const { phone } = this.state;
    const { renderLayout, onBackCancel, onConfirm, onGoToHelpScreen, onGoToCancelScreen, leftButtonCancel, rightButtonText, loading, t } = this.props;
    const headerButtons = [
      <HelpButton key={1} onClick={onGoToHelpScreen} disabled={loading} />,
      <CancelButton key={2} onClick={onGoToCancelScreen} disabled={loading} />,
    ];

    const content = (
      <div className="PhonePadScreen">
        <div className="PhoneInput">
          <div className="KeyLockerTitle">{t("kl_phone_number").message || "Phone Number"}</div>
          <div className="PhoneNumber">
            <div>{phone[0]}</div>
            <div>{phone[1]}</div>
            <div>{phone[2]}</div>
            <div>{phone[3]}</div>
            <div>{phone[4]}</div>
            <div>{phone[5]}</div>
            <div>{phone[6]}</div>
            <div>{phone[7]}</div>
            <div>{phone[8]}</div>
            <div>{phone[9]}</div>
          </div>
        </div>

        <NumPad onNumPress={this.handleNumPress} onErase={this.handleErasePhoneNumber} />
      </div>
    );

    const leftButton = <NavigationButton cancel={leftButtonCancel} back={!leftButtonCancel} loading={loading} onClick={onBackCancel} />;

    const rightButton = (
      <NavigationButton icon="check" disabled={this.state.phone.length < 10} loading={loading} onClick={() => onConfirm(this.state.phone)}>
        {rightButtonText}
      </NavigationButton>
    );

    return renderLayout({ headerButtons, content, leftButton, rightButton });
  }
}

export default withTranslation()(PhonePad);
