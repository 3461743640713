import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { AuthenticationHelp, EnterPINScreen, ScanQRCodeScreen, AuthenticationOptions } from "./subscreens";

import { Cancel, CallForHelp } from "../../../components";

import { MODE } from "../..";

import MainLayout from "../../../layout";

const SUBSCREENS = {
  MAIN: 1,
  ENTER_PIN: 2,
  SCAN_QR_CODE: 3,
  HELP: 4,
  CANCEL: 5,
  CALL_FOR_HELP: 6,
};

const STEP = 1;

class AuthenticationScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
  };

  startInactivityTimer = () => (this.inactivityTimer = setTimeout(() => this.props.onGoBackToStartScreen(), 60000));

  handleResetInactivityTimer = () => {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  };

  componentDidMount() {
    this.startInactivityTimer();
    document.addEventListener("click", this.handleResetInactivityTimer);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleResetInactivityTimer);
    clearTimeout(this.inactivityTimer);
  }

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kl_step").message || "Step") + " " + STEP;
    const title = t("kl_authentication").message || "Authentication";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToMain = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToEnterPinScreen = () => this.setState({ screen: SUBSCREENS.ENTER_PIN });
  goToScanQRCode = () => this.setState({ screen: SUBSCREENS.SCAN_QR_CODE });
  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });

  render() {
    const { screen } = this.state;
    const { onGoToNextScreenWithState, onGoToPreviousScreen, onAbort, softmode } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <AuthenticationOptions
            renderLayout={this.renderLayout}
            onGoToEnterPinScreen={this.goToEnterPinScreen}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToScanQRCode={this.goToScanQRCode}
            onGoBack={onGoToPreviousScreen}
          />
        );

      case SUBSCREENS.ENTER_PIN:
        return (
          <EnterPINScreen
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoBack={this.goToMain}
            onGoToCancelScreen={this.goToCancel}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
            onAbort={onAbort}
          />
        );

      case SUBSCREENS.SCAN_QR_CODE:
        return <ScanQRCodeScreen renderLayout={this.renderLayout} onGoBack={this.goToMain} onAbort={onAbort} softmode={softmode} />;

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={this.renderLayout} onGoBack={this.goToMain} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel keylockerMode={MODE.PICKUP} onGoBack={this.goToMain} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;
    }
  }
}

export default withTranslation()(AuthenticationScreen);
