import React from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";

const CustomerName = ({ customerName, showCustomerNameError, onGoToEditCustomerName, t }) => {
  return (
    <div className={`OrderContainer OrderCustomerName ${!customerName && showCustomerNameError ? "CustomerNameError" : ""}`} onClick={onGoToEditCustomerName}>
      <div className="ContainerHeader">
        <FontAwesomeIcon icon={faPen} />
        <h4>{customerName ? t("cc_edit_your_name").message || "Edit your name" : t("cc_enter_your_name").message || "Enter your name"}</h4>
      </div>
      <div className="ContainerText" dangerouslySetInnerHTML={{ __html: customerName.replace(/\n/g, "<br />") }} />
    </div>
  );
};

export default withTranslation()(CustomerName);
