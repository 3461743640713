import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { withTranslation } from "react-i18next";

import MainLayout from "../../layout";

import "./index.scss";

const ThankYouScreen = ({ t }) => {
  const content = (
    <div className="DeskCheckinThankYouScreen">
      <div className="ThankYouHeader">
        <FontAwesomeIcon icon={faCheckCircle} className="ThankYouIcon" />
      </div>
      <div className="ThankYouMessage">{t("cc_thank_you").message || "Thank you"}</div>
    </div>
  );

  return <MainLayout content={content} />;
};

export default withTranslation()(ThankYouScreen);
