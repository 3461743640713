import React, { Component } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Transition, Dimmer, Loader } from "semantic-ui-react";

import { MODE, Drop, PickUp, StartScreen } from "./screens";
import APP_CONFIG from "../../util/app-config";
import Service from "./service";

const INITIAL_BACKOFF_DELAY = 1000;
const initialState = {
  mode: MODE.CHOOSE,
  screen: Drop.SCREENS.FIRST_SCREEN,
  communication: null,
  extraPhone: null,
  additionalRemark: null,
  customerName: "",
  customerAcceptedAgreementsIDs: null,
  pickUpBackDoor: null,
  pickUpBackPin: null,
  isLoading: false,
  adminOpenDoor: false,
};

class KeyLocker extends Component {
  state = {
    ...initialState,
    keylocker: null,
    abortErrors: [],
    abortErrorsDelay: INITIAL_BACKOFF_DELAY,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { match, i18n } = this.props;

    this.setState({ isLoading: true }, () => {
      Service.init({ private_key: match.params.keylockerKey, frontend_version: APP_CONFIG.FRONTEND_VERSION })
        .then(res => {
          const keylocker = res?.data?.data ? res.data.data : {};
          i18n.changeLanguage(keylocker.language_code || "en-GB");
          this.setState({ keylocker, isLoading: false });
        })
        .catch(err => {
          console.error("Error getting keylocker", err);

          setTimeout(() => {
            this.init();
          }, 60000);

          this.setState({ isLoading: false });
        });
    });
  };

  handleStartOver = (stateUpdate = {}) => {
    if (stateUpdate.keylocker) {
      this.handleResetState(stateUpdate);
      return;
    }

    this.setState({ isLoading: true }, () => {
      Service.getStatus({ private_key: this.props.match.params.keylockerKey })
        .then(response => {
          const { active, active_box_count, key_count } = response?.data?.data ? response.data.data : {};
          this.handleResetState({ keylocker: { ...this.state.keylocker, active, active_box_count, key_count } });
        })
        .catch(() => this.handleResetState(stateUpdate));
    });
  };

  handleStatusUpdate = () => {
    Service.getStatus({ private_key: this.props.match.params.keylockerKey })
      .then(response => {
        const { active, active_box_count, key_count } = response?.data?.data ? response.data.data : {};
        this.setState(prevState => ({ keylocker: { ...prevState.keylocker, active, active_box_count, key_count } }));
      })
      .catch(() => console.error("Failed to get Keylocker status"));
  };

  handleGoToDropScreens = () => this.setState({ mode: MODE.DROP });

  handleGoToPickUpScreens = () => this.setState({ mode: MODE.PICKUP });

  handleGoBackToStartScreen = () => this.setState({ mode: MODE.CHOOSE });

  handleGoToNextScreen = () => this.setState({ screen: this.state.screen + 1 });

  handleGoToNextScreenWithState = state => this.setState({ ...state, screen: this.state.screen + 1 });

  handleGoToOrderScreenWithState = state => this.setState({ ...state, screen: Drop.SCREENS.ORDER });

  handleGoToPreviousScreen = () => this.setState({ screen: this.state.screen - 1 });

  exponentialBackoffAbortErrors = () => {
    let { abortErrors, abortErrorsDelay } = this.state;

    setTimeout(async () => {
      let failed = false;
      for (let i = 0; i < abortErrors.length; i++) {
        try {
          await Service.abortDropKey(abortErrors[i]);
        } catch (err) {
          if (i > 0) abortErrors = abortErrors.slice(i);

          this.setState({ abortErrors, abortErrorsDelay: abortErrorsDelay * 2 }, () => {
            this.exponentialBackoffAbortErrors();
          });

          failed = true;
          break;
        }
      }

      if (!failed) this.setState({ abortErrors: [], abortErrorsDelay: INITIAL_BACKOFF_DELAY });
    }, abortErrorsDelay);
  };

  handleResetState = (stateUpdate = {}) => {
    const newState = { ...initialState, keylocker: { ...this.state.keylocker, ...stateUpdate.keylocker } };

    if (stateUpdate.failure) newState.abortErrors = this.state.abortErrors.concat(stateUpdate.failure);

    this.setState({ ...newState }, () => {
      if (stateUpdate.failure && this.state.abortErrors.length === 1) this.exponentialBackoffAbortErrors();
    });
  };

  renderDropKey = () => {
    const { screen, communication, keylocker, extraPhone, additionalRemark, customerName, customerAcceptedAgreementsIDs } = this.state;
    const { softmode } = this.props;

    switch (screen) {
      default:
      case Drop.SCREENS.WELCOME:
        return (
          <Drop.WelcomeScreen
            keylocker={keylocker}
            onGoToNextScreen={this.handleGoToNextScreen}
            onGoBackToStartScreen={this.handleGoBackToStartScreen}
            softmode={softmode}
          />
        );

      case Drop.SCREENS.AUTH_OPTIONS:
        return (
          <Drop.AuthenticationScreen
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
            onGoToNextScreenWithState={this.handleGoToNextScreenWithState}
            onGoToOrderScreenWithState={this.handleGoToOrderScreenWithState}
            onGoBackToStartScreen={this.handleGoBackToStartScreen}
            onAbort={this.handleStartOver}
            softmode={softmode}
            onStartOver={this.handleStartOver}
            keylocker={keylocker}
          />
        );

      case Drop.SCREENS.CHECK_INFO:
        return <Drop.CheckInfoScreen onGoToNextScreenWithState={this.handleGoToNextScreenWithState} communication={communication} onAbort={this.handleStartOver} />;

      case Drop.SCREENS.ORDER:
        return (
          <Drop.OrderScreen
            onGoToNextScreenWithState={this.handleGoToNextScreenWithState}
            communication={communication}
            keylocker={keylocker}
            onAbort={this.handleStartOver}
          />
        );

      case Drop.SCREENS.DROP_KEY:
        return (
          <Drop.DropKeyScreen
            onGoToNextScreen={this.handleGoToNextScreen}
            communication={communication}
            extraPhone={extraPhone}
            additionalRemark={additionalRemark}
            customerName={customerName}
            customerAcceptedAgreementsIDs={customerAcceptedAgreementsIDs}
            onAbort={this.handleStartOver}
            softmode={softmode}
          />
        );

      case Drop.SCREENS.THANK_YOU:
        return <Drop.ThankYouScreen onStartOver={this.handleStartOver} />;
    }
  };

  renderPickupKey = () => {
    const { screen, pickUpBackDoor, pickUpBackPin, adminOpenDoor } = this.state;
    const { softmode } = this.props;

    switch (screen) {
      default:
      case PickUp.SCREENS.WELCOME:
        return <PickUp.WelcomeScreen onGoToNextScreen={this.handleGoToNextScreen} onGoBackToStartScreen={this.handleGoBackToStartScreen} softmode={softmode} />;

      case PickUp.SCREENS.AUTH:
        return (
          <PickUp.AuthenticationScreen
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
            onGoToNextScreenWithState={this.handleGoToNextScreenWithState}
            onGoBackToStartScreen={this.handleGoBackToStartScreen}
            onAbort={this.handleStartOver}
            softmode={softmode}
          />
        );

      case PickUp.SCREENS.PICKUP_KEY:
        return (
          <PickUp.PickUpKeyScreen
            keyLockerNumber={pickUpBackDoor}
            pin={pickUpBackPin}
            adminOpenDoor={adminOpenDoor}
            onGoToNextScreen={this.handleGoToNextScreen}
            onAbort={this.handleStartOver}
            softmode={softmode}
          />
        );

      case PickUp.SCREENS.THANK_YOU:
        return <PickUp.ThankYouScreen onStartOver={this.handleStartOver} />;
    }
  };

  render() {
    const { mode, keylocker, isLoading } = this.state;

    if (isLoading) {
      return (
        <Transition visible={true} animation="fade" duration={300}>
          <Dimmer active={true}>
            <Loader size="massive" />
          </Dimmer>
        </Transition>
      );
    }

    if (!keylocker) return null;

    switch (mode) {
      default:
      case MODE.CHOOSE:
        return (
          <StartScreen
            keylocker={keylocker}
            onStatusUpdate={this.handleStatusUpdate}
            onGoToDropScreens={this.handleGoToDropScreens}
            onGoToPickUpScreens={this.handleGoToPickUpScreens}
          />
        );

      case MODE.DROP:
        return this.renderDropKey();
      case MODE.PICKUP:
        return this.renderPickupKey();
    }
  }
}

export default withRouter(withTranslation()(KeyLocker));
