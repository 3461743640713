import axios from "axios";

import ENV from "../../../../../../util/env-config";

class Service {
  static openCheckin(requestData) {
    return axios.post(ENV.kioskBase + "/checkin/open", requestData);
  }
}

export default Service;
