import React from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import "./index.scss";

const DetailAnalysis = ({ communication, lettersMap, t }) => {
  const { appointment, results } = communication;
  const { final_check_images } = appointment;
  const reducedLetterMap = lettersMap.reduce((a, c) => {
    for (const url in c) a[url] = c[url];

    return a;
  }, []);

  const renderInterventionLetters = intervention => {
    if (!Array.isArray(final_check_images) || lettersMap.length < 1) return null;

    const images = final_check_images.filter(img => img.intervention_index === intervention.intervention_index);
    if (images.length < 1) return null;

    return (
      <span className="simple-detail-analysis-row-content letter">
        [
        {images
          .map(i => reducedLetterMap[i.url])
          .filter(f => f !== undefined)
          .map(lm => lm.letter)
          .join("] [")}
        ]
      </span>
    );
  };

  const renderInterventionData = () => {
    return appointment.interventions.map((intervention, offset) => {
      return (
        <Grid.Row className="simple-detail-analysis-row" key={offset}>
          <Grid.Column width={6}>
            <span className="simple-detail-analysis-row-content-title">{intervention.title}</span>
          </Grid.Column>
          <Grid.Column width={8}>
            <span className="simple-detail-analysis-row-content">{intervention.description}</span>
          </Grid.Column>
          <Grid.Column width={2}>{renderInterventionLetters(intervention)}</Grid.Column>
        </Grid.Row>
      );
    });
  };

  const renderItemLetters = item => {
    if (!Array.isArray(item.images) || item.length < 1 || lettersMap.length < 1) return null;

    return (
      <span className="simple-detail-analysis-row-content letter">
        [
        {item.images
          .map(i => reducedLetterMap[i.url])
          .filter(f => f !== undefined)
          .map(lm => lm.letter)
          .join("] [")}
        ]
      </span>
    );
  };

  const renderItemsData = () => {
    return results.map((item, offset) => {
      return (
        <Grid.Row className="simple-detail-analysis-row" key={offset}>
          <Grid.Column width={6}>
            <span className="simple-detail-analysis-row-content-title">{item.title}</span>
          </Grid.Column>
          <Grid.Column width={8}>
            <span className="simple-detail-analysis-row-content">{item.mechanic_notes}</span>
          </Grid.Column>
          <Grid.Column width={2}>{renderItemLetters(item)}</Grid.Column>
        </Grid.Row>
      );
    });
  };

  return (
    <div className="simple-detail-analysis-container">
      <h4>{t("cc_detail_analysis").message || "Detail Analysis"}</h4>
      <Grid>
        <Grid.Row className="simple-detail-analysis-header">
          <Grid.Column width={6}>
            <span className="title">{t("cc_item_name").message || "ITEM NAME"}</span>
          </Grid.Column>
          <Grid.Column width={8}>
            <span className="title">{t("cc_REMARKS").message || "REMARKS"}</span>
          </Grid.Column>
          <Grid.Column width={2}>
            <span className="title">{t("cc_photo").message || "PHOTO"}</span>
          </Grid.Column>
        </Grid.Row>

        {renderInterventionData()}
        {renderItemsData()}
      </Grid>
    </div>
  );
};

export default withTranslation()(DetailAnalysis);
