import React from "react";
import { withTranslation } from "react-i18next";

import { HelpButton, CancelButton, NavigationButton, Error } from "../../../components";

const PickUpKeyError = ({ renderLayout, onGoBack, onGoToHelpScreen, onGoToCallForHelp, onAbort, t }) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} />, <CancelButton key={2} onClick={onAbort} />];

  const content = <Error>{t("kl_pickup_key_error").message || "The locker is not properly closed or something went wrong, please check or call for help."}</Error>;

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  const rightButton = (
    <NavigationButton icon="phone" onClick={onGoToCallForHelp}>
      {t("kl_call_for_help").message || "Call For Help"}
    </NavigationButton>
  );

  return renderLayout({ headerButtons, content, leftButton, rightButton });
};

export default withTranslation()(PickUpKeyError);
