export const CUSTOMER_COMMUNICATION_STATUSES = {
  ONLINE_CHECKIN_SENT: 1,
  ONLINE_CHECKIN_OPENED: 2,
  ONLINE_CHECKIN_ANSWERED: 3,
  ONLINE_CHECKIN_CLOSED: 4,
  DIAGNOSE_OVERVIEW_SENT: 5,
  DIAGNOSE_OVERVIEW_OPENED: 6,
  DIAGNOSE_OVERVIEW_ANSWERED: 7,
  DIAGNOSE_OVERVIEW_CLOSED: 8,
  REPAIR_OVERVIEW_SENT: 9,
  REPAIR_OVERVIEW_OPENED: 10,
};

export const KEYLOCKER_COMMUNICATION_STATUSES = {
  DROP_PIN_SENT: 1,
  CHECK_IN_OPENED: 2,
  CHECK_IN_ANSWERED: 3,
  PICK_UP_PIN_SENT: 4,
};

export const DESK_COMMUNICATION_STATUSES = {
  CHECKIN_SENT: 1,
  CHECKIN_RECEIVED: 2,
  CHECKIN_FAILED_TO_OPEN: 3,
  CHECKIN_OPENED: 4,
  CHECKIN_ANSWERED: 5,
  CHECKIN_CLOSED: 6,
};
export const DESK_COMMUNICATION_EVENTS = {
  CHECKIN_SENT: 1,
  CHECKIN_RECEIVED: 2,
  CHECKIN_FAILED_TO_OPEN: 3,
  CHECKIN_OPENED: 4,
  CHECKIN_CANCELED: 5,
  CHECKIN_ANSWERED: 6,
};
