import React from "react";
import { Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { HelpButton, CancelButton, NavigationButton } from "../../../../../components";
import { COMMUNICATION_CHANNELS } from "./index";

const ConfirmAuthenticationMethod = ({
  renderLayout,
  onGoToHelpScreen,
  onGoToCancel,
  handleConfirmAuthentication,
  handleSelectAuthenticationMethod,
  communicationChannel,
  emailAddress,
  displayedPhoneNr,
  loading,
  t,
}) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} disabled={loading} />, <CancelButton key={2} onClick={onGoToCancel} disabled={loading} />];

  const content = (
    <div className="ConfirmAuthenticationScreen">
      <div className="ConfirmAuthenticationScreenHeader">
        <FontAwesomeIcon icon={faPaperPlane} className="ConfirmAuthenticationScreenTitleIcon" />
        <div className="KeyLockerTitle ConfirmAuthenticationScreenHeaderTitle">{t("kl_appointment_found").message || "Appointment found"}</div>
      </div>
      <div className="ConfirmAuthenticationScreenMessage">
        {emailAddress && !displayedPhoneNr && (
          <>
            <div>{t("kl_is_this_your_email").message || "Is this your email?"}</div>
            <div className="ConfirmAuthenticationScreenMethod">
              <FontAwesomeIcon icon={faEnvelope} />
              <div>{emailAddress}</div>
            </div>
          </>
        )}

        {displayedPhoneNr && !emailAddress && (
          <>
            <div>{t("kl_is_this_your_phone_nr").message || "Is this your phone number?"}</div>
            <div className="ConfirmAuthenticationScreenMethod">
              <FontAwesomeIcon icon={faPhone} />
              <div>{displayedPhoneNr}</div>
            </div>
          </>
        )}

        {displayedPhoneNr && emailAddress && (
          <>
            <div>{t("kl_contact_info_question").message || "Is this your contact information?"}</div>
            <div className="ConfirmAuthenticationScreenMethod-container">
              <div className="ConfirmAuthenticationScreenMethod">
                <FontAwesomeIcon icon={faEnvelope} />
                <div>{emailAddress}</div>
              </div>

              <div className="ConfirmAuthenticationScreenMethod">
                <FontAwesomeIcon icon={faPhone} />
                <div>{displayedPhoneNr}</div>
              </div>
            </div>
            <div className="ConfirmAuthenticationScreenSelection">
              <p>{t("kl_communication_channel_selection").message || "Make a selection how do you want to receive your PIN code"}</p>
              <div className="ConfirmAuthenticationScreenButtons">
                <Button active={communicationChannel === COMMUNICATION_CHANNELS.EMAIL} onClick={() => handleSelectAuthenticationMethod(COMMUNICATION_CHANNELS.EMAIL)}>
                  {t("kl_email").message || "Email"}
                </Button>

                <Button active={communicationChannel === COMMUNICATION_CHANNELS.SMS} onClick={() => handleSelectAuthenticationMethod(COMMUNICATION_CHANNELS.SMS)}>
                  {t("kl_sms").message || "SMS"}
                </Button>
              </div>
            </div>
          </>
        )}

        <div className="ConfirmAuthenticationScreenEmailExplanation">
          <div>{t("kl_confirm_email_explanation1").message || "We will use it to send you a PIN code."}</div>
          <div>{t("kl_confirm_email_explanation2").message || "If it is not, please abort and go to receptionist."}</div>
        </div>
      </div>
    </div>
  );

  const rightButton = (
    <NavigationButton disabled={displayedPhoneNr && emailAddress && !communicationChannel} loading={loading} onClick={handleConfirmAuthentication}>
      {t("kl_send").message || "Send"}
    </NavigationButton>
  );

  return renderLayout({ headerButtons, rightButton, content });
};

export default withRouter(withTranslation()(ConfirmAuthenticationMethod));
