import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { NavigationButton, CancelButton } from "../../../../components";

import "./index.scss";

const AuthenticationOptions = ({ renderLayout, onGoToEnterRegNrScreen, onGoToEnterPhoneNrScreen, onGoToCancelScreen, onGoBack, t }) => {
  const headerButtons = [<CancelButton key={1} onClick={onGoToCancelScreen} />];
  const content = (
    <div className="KioskAuthenticationOptionsScreen">
      <div className="KioskTitle">{t("kiosk_find_your_appointment").message || "Let’s find your appointment"}</div>

      <div className="AuthenticationOptionsScreenOptions">
        <div className="AuthenticationOptionsScreenOption" onClick={onGoToEnterRegNrScreen}>
          <div className="AuthenticationOptionsScreenOptionContent">
            <div className="AuthenticationOptionsScreenIcon">
              <FontAwesomeIcon icon={faCar} className="AuthenticationOptionsScreenSingleIcon" />
            </div>
            <div className="AuthenticationOptionsScreenOptionTitle">
              <div>{t("kiosk_registration_number").message || "Registration Number"}</div>
            </div>
          </div>
        </div>

        <div className="AuthenticationOptionsScreenOption" onClick={onGoToEnterPhoneNrScreen}>
          <div className="AuthenticationOptionsScreenOptionContent">
            <div className="AuthenticationOptionsScreenIcon">
              <FontAwesomeIcon icon={faPhone} className="AuthenticationOptionsScreenSingleIcon" />
            </div>
            <div className="AuthenticationOptionsScreenOptionTitle">{t("kiosk_phone_number").message || "Phone Number"}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const mainButton = <NavigationButton back onClick={onGoBack} />;

  return renderLayout({ headerButtons, mainButton, content });
};

export default withTranslation()(AuthenticationOptions);
