import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { NavigationButton, HelpButton } from "../../../components";
import Service from "./service";

import "./DropKey.scss";

const KEY_LOCKER_STATUSES = {
  OPEN: "1",
  CLOSED: "0",
};

class DropKey extends Component {
  state = {
    keyLockerNumber: this.props.keyLockerNumber,
    loading: false,
  };

  componentDidMount() {
    this.getKeyLockerNumber();
  }

  getKeyLockerNumber = () => {
    if (this.state.keyLockerNumber) return;

    const { communication, match, onGoToErrorScreen, onLockerAssigned, softmode } = this.props;

    this.setState({ loading: true }, () => {
      Service.getKeyLockerNumber({ communication_key: communication.user_key, private_key: match.params.keylockerKey })
        .then(res => {
          if (!isNaN(res?.data?.data?.locker_number)) {
            this.setState({ keyLockerNumber: res.data.data.locker_number, loading: false }, () => {
              Service.openKeyLocker(this.state.keyLockerNumber, softmode)
                .then(() => onLockerAssigned(this.state.keyLockerNumber))
                .catch(error => {
                  let errorMessage = "";

                  try {
                    if (error.response) {
                      if (error.response.data?.errors?.length) errorMessage = error.response.data.errors[0];
                      else errorMessage = JSON.stringify(error.response.data);
                    } else errorMessage = error.message;
                  } catch (error) {
                    console.error("Failed to parse the error message", error);
                    errorMessage = "Failed to parse the error message";
                  }

                  Service.relayOpenKeylockerError({ private_key: match.params.keylockerKey, box_number: this.state.keyLockerNumber, error: errorMessage }).catch(() =>
                    console.error("Error relaying open keylocker error")
                  );

                  onGoToErrorScreen();
                });
            });
          } else this.setState({ loading: false }, () => onGoToErrorScreen());
        })
        .catch(err => {
          console.log("error getting key locker number", err);
          const errorMessage = err.response?.data?.errors?.length ? err.response?.data?.errors[0] : "Error getting keylocker number";
          this.setState({ loading: false }, () => onGoToErrorScreen(errorMessage));
        });
    });
  };

  checkLockStatus = () => {
    const { onGoToErrorScreen, softmode } = this.props;

    this.setState({ loading: true }, () => {
      Service.checkKeyLockerStatus(softmode, this.state.keyLockerNumber)
        .then(status => {
          if (status !== KEY_LOCKER_STATUSES.CLOSED) this.setState({ loading: false }, () => onGoToErrorScreen());
          else {
            const {
              communication,
              match,
              extraPhone: additional_phone,
              additionalRemark: additional_remark,
              customerName: customer_name,
              customerAcceptedAgreementsIDs: agreed,
              onGoToNextScreen,
            } = this.props;

            Service.setKeyLockerClosed({
              communication_key: communication.user_key,
              private_key: match.params.keylockerKey,
              additional_phone,
              additional_remark,
              customer_name,
              agreed,
            })
              .then(() => this.setState({ loading: false }, () => onGoToNextScreen()))
              .catch(err => {
                const errorMessage = err.response?.data?.errors?.length ? err.response?.data?.errors[0] : "Error setting keylocker closed";
                this.setState({ loading: false }, () => onGoToErrorScreen(errorMessage));
                console.log("error setting keylocker closed", err);
              });
          }
        })
        .catch(err => {
          const errorMessage = err.response?.data?.errors?.length ? err.response?.data?.errors[0] : "Error getting keylocker status";
          this.setState({ loading: false }, () => onGoToErrorScreen(errorMessage));
          console.log("error getting keylocker status", err);
        });
    });
  };

  render() {
    const { keyLockerNumber, loading } = this.state;
    const { renderLayout, onGoToHelpScreen, t } = this.props;

    const headerButtons = [<HelpButton disabled={loading} key={1} onClick={onGoToHelpScreen} />];

    const content = (
      <div className="DropKeyScreen">
        <div className="DropKeyBox">{keyLockerNumber ? <div className="DropKeyBoxNumber">{keyLockerNumber}</div> : <Loader active size="massive" />}</div>
        <div className="DropKeyMessage">{t("kl_drop_key_message1").message || "Drop your car key in the Box and close it."}</div>
        <div className="DropKeyMessage">{t("kl_drop_key_message2").message || "Then come back here and click on Confirm."}</div>
      </div>
    );

    const rightButton = (
      <NavigationButton loading={loading} onClick={this.checkLockStatus}>
        {t("kl_confirm").message || "Confirm"}
      </NavigationButton>
    );

    return renderLayout({ headerButtons, rightButton, content });
  }
}

export default withRouter(withTranslation()(DropKey));
