import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { HelpButton, CancelButton, NavigationButton } from "../../../../../components";

const ConfirmAuthenticationWithout2FAMethod = ({ renderLayout, onGoToHelpScreen, onGoToCancel, handleConfirmAuthenticationWithout2FA, emailAddress, loading, t }) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} disabled={loading} />, <CancelButton key={2} onClick={onGoToCancel} disabled={loading} />];

  const content = (
    <div className="ConfirmAuthenticationScreen">
      <div className="ConfirmAuthenticationScreenHeader">
        <FontAwesomeIcon icon={faPaperPlane} className="ConfirmAuthenticationScreenTitleIcon" />
        <div className="KeyLockerTitle ConfirmAuthenticationScreenHeaderTitle">{t("kl_appointment_found").message || "Appointment found"}</div>
      </div>
      <div className="ConfirmAuthenticationScreenMessage">
        {emailAddress && (
          <>
            <div>{t("kl_your_contact_info").message || "Your contact information:"}</div>
            <div className="ConfirmAuthenticationScreenMethod-container">
              <div className="ConfirmAuthenticationScreenMethod">
                <FontAwesomeIcon icon={faEnvelope} />
                <div>{emailAddress}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const rightButton = (
    <NavigationButton loading={loading} onClick={handleConfirmAuthenticationWithout2FA}>
      {t("kl_next").message || "Next"}
    </NavigationButton>
  );

  return renderLayout({ headerButtons, rightButton, content });
};

export default withRouter(withTranslation()(ConfirmAuthenticationWithout2FAMethod));
