import axios from "axios";

import ENV from "../../../util/env-config";

class Service {
  static sendAnswer(requestData) {
    return axios.post(ENV.customComBaseV2 + "/diagnose_overview/answer", requestData);
  }

  static uploadImages(requestData) {
    return axios.post(ENV.customComBase + "/communication/upload_attachment", requestData);
  }
}

export default Service;
