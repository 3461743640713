// all deprecated fields/method need to be removed when the tablet build from CLA-I2616 is used by 100% of users

import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faKey, faInbox } from "@fortawesome/pro-solid-svg-icons";

import MainLayout from "../../../layout";
import { NavigationButton } from "../../../components";

import "./index.scss";

class WelcomeScreen extends Component {
  renderContent = () => {
    const { onGoToNextScreen, t } = this.props;

    return (
      <div className="PickUp_Key_WelcomeScreen">
        <div className="WelcomeScreenMessage">
          {t("kl_pickup_key_welcome_message_part1").message || "To pick up your car key in this locker"}
          <br />
          {t("kl_pickup_key_welcome_message_part2").message || "go through these two steps:"}
        </div>
        <Grid container className="WelcomeScreenSteps">
          <Grid.Row centered>
            <Grid.Column width={4}></Grid.Column>
            <Grid.Column className="WelcomeScreenStepContainer" width="4" textAlign="center" onClick={onGoToNextScreen}>
              <div className="WelcomeScreenStep">
                <div className="WelcomeScreenStepNumber">1</div>
                <FontAwesomeIcon icon={faUserLock} className="WelcomeScreenStepSingleIcon" />
                <div className="WelcomeScreenStepTitle">
                  <p>{t("kl_authentication").message || "Authentication"}</p>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column className="WelcomeScreenStepContainer" width="4" textAlign="center" onClick={onGoToNextScreen}>
              <div className="WelcomeScreenStep">
                <div className="WelcomeScreenStepNumber">2</div>
                <div className="WelcomeScreenStepCompositeIcon">
                  <FontAwesomeIcon icon={faKey} />
                  <br />
                  <FontAwesomeIcon icon={faInbox} />
                </div>
                <div className="WelcomeScreenStepTitle">
                  <p>{t("kl_pick_up_your_key_inside_box").message || "Pick up your key inside the box"}</p>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4}></Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  render() {
    const leftButton = <NavigationButton back onClick={this.props.onGoBackToStartScreen} />;
    const rightButton = <NavigationButton onClick={this.props.onGoToNextScreen}>{this.props.t("kl_next").message || "Next"}</NavigationButton>;

    return <MainLayout leftButton={leftButton} rightButton={rightButton} content={this.renderContent()} />;
  }
}

export default withRouter(withTranslation()(WelcomeScreen));
