import React, { useState } from "react";
import { Grid, Table, Modal, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faArrowToRight, faCaretDown, faCaretUp, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Responsive } from "../../../../../layout";
import { ActionButtons, ConditionIcon, Expandable, GalleryButton } from "../../../../../components";
import { round2, renderInterventionElementType, getImageAndVideoFileTypes } from "../../../../../util/common";
import { ANSWERS } from "../../../../../util/answers";

import "./index.scss";

const renderPrice = (price, include_vat, vat) => {
  if (!(price > 0.01)) return null;

  if (include_vat) price = price * (1 + vat / 100);

  return <span className="scheduled-services-price">€ {round2(price).toFixed(2)}</span>;
};

const handleUpdateIntervention = (intervention_id, answer, readOnly, pendingInterventions, onUpdatePendingInterventions) => {
  if (readOnly) return;

  const updatedInterventions = pendingInterventions.map(i => (i.id === intervention_id ? { ...i, communication_result_status: answer } : i));

  onUpdatePendingInterventions(updatedInterventions);
};

const ScheduledServices = ({
  settings,
  showMobile,
  cartOnly,
  readOnly,
  showNotFixedButton,
  highlightInterventions,
  approvedInterventions,
  pendingInterventions,
  onUpdatePendingInterventions,
}) => {
  const { t } = useTranslation();
  const [showRemarkID, setShowRemarkID] = useState(null);
  const [isApprovedOpen, setIsApprovedOpen] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState(null);

  const { include_vat, vat, display_intervention_elements } = settings;

  const toggleRemark = id => {
    setShowRemarkID(prevId => (prevId === id ? null : id));
  };

  const renderIntervention = (intervention, index, highlightInterventions, readOnly, showNotFixedButton, cartOnly, isPendingIntervention = false) => {
    const { images, videos } = getImageAndVideoFileTypes(intervention.attachments);

    return (
      <Grid.Row
        className={`scheduled-services-item ${
          highlightInterventions && !intervention.customer_ok && intervention.communication_result_status === ANSWERS.NOT_ANSWERED ? "highlight" : ""
        }`}
        key={index}
      >
        <Grid.Column computer={5} widescreen={6} mobile={16}>
          <div className="scheduled-services-row-title">
            <ConditionIcon className="scheduled-services-condition-icon" status={intervention.question_result_status} />
            {display_intervention_elements && intervention.elements.length > 0 && (
              <Icon name="info circle" color="black" onClick={() => setSelectedIntervention(intervention)} />
            )}
            <Expandable text={intervention.title} />
          </div>
        </Grid.Column>
        <Grid.Column computer={isPendingIntervention ? 4 : 6} mobile={16}>
          <div className="scheduled-services-row-remark -margin-bottom-mobile-10">
            <Expandable text={intervention.mechanic_notes} />
          </div>
        </Grid.Column>
        <Grid.Column className={`scheduled-services-price-gallery ${!isPendingIntervention ? "approved-intervention" : ""}`} computer={3} mobile={16}>
          {renderPrice(intervention.price, include_vat, vat)}
          <GalleryButton images={images} videos={videos} />
        </Grid.Column>
        {isPendingIntervention && (
          <Grid.Column computer={4} widescreen={3} mobile={16}>
            <ActionButtons
              status={intervention.communication_result_status}
              showNotFixedButton={showNotFixedButton}
              onAnswer={answer => handleUpdateIntervention(intervention.id, answer, readOnly, pendingInterventions, onUpdatePendingInterventions)}
              readOnly={readOnly || intervention.readOnly}
              cartOnly={cartOnly}
            />
          </Grid.Column>
        )}
      </Grid.Row>
    );
  };

  const renderApprovedInterventions = () => {
    if (!approvedInterventions?.length) return null;

    return (
      <>
        <Grid.Row className="scheduled-services-header">
          <div className="scheduled-services-header-button" onClick={() => setIsApprovedOpen(!isApprovedOpen)}>
            <span className="scheduled-services-header-button-title">{t("cc_approved").message || "Approved"}</span>
            <FontAwesomeIcon icon={isApprovedOpen ? faCaretUp : faCaretDown} className="scheduled-services-header-button-icon" />
          </div>
        </Grid.Row>

        {isApprovedOpen &&
          approvedInterventions.map((intervention, index) => renderIntervention(intervention, index, highlightInterventions, readOnly, showNotFixedButton, cartOnly))}
      </>
    );
  };

  const renderPendingInterventions = () => {
    if (!pendingInterventions?.length) return null;

    return (
      <>
        <Grid.Row className="scheduled-services-header">
          <div className="scheduled-services-header-button">
            <span className="scheduled-services-header-button-title">{t("cc_approval_required").message || "Approval required"}</span>
          </div>
        </Grid.Row>
        {pendingInterventions.map((intervention, index) => renderIntervention(intervention, index, highlightInterventions, readOnly, showNotFixedButton, cartOnly, true))}
      </>
    );
  };

  const renderInterventionElementsModal = () => {
    return (
      <Modal open={!!selectedIntervention} className="scheduled-services-elements-modal" size="small">
        <Modal.Header>
          <div className="scheduled-services-elements-title">{selectedIntervention?.title}</div>
          <FontAwesomeIcon icon={faTimes} onClick={() => setSelectedIntervention(null)} />
        </Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Body>
              {selectedIntervention?.elements.map(e => (
                <Table.Row key={e.id}>
                  <Table.Cell>
                    {e.parent_dms_nr && (
                      <div className="intervention-element-arrow-to-right">
                        <FontAwesomeIcon icon={faArrowToRight} />
                      </div>
                    )}
                    {renderInterventionElementType(e.element_type)}
                    <div className="intervention-element-description">{e.description}</div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  };

  if (!approvedInterventions.length && !pendingInterventions.length) return null;

  return (
    <Responsive showMobile={showMobile}>
      <Grid className="v2-scheduled-services-container component-container">
        <Grid.Row>
          <div className="scheduled-services-header">
            <div className="scheduled-services-header-title">
              <FontAwesomeIcon icon={faCog} className="scheduled-services-header-icon" />
              <h4>{t("cc_services").message || "Services"}</h4>
            </div>
          </div>
        </Grid.Row>
        {renderApprovedInterventions()}
        {renderPendingInterventions()}
      </Grid>
      {renderInterventionElementsModal()}
    </Responsive>
  );
};

const filterApprovedInterventions = interventions => {
  return interventions.filter(i => i.customer_ok);
};

const filterPendingInterventions = interventions => {
  return interventions.filter(i => !i.customer_ok);
};

export default ScheduledServices;
export { filterApprovedInterventions, filterPendingInterventions };
