import React from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faWarehouse } from "@fortawesome/pro-regular-svg-icons";
import { withTranslation } from "react-i18next";

import { DesktopOnly } from "../../../../layout";
import { SEASONS, getTyreSeasonIcon } from "../../../../util/tyre";

import "./index.scss";

const TiresOverview = ({ communication, settings, showMobile, t }) => {
  const { on_car_tyres, in_storage_tyres } = communication.car;
  if (!on_car_tyres && !in_storage_tyres) return null;

  const getTyreStatus = (tyreProfile, season) => {
    const { all_season_a_threshold, all_season_n_threshold, winter_n_threshold, winter_a_threshold, summer_n_threshold, summer_a_threshold } = settings;

    switch (season) {
      case SEASONS.ALL_SEASON:
        if (tyreProfile < all_season_n_threshold) return "red-status";
        else if (tyreProfile < all_season_a_threshold) return "orange-status";
        else return "green-status";

      case SEASONS.WINTER:
        if (tyreProfile < winter_n_threshold) return "red-status";
        else if (tyreProfile < winter_a_threshold) return "orange-status";
        else return "green-status";

      case SEASONS.SUMMER:
        if (tyreProfile < summer_n_threshold) return "red-status";
        else if (tyreProfile < summer_a_threshold) return "orange-status";
        else return "green-status";

      default:
        return "green-status";
    }
  };

  const renderTyres = (leftProfile, rightProfile, leftTyre, rightTyre) => {
    if (!leftTyre && !rightTyre) return null;

    return (
      <div className="tyre-overview-section-content-container">
        <div className="tyre-overview-section-inner-container">
          <div className="tyre-overview-section">
            <div className={`tyre-overview-section-box ${getTyreStatus(leftProfile, leftTyre.season)}`}>
              <span>L</span>
            </div>
            <span className="tyre-overview-profile-text">{leftProfile} mm</span>
          </div>
          <div className="tyre-overview-manufacturer-container">
            <div className="tyre-overview-manufacturer-inner">
              {leftTyre.manufacturer} {getTyreSeasonIcon(leftTyre.season)}
            </div>
            <div className="tyre-overview-manufacturer-inner">{leftTyre.description}</div>
          </div>
        </div>
        <div className="tyre-overview-section-inner-container right-tyre">
          <div className="tyre-overview-section">
            <span className="tyre-overview-profile-text right-profile">{rightProfile} mm</span>
            <div className={`tyre-overview-section-box ${getTyreStatus(rightProfile, rightTyre.season)}`}>
              <span>R</span>
            </div>
          </div>
          <div className="tyre-overview-manufacturer-container">
            <div className="tyre-overview-manufacturer-inner">
              {getTyreSeasonIcon(rightTyre.season)} {rightTyre.manufacturer}
            </div>
            <div className="tyre-overview-manufacturer-inner">{rightTyre.description}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderTyresSection = (tyres, icon, title) => {
    if (!tyres) return null;

    const { fl_profile, fr_profile, rl_profile, rr_profile, front_left_tyre, front_right_tyre, rear_left_tyre, rear_right_tyre } = tyres;

    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <div className="tyre-overview-sub-heading">
            <FontAwesomeIcon icon={icon} className="tyre-overview-sub-heading-icon" />
            <h4 className="tyre-overview-sub-heading-title">{`${title} ${moment(tyres.updated_on).format("DD-MM-YYYY")}`}</h4>
          </div>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} className="tyre-overview-left-section-container">
          <div className="tyre-overview-section-heading-container">
            <span className="tyre-overview-section-heading-title">{t("cc_FRONT_TYRES").message || "FRONT TYRES"}</span>
          </div>
          {renderTyres(fl_profile, fr_profile, front_left_tyre, front_right_tyre)}
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} className="tyre-overview-right-section-container">
          <div className="tyre-overview-section-heading-container">
            <span className="tyre-overview-section-heading-title">{t("cc_REAR_TYRES").message || "REAR TYRES"}</span>
          </div>
          {renderTyres(rl_profile, rr_profile, rear_left_tyre, rear_right_tyre)}
        </Grid.Column>
      </Grid.Row>
    );
  };

  return (
    <DesktopOnly>
      <Grid className="tyre-overview-container component-container">
        {renderTyresSection(on_car_tyres, faCar, t("cc_Tire_control_on_car_datum").message || "Tire - On car - Control date")}
        {renderTyresSection(in_storage_tyres, faWarehouse, t("cc_Tire_control_in_storage_datum").message || "Tire - In storage - Control date")}
      </Grid>
    </DesktopOnly>
  );
};

export default withTranslation()(TiresOverview);
