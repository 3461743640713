import WelcomeScreen from "./Welcome";
import AuthenticationScreen from "./Authentication";
import CheckInfoScreen from "./CheckInfo";
import OrderScreen from "./Order";
import DropKeyScreen from "./DropKey";
import ThankYouScreen from "./ThankYou";

const SCREENS = {
  FIRST_SCREEN: 1,
  WELCOME: 1,
  AUTH_OPTIONS: 2,
  CHECK_INFO: 3,
  ORDER: 4,
  DROP_KEY: 5,
  THANK_YOU: 6,
  LAST_SCREEN: 6,
};

export default { SCREENS, WelcomeScreen, AuthenticationScreen, CheckInfoScreen, OrderScreen, DropKeyScreen, ThankYouScreen };
