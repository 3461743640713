import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faPhone, faCheck, faTimes, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { ANSWERS } from "../../util/answers";
import "./index.scss";
import { DesktopOnly } from "../../layout";

class ActionButtons extends Component {
  handleDontFix = () => {
    const { readOnly, status } = this.props;

    if (readOnly) return;

    if (status === ANSWERS.NOT_OK) this.props.onAnswer(ANSWERS.NOT_ANSWERED);
    else this.props.onAnswer(ANSWERS.NOT_OK);
  };

  handleAddToCart = () => {
    const { readOnly, status } = this.props;

    if (readOnly) return;

    if (status === ANSWERS.ADD_TO_CART) this.props.onAnswer(ANSWERS.NOT_ANSWERED);
    else this.props.onAnswer(ANSWERS.ADD_TO_CART);
  };

  handleContactMe = () => {
    const { readOnly, status } = this.props;

    if (readOnly) return;

    if (status === ANSWERS.CONTACT_ME) this.props.onAnswer(ANSWERS.NOT_ANSWERED);
    else this.props.onAnswer(ANSWERS.CONTACT_ME);
  };

  render() {
    const { readOnly, showNotFixedButton, cartOnly, status } = this.props;

    return (
      <div className={"action-buttons-container" + (readOnly ? " read-only" : "")}>
        {showNotFixedButton ? (
          <div className={status === ANSWERS.NOT_OK ? "action-buttons-dont-fix selected" : "action-buttons-dont-fix"} onClick={this.handleDontFix}>
            <FontAwesomeIcon className="action-buttons-icon" icon={faTimes} />
          </div>
        ) : (
          <DesktopOnly>
            <div className="action-buttons-spacer"></div>
          </DesktopOnly>
        )}
        {!cartOnly && (
          <div className={status === ANSWERS.CONTACT_ME ? "action-buttons-contact selected" : "action-buttons-contact"} onClick={this.handleContactMe}>
            <FontAwesomeIcon className="action-buttons-icon" icon={faPhone} />
          </div>
        )}
        <div className={status === ANSWERS.ADD_TO_CART ? "action-buttons-cart selected" : "action-buttons-cart"} onClick={this.handleAddToCart}>
          {status !== ANSWERS.ADD_TO_CART && <FontAwesomeIcon icon={faPlus} className="action-buttons-cart-plus" />}
          {status === ANSWERS.ADD_TO_CART && <FontAwesomeIcon icon={faCheck} className="action-buttons-cart-checked" />}
          <FontAwesomeIcon className="action-buttons-icon" icon={faShoppingCart} />
        </div>
      </div>
    );
  }
}

export default ActionButtons;
