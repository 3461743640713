import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { Cancel, CallForHelp } from "../../../../../components";

import AuthenticationHelp from "../AuthenticationHelp";
import AuthenticationError from "../AuthenticationError";
import ConfirmAuthenticationMethod from "./ConfirmAuthenticationMethod";

import Service from "./service";

import "./index.scss";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  CALL_FOR_HELP: 4,
  ERROR: 5,
};

export const COMMUNICATION_CHANNELS = {
  EMAIL: 1,
  SMS: 2,
};

class ConfirmAuthenticationScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    loading: false,
  };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToErrorScreen = () => this.setState({ screen: SUBSCREENS.ERROR });

  handleConfirmAuthentication = () => {
    const { onGoToEnterPinScreen, phone_nr, displayedPhoneNr, reg_nr, communicationChannel, match } = this.props;

    this.setState({ loading: true }, () => {
      const requestData = { private_key: match.params.keylockerKey, communication_channel_id: communicationChannel };

      if (reg_nr) requestData.reg_nr = reg_nr;
      else requestData.phone_nr = phone_nr;

      if (!communicationChannel) requestData.communication_channel_id = !displayedPhoneNr ? COMMUNICATION_CHANNELS.EMAIL : COMMUNICATION_CHANNELS.SMS;

      Service.confirmAuthenticationMethod(requestData)
        .then(() => this.setState({ loading: false }, () => onGoToEnterPinScreen()))
        .catch(() => this.setState({ loading: false }, () => this.goToErrorScreen()));
    });
  };

  render() {
    const { screen, loading } = this.state;
    const { renderLayout, onAbort, emailAddress, phone_nr, displayedPhoneNr, handleSelectAuthenticationMethod, communicationChannel, t } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <ConfirmAuthenticationMethod
            renderLayout={renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancel={this.goToCancel}
            handleConfirmAuthentication={this.handleConfirmAuthentication}
            handleSelectAuthenticationMethod={handleSelectAuthenticationMethod}
            communicationChannel={communicationChannel}
            emailAddress={emailAddress}
            phone_nr={phone_nr}
            displayedPhoneNr={displayedPhoneNr}
            loading={loading}
          />
        );

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={renderLayout} onGoBack={this.goBack} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;

      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={renderLayout}
            onGoBack={this.goBack}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            message={t("kl_confirm_email_error").message || "An error occurred, please try again or abort."}
          />
        );
    }
  }
}

export default withRouter(withTranslation()(ConfirmAuthenticationScreen));
