import axios from "axios";

import ENV from "../../../../util/env-config";
class Service {
  static callForHelp(requestData) {
    return axios.post(ENV.keylockerBase + "/keylockers/call_for_help", requestData);
  }
}

export default Service;
