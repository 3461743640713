import React from "react";
import { withTranslation } from "react-i18next";

import "./index.scss";

const VatIndicator = ({ include_vat, t }) => {
  return (
    <div className="bottom-vat-container component-container">
      <div className="bottom-vat-inner-container">
        {include_vat && <span className="prices_text">*{t("cc_all_prices_including").message || "All prices are including"}</span>}
        {!include_vat && <span className="prices_text">*{t("cc_all_prices_excluding").message || "All prices are excluding"}</span>}
        <span className="vat">{t("cc_VAT").message || "VAT"}</span>
      </div>
    </div>
  );
};

export default withTranslation()(VatIndicator);
