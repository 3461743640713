import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { CallForHelp, Cancel } from "../../../components";

import MainLayout from "../../../layout";

import OrderHelp from "./OrderHelp";
import Order from "./Order";
import OrderEditRemarks from "./OrderEditRemarks";
import OrderEditCustomerName from "./OrderEditCustomerName";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  EDIT_REMARKS: 4,
  EDIT_NAME: 5,
  CALL_FOR_HELP: 6,
};

const STEP = 3;
class OrderScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    additionalRemark: "",
    agreements: this.props.communication.agreements,
    customerName: "",
    showAgreementError: false,
    showCustomerNameError: false,
  };

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kl_step").message || "Step") + " " + STEP;
    const title = t("kl_validate_appointment_list").message || "Validate Appointment List";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToEditRemarks = () => this.setState({ screen: SUBSCREENS.EDIT_REMARKS });
  goToEditCustomerName = () => this.setState({ screen: SUBSCREENS.EDIT_NAME });
  goToNextScreen = () => {
    const hasMissingMandatoryAgreements = this.state.agreements.some(a => !a.optional_keylocker_check_in && !a.accepted);

    if (hasMissingMandatoryAgreements) {
      this.setState({ showAgreementError: true }, () => {
        document.querySelector("#OrderServices").scrollTop = document.querySelector(".OrderAgreements").offsetTop;
      });
    } else if (!this.state.customerName && !this.props.keylocker.is_2fa_to_drop_key_enabled) {
      this.setState({ showCustomerNameError: true }, () => {
        document.querySelector("#OrderServices").scrollTop = document.querySelector(".OrderCustomerName").offsetTop;
      });
    } else {
      this.props.onGoToNextScreenWithState({
        additionalRemark: this.state.additionalRemark || null,
        customerName: this.state.customerName,
        customerAcceptedAgreementsIDs: this.state.agreements.filter(a => a.accepted).map(a => a.id),
      });
    }
  };

  saveRemarks = additionalRemark => {
    this.setState({ screen: SUBSCREENS.MAIN, additionalRemark }, () => {
      document.querySelector("#OrderServices").scrollTop = document.querySelector("#OrderServices").scrollHeight;
    });
  };

  handleAgreementChecked = id => {
    const agreements = this.state.agreements.map(a => (a.id === id ? { ...a, accepted: !a.accepted } : a));

    this.setState({
      agreements,
    });
  };

  saveCustomerName = customerName => {
    this.setState({ screen: SUBSCREENS.MAIN, customerName }, () => {
      document.querySelector("#OrderServices").scrollTop = document.querySelector("#OrderServices").scrollHeight;
    });
  };

  render() {
    const { screen, agreements, additionalRemark, customerName, showAgreementError, showCustomerNameError } = this.state;
    const { communication, keylocker, onAbort } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <Order
            renderLayout={this.renderLayout}
            communication={communication}
            keylocker={keylocker}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToNextScreen={this.goToNextScreen}
            agreements={agreements}
            customerName={customerName}
            showAgreementError={showAgreementError}
            showCustomerNameError={showCustomerNameError}
            handleAgreementChecked={this.handleAgreementChecked}
            onGoToEditRemarks={this.goToEditRemarks}
            onGoToEditCustomerName={this.goToEditCustomerName}
            additionalRemark={additionalRemark}
          />
        );

      case SUBSCREENS.HELP:
        return <OrderHelp renderLayout={this.renderLayout} onGoBack={this.goBack} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} communication={communication} />;

      case SUBSCREENS.EDIT_REMARKS:
        return <OrderEditRemarks renderLayout={this.renderLayout} additionalRemark={additionalRemark} onGoBack={this.goBack} onSave={this.saveRemarks} />;

      case SUBSCREENS.EDIT_NAME:
        return <OrderEditCustomerName renderLayout={this.renderLayout} customerName={customerName} onGoBack={this.goBack} onSave={this.saveCustomerName} />;
    }
  }
}

export default withTranslation()(OrderScreen);
