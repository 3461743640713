import React from "react";
import { withTranslation } from "react-i18next";
import { round2 } from "../../../../util/common";
import { TYRE_POSITION } from "../../../../util/tyre";
import "./index.scss";

const TotalAmount = ({ communication, settings, t }) => {
  const { results } = communication;
  const { interventions } = communication.appointment;
  const { vat, total_pdf_enabled, include_vat } = settings;

  if (!total_pdf_enabled) return null;

  let totalAmount = 0;

  interventions.forEach(item => {
    if (item.mechanic_fixed && item.price > 0.01) {
      totalAmount += vat && include_vat ? item.price * (1 + vat / 100) : item.price;
    }
  });

  results.forEach(item => {
    if (TYRE_POSITION.IS_TYRE(item.tyre_position) && item.tyre_replacements.length > 0) {
      const rep = item.tyre_replacements.find(rep => rep.mechanic_fixed);
      if (rep && rep.price > 0.01) totalAmount += vat && include_vat ? rep.price * (1 + vat / 100) : rep.price;
    } else {
      if (item.mechanic_fixed && item.price > 0.01) totalAmount += vat && include_vat ? item.price * (1 + vat / 100) : item.price;
    }
  });

  if (totalAmount <= 0) return null;

  const vatAmount = include_vat ? totalAmount - totalAmount / (1 + vat / 100) : 0;

  return (
    <div className="report-total-amount-container">
      <span>
        {t("cc_total_amount").message || "Total amount"}
        {vat && include_vat && " (" + (t("cc_vat_included").message || "Incl. VAT") + " " + vat + "% - € " + vatAmount.toFixed(2) + " )"}
        :&nbsp;&nbsp;&nbsp;&nbsp;€ {round2(totalAmount).toFixed(2)}
      </span>
    </div>
  );
};

export default withTranslation()(TotalAmount);
