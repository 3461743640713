import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  TurboHoetHeader,
  TotalAmount,
  ImageGallery,
  FixedCheckItems,
  NotFixedCheckItems,
  WorkingCheckItems,
  TiresOverview,
  Signatures,
  VatIndicator,
} from "../../components";

class TurboHoetCheckTemplate extends Component {
  renderMainContent = () => {
    const { settings, communication, t } = this.props;
 
    const fixedItems = FixedCheckItems.filterFixedItems(communication.results);
    const notFixedItems = NotFixedCheckItems.filterNotFixedItems(communication.results);
    const workingItems = WorkingCheckItems.filterWorkingsItems(communication.results);
    const lettersMap = ImageGallery.getLettersMap(communication.appointment, fixedItems, notFixedItems, WorkingCheckItems.filterWorkingsItemsWithRemarks(workingItems));
    const signatureItems = Signatures.filterSignatureItems(communication.results);

    return (
      <div className="mainContent">
        <div className="simple-header-container">
          <h1>{t("cc_turbocharge_rating").message || "Turbocharger rating"}</h1>
        </div>
        <TurboHoetHeader settings={settings} communication={communication} />
        <FixedCheckItems lettersMap={lettersMap} fixedItems={fixedItems} settings={settings} communication={communication} />
        {fixedItems.length > 0 && <TotalAmount communication={communication} settings={settings} />}
        <ImageGallery lettersMap={lettersMap} />
        <NotFixedCheckItems lettersMap={lettersMap} notFixedItems={notFixedItems} settings={settings} communication={communication} />
        <TiresOverview communication={communication} settings={settings} />
        <WorkingCheckItems lettersMap={lettersMap} workingItems={workingItems} communication={communication} />
        <Signatures signatureItems={signatureItems} settings={settings} communication={communication} />
        <VatIndicator include_vat={settings.include_vat} />
      </div>
    );
  };

  render() {
    return <div className="report-template">{this.renderMainContent()}</div>;
  }
}

export default withTranslation()(TurboHoetCheckTemplate);
