export const SCREENS = {
  FIRST_SCREEN: 1,
  INFO: 1,
  SCHEDULED_SERVICES: 2,
  CRITICAL_ITEMS: 3,
  ADVISED_ITEMS: 4,
  OTHER_ITEMS: 5,
  SIGNATURE: 6,
  THANK_YOU: 7,
};
