import React from "react";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { getPhoneNumber, getEmail } from "../../../../../util/common";

import { HelpButton, CancelButton, NavigationButton } from "../../../components";

import "./CheckInfo.scss";

const CheckInfo = ({ renderLayout, extraPhone, communication, onGoToHelpScreen, onGoToCancelScreen, onGoToAddPhoneNumber, onGoToNextScreenWithState, t }) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} />, <CancelButton key={2} onClick={onGoToCancelScreen} />];
  const email = communication.correct_email || getEmail(communication);
  const phone = communication.correct_phone || getPhoneNumber(communication);
  const { customer } = communication;

  let salutation = t("kl_hello").message || "Hello";
  if (customer?.title && customer.surname) {
    salutation += ` ${customer.title}`;
    if (customer.firstname) salutation += ` ${customer.firstname}`;

    salutation += ` ${customer.surname}`;
  }

  const content = (
    <div className="CheckInfoScreen">
      <div className="KeyLockerTitle">{salutation},</div>
      <div className="CheckInfoMessage">{t("kl_these_are_the_info_we_have_from_you").message || "These are the information that we have from you:"}</div>
      <div className="CheckInfoDetail">
        {email && (
          <div>
            <Icon name="envelope" />
            {email}
          </div>
        )}
        {phone && (
          <div>
            <Icon name="phone" />
            {phone}
          </div>
        )}
      </div>
      <div className="CheckInfoMessage">{t("kl_we_will_use_them_to_contact_you_if_necessary").message || "We will use them to contact you if necessary."}</div>
      {extraPhone && (
        <>
          <div className="CheckInfoExtraPhone">{t("kl_additional_number").message || "Additional number"}</div>
          <div className="CheckInfoDetail">
            <div>
              <Icon name="phone" />
              {extraPhone}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const leftButton = (
    <NavigationButton secondary onClick={onGoToAddPhoneNumber}>
      {!extraPhone && (t("kl_add_phone_number").message || "Add Phone Number")}
      {extraPhone && (t("kl_edit_phone_number").message || "Edit Phone Number")}
    </NavigationButton>
  );

  const rightButton = <NavigationButton onClick={() => onGoToNextScreenWithState({ extraPhone: extraPhone || null })} />;

  return renderLayout({ headerButtons, leftButton, rightButton, content });
};

export default withTranslation()(CheckInfo);
