import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import AuthenticationError from "../AuthenticationError";
import ScanQRCode from "./ScanQRCode";

import "./ScanQRCode.scss";

const SUBSCREENS = {
  MAIN: 1,
  ERROR: 2,
};

class ScanQRCodeScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
  };

  goToMain = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToErrorScreen = () => this.setState({ screen: SUBSCREENS.ERROR });

  render() {
    const { screen } = this.state;
    const { renderLayout, onGoBack, onAbort, softmode, t } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return <ScanQRCode renderLayout={renderLayout} onGoToErrorScreen={this.goToErrorScreen} onGoBack={onGoBack} onAbort={onAbort} softmode={softmode} />;
      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={renderLayout}
            onGoBack={onGoBack}
            message={t("kl_scan_qr_code_auth_error").message || "An error occurred, please try again or contact an admin"}
          />
        );
    }
  }
}

export default withTranslation()(ScanQRCodeScreen);
