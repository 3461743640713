import React from "react";
import { Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUserHardHat, faFileAlt, faDollarSign, faPercentage, faCogs, faKey, faBars } from "@fortawesome/pro-solid-svg-icons";

import { TYRE_POSITION, getTyreItemName } from "./tyre";

export const QUESTION_RESULT_STATUS = {
  OK: 0,
  OK_WITH_REMARKS: 1,
  ADVISED: 2,
  NECESSARY: 3,
  NOT_WORKED_ON: 4,
};

export const INTERVENTION_ELEMENT_TYPE = {
  TASK: 1,
  LABOR: 2,
  PART: 3,
  TEXT: 4,
  DISCOUNT: 5,
  SMALL_PART: 6,
  FEE: 7,
  RENTAL: 8,
  MENU: 9,
};

export const APPOINTMENT_STATUSES = {
  CANCELED: 0,
  NEW_CAR: 1,
  CAR_CHECK: 2,
  PRICING_OK: 3,
  CUSTOMER_OK: 4,
  CAR_READY: 5,
  DIAGNOSE: 6,
  AANNAME_OK: 7,
  CALCULATING: 8,
  CONTACT: 9,
  CAR_CHECK_STARTED: 10,
  QUALITY_CHECK: 11,
  CUSTOMER_ANSWERED: 12,
  LAST_REAL_STATUS: 12,
  BACK_ORDER: 19,
  HANDLE_CHECK_IN: 50,
  CHECK_IN_DONE: 51,
  CAR_IN_SHOP: 52,
  CAR_OK_PLUS_REPAIR_OVERVIEW: 53,
  QUALITY_CHECK_PLUS_REPAIR_OVERVIEW: 54,
  CAR_OUT_OF_SHOP: 55,
};

export const renderInterventionElementType = type => {
  switch (type) {
    case INTERVENTION_ELEMENT_TYPE.PART:
      return (
        <Label>
          <FontAwesomeIcon icon={faCog} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.LABOR:
      return (
        <Label>
          <FontAwesomeIcon icon={faUserHardHat} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.TEXT:
      return (
        <Label>
          <FontAwesomeIcon icon={faFileAlt} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.DISCOUNT:
      return (
        <Label>
          <FontAwesomeIcon icon={faPercentage} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.SMALL_PART:
      return (
        <Label>
          <FontAwesomeIcon icon={faCogs} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.FEE:
      return (
        <Label>
          <FontAwesomeIcon icon={faDollarSign} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.RENTAL:
      return (
        <Label>
          <FontAwesomeIcon icon={faKey} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.MENU:
      return (
        <Label>
          <FontAwesomeIcon icon={faBars} />
        </Label>
      );

    default:
      return null;
  }
};

export function round2(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const getPhoneNumber = communication => {
  let phoneNr = "";

  if (communication.customer) {
    if (communication.customer.tel_mobile_private) phoneNr = communication.customer.tel_mobile_private;

    if (communication.customer.tel_private_nr) phoneNr = communication.customer.tel_private_nr;

    if (communication.customer.tel_mobile_business) phoneNr = communication.customer.tel_mobile_business;

    if (communication.customer.tel_business_nr) phoneNr = communication.customer.tel_business_nr;
  }

  return phoneNr;
};

export const getEmail = communication => {
  const event = communication.events && communication.events.sort((a, b) => (a.created_on < b.created_on ? 1 : -1)).find(e => e.receivers?.length);
  if (event) {
    let receiver = event.receivers.find(r => r.channel === 1);
    if (receiver) return receiver.destination;
  }

  if (communication.customer) {
    if (communication.customer.email_private) return communication.customer.email_private;

    if (communication.customer.email_business) return communication.customer.email_business;
  }

  return "";
};

export function normalizeCommunicationData(communication, i18n) {
  if (communication.settings) i18n.changeLanguage(communication.settings.language_code || "en-GB");

  if (communication && communication.appointment) {
    document.title = communication.appointment.car_make + " " + communication.appointment.car_model + " " + communication.appointment.reg_number;

    if (!Array.isArray(communication.agreements)) communication.agreements = [];

    if (!Array.isArray(communication.check_in_results)) communication.check_in_results = [];
    if (!Array.isArray(communication.results)) communication.results = [];

    let withTyres = communication.results.filter(r => r.tyre_position > 0);
    if (withTyres.length > 0) {
      let latestCheck = withTyres[0].check_id;
      if (withTyres.some(t => t.check_id !== latestCheck)) {
        withTyres.forEach(t => {
          if (t.check_id > latestCheck) latestCheck = t.check_id;
        });

        communication.results = communication.results.filter(r => r.tyre_position === 0 || r.check_id === latestCheck);
      }
    }

    communication.results.forEach(r => {
      if (!r.answer_item) r.answer_item = {};
      if (r.customer_approved === undefined) r.customer_approved = false;
      if (!r.tyre_replacements) r.tyre_replacements = [];
      if (!r.tyre) r.tyre = {};
    });

    communication.results = communication.results.filter(
      item => item.question_status > -1 || item.tyre_position > 0 || ["signature", "employee_signature", "customer_signature"].includes(item.answer_item.template)
    );

    communication.results.sort((a, b) => {
      if (a.checklist_type === 4 && b.checklist_type !== 4) return -1;
      else if (a.checklist_type !== 4 && b.checklist_type === 4) return 1;

      if (a.checklist_order < b.checklist_order) return -1;
      else if (a.checklist_order > b.checklist_order) return 1;

      if (a.check_id < b.check_id) return -1;
      else if (a.check_id > b.check_id) return 1;

      if (a.group_order < b.group_order) return -1;
      else if (a.group_order > b.group_order) return 1;

      if (a.group_name < b.group_name) return -1;
      else if (a.group_name > b.group_name) return 1;

      if (a.question_order < b.question_order) return -1;
      else if (a.question_order > b.question_order) return 1;

      if (a.question_result_id < b.question_result_id) return -1;
      else if (a.question_result_id > b.question_result_id) return 1;

      if (a.tyre_position < b.tyre_position) return -1;
      else if (a.tyre_position > b.tyre_position) return 1;

      return a.title < b.title ? -1 : 1;
    });

    if (!Array.isArray(communication.appointment.interventions)) communication.appointment.interventions = [];
    if (!Array.isArray(communication.appointment.notes)) communication.appointment.notes = [];

    communication.appointment.interventions.sort((i1, i2) => {
      if (i1.dms_nr && i2.dms_nr) {
        if (i1.dms_nr !== i2.dms_nr) return i1.dms_nr.localeCompare(i2.dms_nr, undefined, { numeric: true, sensitivity: "base" });
      } else if (!i1.dms_nr && i2.dms_nr) return 1;
      else if (i1.dms_nr && !i2.dms_nr) return -1;

      return i1.id - i2.id;
    });

    communication.appointment.interventions.forEach((i, j) => {
      i.intervention_index = j;

      if (!i.elements) i.elements = [];
      else {
        i.elements.sort((a, b) => {
          if (!a.dms_nr && !b.dms_nr) return a.id - b.id;

          if (a.parent_dms_nr === b.parent_dms_nr) return a.dms_nr.localeCompare(b.dms_nr, undefined, { numeric: true, sensitivity: "base" });

          if ((a.parent_dms_nr === "") !== (b.parent_dms_nr === "")) {
            if (a.parent_dms_nr === b.dms_nr) return 1;
            else if (b.parent_dms_nr === a.dms_nr) return -1;
            return (a.parent_dms_nr || a.dms_nr).localeCompare(b.parent_dmsn_nr || b.dms_nr, undefined, { numeric: true, sensitivity: "base" });
          }

          return a.parent_dms_nr.localeCompare(b.parent_dmsn_nr, undefined, { numeric: true, sensitivity: "base" });
        });
      }
    });

    if (!Array.isArray(communication.check_in_remarks)) communication.check_in_remarks = [];
    if (!Array.isArray(communication.diagnose_overview_remarks)) communication.diagnose_overview_remarks = [];
  } else return false;

  return true;
}

export function getItemName(t, r) {
  if (!r.tyre_position || TYRE_POSITION.IS_TRUCK_TYRE(r.tyre_position)) return r.title;

  return getTyreItemName(t, r);
}

export function renderCarRegistration(country, reg_nr) {
  const countryCode = ["BE", "FR", "LU"].includes(country) ? country[0] : country;

  return (
    <div className={`header-info-reg-num-box ${country || ""}`}>
      <div className="header-info-reg-country">
        <div className="header-info-reg-country-text">{countryCode || ""}</div>
      </div>

      <div className="header-info-reg-num">{reg_nr.split("-").join(" - ")}</div>
    </div>
  );
}

export const getYYYMMDD = () => {
  let now = new Date();
  let y = now.getFullYear();
  let m = now.getMonth() + 1;
  let d = now.getDate();
  let mm = m < 10 ? "0" + m : m;
  let dd = d < 10 ? "0" + d : d;
  return "" + y + mm + dd;
};

export const getImageAndVideoFileTypes = files => {
  let images = [],
    videos = [];

  if (files?.length) {
    images = files.filter(file => file.type.startsWith("image/"));
    videos = files.filter(file => file.type.startsWith("video/"));
  }

  return {
    images,
    videos,
  };
};
