import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

import { ImageLetters } from "../";
import { TYRE_POSITION } from "../../../../util/tyre";
import { QUESTION_RESULT_STATUS } from "../../../../util/common";

import "./index.scss";

function filterWorkingsItemsWithRemarks(workingItems) {
  return workingItems.filter(
    r =>
      !(
        (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_fixed)) ||
        r.question_status !== QUESTION_RESULT_STATUS.OK_WITH_REMARKS ||
        r.mechanic_fixed
      )
  );
}

class WorkingCheckItems extends Component {
  render() {
    let { workingItems, t, lettersMap } = this.props;
    const withRemarks = filterWorkingsItemsWithRemarks(workingItems);

    let groups = [];
    let i = -1;
    let last_checklist = "";
    let last_group = "";

    workingItems.forEach(r => {
      if ((TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_fixed)) || r.question_status !== QUESTION_RESULT_STATUS.OK) return;

      if (r.checklist_name !== last_checklist || r.group_name !== last_group) {
        last_checklist = r.checklist_name;
        last_group = r.group_name;
        i++;
        groups[i] = [];
      }

      groups[i].push(r);
    });

    if (withRemarks.length < 1 && groups.length < 1) return null;

    return (
      <Grid className="report-working-items-checks-container component-container">
        <Grid.Row width={16} className="working-items-checks-heading">
          <FontAwesomeIcon icon={faCheckCircle} className="working-items-checks-heading-icon" />
          <h4 className="working-items-checks-heading-title">{t("cc_checked_working_properly").message || "Checked items working properly"}</h4>
        </Grid.Row>

        {withRemarks.length > 0 && (
          <Grid className="working-items-checks-with-remarks">
            {withRemarks.map(result => (
              <Grid.Row key={result.question_result_id * 10 + result.tyre_position} className="working-items-checks-row">
                <Grid.Column width={7} className="working-items-checks-row-column">
                  <ImageLetters images={result.images} lettersMap={lettersMap} />
                  <span className="working-items-checks-title">{result.title}</span>
                </Grid.Column>
                <Grid.Column width={9} className="working-items-checks-remarks">
                  {result.mechanic_notes && <span>{result.mechanic_notes}</span>}
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        )}

        <Grid className="working-items-checks-groups">
          <Grid.Column width={8}>
            {groups
              .filter((g, i) => i % 2 === 0)
              .map((group, idx) => (
                <Grid.Row key={"evengroup_name" + idx}>
                  <div className="working-items-checks-group-name-title-container">
                    <FontAwesomeIcon icon={faCheckCircle} className="working-items-checks-group-name-title-icon" />
                    <h3 className="working-items-checks-group-name-title">{group[0].group_name}</h3>
                  </div>
                  {group.map(result => (
                    <div className="working-items-checks-group-name-item-box" key={"evengroup" + idx + "question" + result.question_result_id + result.tyre_position}>
                      <span className="working-items-checks-group-title">{result.title}</span>
                    </div>
                  ))}
                </Grid.Row>
              ))}
          </Grid.Column>
          <Grid.Column width={8}>
            {groups
              .filter((g, i) => i % 2 !== 0)
              .map((group, idx) => (
                <Grid.Row key={"oddgroup_name" + idx}>
                  <div className="working-items-checks-group-name-title-container">
                    <FontAwesomeIcon icon={faCheckCircle} className="working-items-checks-group-name-title-icon" />
                    <h3 className="working-items-checks-group-name-title">{group[0].group_name}</h3>
                  </div>
                  {group.map(result => (
                    <div className="working-items-checks-group-name-item-box" key={"oddgroup" + idx + "question" + result.question_result_id + result.tyre_position}>
                      <span className="working-items-checks-group-title">{result.title}</span>
                    </div>
                  ))}
                </Grid.Row>
              ))}
          </Grid.Column>
        </Grid>
      </Grid>
    );
  }
}

const TranslatedWorkingCheckItems = withTranslation()(WorkingCheckItems);

TranslatedWorkingCheckItems.filterWorkingsItems = results => results.filter(r => !r.mechanic_fixed && r.question_status <= QUESTION_RESULT_STATUS.OK_WITH_REMARKS);

TranslatedWorkingCheckItems.filterWorkingsItemsWithRemarks = filterWorkingsItemsWithRemarks;

export default TranslatedWorkingCheckItems;
