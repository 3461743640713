import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import OptionalItems from "./components/OptionalItems";
import MainLayout from "../../layout";

import "./index.scss";

export const QUESTION_STATUS = {
  ADVISED: 2,
  CRITICAL: 3,
};

class OptionalItemsScreen extends Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    const { settings, readOnly, communication, onGoToNextScreen, onGoToPreviousScreen, question_status, items, updateItems, t } = this.props;

    const content = (
      <div className="OptionalItemsScreen">
        <OptionalItems
          question_status={question_status}
          readOnly={readOnly}
          settings={settings}
          showMobile={true}
          items={items}
          showNotFixedButton={true}
          updateItems={updateItems}
          cartOnly={true}
        />
      </div>
    );

    const navigationButtons = (
      <>
        <Button className="DeskCheckinNavigationLeftButton" onClick={onGoToPreviousScreen} loading={loading} disabled={loading}>
          <FontAwesomeIcon icon={faArrowLeft} className="navigation-icon" />
          <span className="navigation-text">{t("cc_back").message || "BACK"}</span>
        </Button>
        <Button className="DeskCheckinNavigationRightButton" onClick={onGoToNextScreen} loading={loading} disabled={loading}>
          <span className="navigation-text">{t("cc_next").message || "NEXT"}</span>
          <FontAwesomeIcon icon={faArrowRight} className="navigation-icon" />
        </Button>
      </>
    );

    return <MainLayout content={content} navigationButtons={navigationButtons} showTitle isCheckingOut={communication.is_checking_out} />;
  }
}

const TranslatedOptionalItemsScreen = withTranslation()(OptionalItemsScreen);

TranslatedOptionalItemsScreen.filterAdvisedItems = items => OptionalItems.filterAdvisedItems(items);

TranslatedOptionalItemsScreen.filterCriticalItems = items => OptionalItems.filterCriticalItems(items);

TranslatedOptionalItemsScreen.QUESTION_STATUS = QUESTION_STATUS;

export default TranslatedOptionalItemsScreen;
