import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faSnowflake, faSunDust } from "@fortawesome/pro-regular-svg-icons";

export const TYRE_POSITION = {
  CAR_FRONT_LEFT: 1,
  CAR_FRONT_RIGHT: 2,
  CAR_REAR_LEFT: 3,
  CAR_REAR_RIGHT: 4,
  STORAGE_FRONT_LEFT: 5,
  STORAGE_FRONT_RIGHT: 6,
  STORAGE_REAR_LEFT: 7,
  STORAGE_REAR_RIGHT: 8,
  CAR_SPARE: 9,
  TRUCK_LEFT: 10,
  TRUCK_RIGHT: 11,
  TRUCK_EXTERIOR_LEFT: 12,
  TRUCK_EXTERIOR_RIGHT: 13,
  TRUCK_INTERIOR_LEFT: 14,
  TRUCK_INTERIOR_RIGHT: 15,
  TRUCK_SPARE: 16,
  IS_TYRE: position => position > 0,
  IS_TRUCK_TYRE: position => position > 9,
};

export const SEASONS = {
  ALL_SEASON: 0,
  WINTER: 1,
  SUMMER: 2,
};

export function getTyreItemName(t, r) {
  let title = "";

  switch (r.tyre_position) {
    case TYRE_POSITION.CAR_FRONT_LEFT:
      title = t("cc_car_front_left_tyre").message || "Car front left tyre";
      break;
    case TYRE_POSITION.CAR_FRONT_RIGHT:
      title = t("cc_car_front_right_tyre").message || "Car front right tyre";
      break;

    case TYRE_POSITION.CAR_REAR_LEFT:
      title = t("cc_car_rear_left_tyre").message || "Car rear left tyre";
      break;

    case TYRE_POSITION.CAR_REAR_RIGHT:
      title = t("cc_car_rear_right_tyre").message || "Car rear right tyre";
      break;

    case TYRE_POSITION.STORAGE_FRONT_LEFT:
      title = t("cc_storage_front_left_tyre").message || "Storage front left tyre";
      break;

    case TYRE_POSITION.STORAGE_FRONT_RIGHT:
      title = t("cc_storage_front_right_tyre").message || "Storage front right tyre";
      break;

    case TYRE_POSITION.STORAGE_REAR_LEFT:
      title = t("cc_storage_rear_left_tyre").message || "Storage rear left tyre";
      break;

    case TYRE_POSITION.STORAGE_REAR_RIGHT:
      title = t("cc_storage_rear_right_tyre").message || "Storage rear right tyre";
      break;

    case TYRE_POSITION.TRUCK_LEFT:
    case TYRE_POSITION.TRUCK_RIGHT:
    case TYRE_POSITION.TRUCK_EXTERIOR_LEFT:
    case TYRE_POSITION.TRUCK_EXTERIOR_RIGHT:
    case TYRE_POSITION.TRUCK_INTERIOR_LEFT:
    case TYRE_POSITION.TRUCK_INTERIOR_RIGHT:
    case TYRE_POSITION.TRUCK_SPARE:
      return r.title;

    default:
      return "";
  }

  return title;
}

export function getTyreSeasonIcon(s, color) {
  switch (s) {
    case SEASONS.ALL_SEASON:
      return <FontAwesomeIcon className="tyre-season-icon" icon={faSunDust} style={{ color: color ? color : "#21ba45" }} />;
    case SEASONS.WINTER:
      return <FontAwesomeIcon className="tyre-season-icon" icon={faSnowflake} style={{ color: color ? color : "#2185d0" }} />;
    case SEASONS.SUMMER:
      return <FontAwesomeIcon className="tyre-season-icon" icon={faSun} style={{ color: color ? color : "#fbbd08" }} />;
    default:
      return null;
  }
}
