import StartScreen from "./Start";
import Drop from "./Drop";
import PickUp from "./PickUp";

const MODE = {
  CHOOSE: 1,
  DROP: 2,
  PICKUP: 3,
};

export { StartScreen, Drop, PickUp, MODE };
