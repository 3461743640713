import React from "react";
import { Helmet } from "react-helmet";

const ExternalScripts = ({ settings, noExternalJS, children }) => {
  if (!noExternalJS && settings.external_js && !document.getElementById("external_js")) {
    const script = document.createElement("script");
    script.src = settings.external_js;
    script.id = "external_js";
    document.body.appendChild(script);
  }

  return (
    <>
      {settings.external_css && (
        <Helmet>
          <link rel="stylesheet" href={settings.external_css} />
        </Helmet>
      )}

      {children}
    </>
  );
};

export default ExternalScripts;
