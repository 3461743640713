import axios from "axios";

import ENV from "../../../../util/env-config";

class Service {
  static uploadSignature(requestData) {
    return axios.post(ENV.kioskBase + "/checkin/upload_signature", requestData);
  }
}

export default Service;
