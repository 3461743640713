import React from "react";
import { Button } from "semantic-ui-react";

import "./index.scss";

const LabelPicker = ({ labels, onClick, picked }) => {
  return (
    <div className="KioskLabelPicker">
      {labels.map(label => (
        <Button
          className={`KioskLabelPicker__button ${label.number === picked ? "-active" : ""}`}
          disabled={!!label.appointment_id}
          onClick={() => onClick(label.number)}
          key={label.number}
        >
          {label.number}
        </Button>
      ))}
    </div>
  );
};

export default LabelPicker;
