import React from "react";
import { withTranslation } from "react-i18next";

import MainLayout from "../../layout";
import { NavigationButton, Error } from "../../components";

const CallForHelpError = ({ onGoBack, t }) => {
  const content = <Error>{t("kl_call_for_help_error").message || "Something went wrong, please go back and try again."}</Error>;

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  return <MainLayout content={content} leftButton={leftButton} />;
};

export default withTranslation()(CallForHelpError);
