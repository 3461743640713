import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

import { WorkingItems, NotWorkedOnItems } from "./components";
import MainLayout from "../../layout";

import "./index.scss";

class OtherItemsScreen extends Component {
  render() {
    let { communication, workingItems, notWorkedOnItems, onGoToPreviousScreen, onGoToNextScreen, t } = this.props;

    const content = (
      <div className="OtherItemsScreen">
        <WorkingItems communication={communication} workingItems={workingItems} />
        <NotWorkedOnItems communication={communication} notWorkedOnItems={notWorkedOnItems} />
      </div>
    );

    const navigationButtons = (
      <>
        <Button className="DeskCheckinNavigationLeftButton" onClick={onGoToPreviousScreen}>
          <FontAwesomeIcon icon={faArrowLeft} className="navigation-icon" />
          <span className="navigation-text">{t("cc_back").message || "BACK"}</span>
        </Button>

        <Button className="DeskCheckinNavigationRightButton" onClick={onGoToNextScreen}>
          <span className="navigation-text">{t("cc_next").message || "NEXT"}</span>
          <FontAwesomeIcon icon={faArrowRight} className="navigation-icon" />
        </Button>
      </>
    );

    return (
      <MainLayout
        content={content}
        navigationButtons={navigationButtons}
        showTitle
        isCheckingOut={communication.is_checking_out}
      />
    );
  }
}

const TranslatedOtherItems = withTranslation()(OtherItemsScreen);

TranslatedOtherItems.filterWorkingsItems = WorkingItems.filterWorkingsItems;
TranslatedOtherItems.filterNotWorkedOnItems = NotWorkedOnItems.filterNotWorkedOnItems;

export default TranslatedOtherItems;
