import axios from "axios";

import ENV from "../../util/env-config";

class Service {
  static init(requestData) {
    return axios.post(ENV.kioskBase + "/kiosks/init", requestData);
  }

  static getStatus(requestData) {
    return axios.post(ENV.kioskBase + "/kiosks/status", requestData);
  }
}

export default Service;
