import React from "react";
import { withTranslation } from "react-i18next";

import { CancelButton } from "../../components";
import MainLayout from "../../layout";

import "./index.scss";

const ThankYouScreen = ({ t, kiosk, onStartOver }) => {
  setTimeout(() => {
    onStartOver();
  }, 16000);

  const content = (
    <div className="KioskThankYouScreen">
      <h2>{kiosk.confirmation_title || t("kiosk_thank_you").message || "Thank you, you are checked in."}</h2>
      <div className="ThankYouImageContainer">
        <img src={kiosk.confirmation_image_url} alt="" />
      </div>
    </div>
  );

  const headerButtons = [<CancelButton key={1} onClick={onStartOver} />];

  return (
    <MainLayout
      headerButtons={headerButtons}
      step={`${t("kiosk_step").message || "Step"} 5`}
      title={t("kiosk_drop_off_your_key").message || "Drop off your key"}
      content={content}
    />
  );
};

export default withTranslation()(ThankYouScreen);
