export const SCREENS = {
  FIRST_SCREEN: 1,
  INFO: 1,
  FIXED_ITEMS: 2,
  NOT_FIXED_ITEMS: 3,
  OTHER_ITEMS: 4,
  ORDER: 5,
  LAST_SCREEN: 5,
  SUPPORT: 6,
};
