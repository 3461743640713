import React from "react";
import moment from "moment-timezone";
import { Grid, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCalendarDay, faInfoCircle, faMapMarkedAlt, faCar, faEnvelope, faPhone } from "@fortawesome/pro-regular-svg-icons";

import { renderCarRegistration } from "../../../../util/common";

import "./index.scss";

const HeaderChecks = ({ title, communication, settings, routeKey, correctEmail, correctPhone, showFooter, showPrintDownload, showActionLegend }) => {
  const { t } = useTranslation();
  const { appointment } = communication;
  showFooter = !!showFooter;
  showPrintDownload = !!showPrintDownload;
  showActionLegend = !!showActionLegend;
  moment.tz.setDefault("Europe/Amsterdam");

  const renderAppointmentInformation = () => {
    return (
      <>
        {communication.appointment.time_car_app && (
          <div className="margin-bottom-8 flex-centered">
            <FontAwesomeIcon icon={faCalendarDay} className="header-info-icon color" />
            <span className="header-info-text">{moment(communication.appointment.time_car_app).format("DD-MM-YYYY")}</span>
          </div>
        )}
        {communication.appointment.wo_nr && (
          <div className="margin-bottom-8 flex-centered">
            <FontAwesomeIcon icon={faInfoCircle} className="header-info-icon color" />
            <span className="header-info-text">{communication.appointment.wo_nr}</span>
          </div>
        )}
        {settings.location_street && settings.location_city && (
          <div className="flex-centered">
            <FontAwesomeIcon icon={faMapMarkedAlt} className="header-info-icon color" />
            <span className="header-info-text">{`${settings.location_street}, ${settings.location_city}`}</span>
          </div>
        )}
      </>
    );
  };

  const renderCarInformation = () => {
    return (
      <>
        {renderCarRegistration(settings.location_country, communication.appointment.reg_number)}

        {communication.appointment.vin_nr && (
          <div className="margin-bottom-8 flex-centered">
            <Popup
              trigger={<FontAwesomeIcon icon={faInfoCircle} className="header-info-icon color" />}
              content={t("cc_VIN_number").message || "VIN Number"}
              position="bottom center"
              inverted
            />
            <span className="header-info-text">{communication.appointment.vin_nr}</span>
          </div>
        )}
        {(communication.appointment.car_make || communication.appointment.car_model) && (
          <div className="flex-centered">
            <FontAwesomeIcon icon={faCar} className="header-info-icon color" />
            <span className="header-info-text">
              {communication.appointment.car_make || ""} {communication.appointment.car_model || ""}
            </span>
          </div>
        )}
      </>
    );
  };

  const renderCustomerInformation = () => {
    let fullname = "";
    if (communication.customer) {
      if (communication.customer.title) fullname += communication.customer.title + " ";

      if (communication.customer.firstname) fullname += communication.customer.firstname + " ";

      if (communication.customer.surname) fullname += communication.customer.surname;
    }

    return (
      <>
        {fullname && (
          <div className="margin-bottom-8 flex-centered">
            <FontAwesomeIcon icon={faUser} className="header-info-icon color" />
            <span className="header-info-text">{fullname}</span>
          </div>
        )}
        {correctEmail && (
          <div className="margin-bottom-8 flex-centered">
            <FontAwesomeIcon icon={faEnvelope} className="header-info-icon color" />
            <span className="header-info-text">{correctEmail}</span>
          </div>
        )}
        {correctPhone && (
          <div className="flex-centered">
            <FontAwesomeIcon icon={faPhone} className="header-info-icon color" />
            <span className="header-info-text">{correctPhone}</span>
          </div>
        )}
      </>
    );
  };

  const formatDateIfValid = (dateToFormat, formatInto = "DD-MM-YYYY") => {
    let formattedDate = "";

    if (dateToFormat && (moment(dateToFormat).isValid() || !dateToFormat.startsWith("0001-01-01T00:00:00"))) {
      const md = moment(dateToFormat);
      formattedDate = md.format(formatInto);
    }
    return formattedDate;
  };

  const renderFooter = () => {
    let appNextDate = formatDateIfValid(appointment.next_date);
    let apkDate = formatDateIfValid(appointment.car_apk_date);

    return (
      <Grid.Row className="header-footer">
        {apkDate && (
          <Grid.Column className="header-footer-column">
            <span className="header-footer-label">{t("cc_apk_date").message || "APK date"}:</span> <span className="header-footer-date">{apkDate}</span>
          </Grid.Column>
        )}
        <Grid.Column className="header-footer-column">
          <span className="header-footer-label">{t("cc_current_kilometers").message || "Current km"}:</span>{" "}
          <span className="header-footer-data">{appointment.current_km} km</span>
        </Grid.Column>
        <Grid.Column className="header-footer-column">
          <span className="header-footer-label">{t("cc_next_maintenance").message || "Next maintenance"}:</span>{" "}
          <span className="header-footer-data">
            {appointment.next_km} km {appointment.next_date && (t("cc_or_before").message || "or before")}
          </span>{" "}
          <span className="header-footer-date">{appNextDate}</span>
        </Grid.Column>
      </Grid.Row>
    );
  };

  return (
    <div className="report-header-checks-container">
      {title && (
        <div className="header-title-container">
          <h1 className="header-title">{title}</h1>
        </div>
      )}

      <Grid className="header-info-container component-container borderless-container">
        <Grid.Row>
          <Grid.Column width={5}>
            <span className="header-info-heading">{t("cc_appointment_information").message || "Appointment information"}</span>
          </Grid.Column>
          <Grid.Column width={6}>
            <span className="header-info-heading">{t("cc_car_information").message || "Car information"}</span>
          </Grid.Column>
          <Grid.Column width={5}>
            <span className="header-info-heading">{t("cc_customer_information").message || "Customer information"}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="header-info-content">
          <Grid.Column width={5}>{renderAppointmentInformation()}</Grid.Column>
          <Grid.Column width={6}>{renderCarInformation()}</Grid.Column>
          <Grid.Column width={5}>{renderCustomerInformation()}</Grid.Column>
        </Grid.Row>

        {showFooter && renderFooter()}
      </Grid>
    </div>
  );
};

export default HeaderChecks;
