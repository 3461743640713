import React from "react";
import { withTranslation } from "react-i18next";

import MainLayout from "../../layout";
import { NavigationButton } from "../";

import { MODE } from "../../screens";

import "./index.scss";

const Cancel = ({ keylockerMode, onGoBack, onAbort, t }) => {
  const content = (
    <div className="CancelScreen">
      <p>
        {keylockerMode === MODE.PICKUP
          ? t("kl_you_are_about_to_cancel_the_key_pickup").message || "You are about to cancel the key pick up."
          : t("kl_you_are_about_to_cancel_the_key_drop").message || "You are about to cancel the key drop."}

        <br />
        {t("kl_are_you_sure").message || "Are you sure?"}
      </p>
    </div>
  );

  return (
    <MainLayout
      content={content}
      leftButton={<NavigationButton back onClick={onGoBack} />}
      rightButton={
        <NavigationButton onClick={() => onAbort()} icon="check">
          {t("kl_ok").message || "Ok"}
        </NavigationButton>
      }
    />
  );
};

export default withTranslation()(Cancel);
