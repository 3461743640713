import React from "react";
import { withTranslation } from "react-i18next";

import { Help, NavigationButton } from "../../../components";

const CheckInfoHelp = ({ renderLayout, onGoBack, onGoToCallForHelp, t }) => {
  const content = (
    <Help className={"CheckInfoHelp"}>
      <div>
        <div>{t("kl_check_info_help1_part1").message || "If the information displayed are not yours, please abort and"}</div>
        <div>{t("kl_check_info_help1_part2").message || "go to the Service Desk, or press Call For Help."}</div>
      </div>
      <div>
        <div>{t("kl_check_info_help2_part1").message || "In case you would like to add a different phone number, from"}</div>
        <div>{t("kl_check_info_help2_part2").message || "you or a relative you mandate, press Add Phone Number."}</div>
      </div>
    </Help>
  );

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  const rightButton = (
    <NavigationButton icon="phone" onClick={onGoToCallForHelp}>
      {t("kl_call_for_help").message || "Call For Help"}
    </NavigationButton>
  );

  return renderLayout({ leftButton, rightButton, content });
};

export default withTranslation()(CheckInfoHelp);
