import React, { Component } from "react";

import { getPhoneNumber, getEmail } from "../../../../util/common";

import {
  DealerInfoHeader,
  TotalAmount,
  ImageGallery,
  HeaderChecks,
  FixedCheckItems,
  NotFixedCheckItems,
  WorkingCheckItems,
  TiresOverview,
  Signatures,
  VatIndicator,
} from "../../components";

class MerdecesCheckTemplate extends Component {
  renderMainContent = () => {
    const { settings, communication } = this.props;

    const fixedItems = FixedCheckItems.filterFixedItems(communication.results);
    const notFixedItems = NotFixedCheckItems.filterNotFixedItems(communication.results);
    const workingItems = WorkingCheckItems.filterWorkingsItems(communication.results);
    const lettersMap = ImageGallery.getLettersMap(communication.appointment, fixedItems, notFixedItems, WorkingCheckItems.filterWorkingsItemsWithRemarks(workingItems));
    const signatureItems = Signatures.filterSignatureItems(communication.results);
    const correct_phone = communication.correct_phone || getPhoneNumber(communication);
    const correct_email = communication.correct_email || getEmail(communication);

    return (
      <div className="mainContent">
        <HeaderChecks communication={communication} settings={settings} correctEmail={correct_email} correctPhone={correct_phone} showFooter />
        <FixedCheckItems lettersMap={lettersMap} fixedItems={fixedItems} settings={settings} communication={communication} />
        {fixedItems.length > 0 && <TotalAmount communication={communication} settings={settings} />}
        <ImageGallery lettersMap={lettersMap} />
        <NotFixedCheckItems lettersMap={lettersMap} notFixedItems={notFixedItems} settings={settings} communication={communication} />
        <TiresOverview communication={communication} settings={settings} />
        <WorkingCheckItems lettersMap={lettersMap} workingItems={workingItems} communication={communication} />
        <DealerInfoHeader settings={settings} />
        <Signatures signatureItems={signatureItems} settings={settings} communication={communication} />
        <VatIndicator include_vat={settings.include_vat} />
      </div>
    );
  };

  render() {
    return <div className="report-template">{this.renderMainContent()}</div>;
  }
}

export default MerdecesCheckTemplate;
