import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { round2 } from "../../../../../../util/common";

const getTotalPrice = (interventions, optional_services, include_vat, vat_percentage) => {
  let totalPrice = 0;
  const vat = include_vat ? 1 + vat_percentage / 100 : 1;

  interventions.forEach(i => {
    totalPrice += round2(i.price * vat);
  });

  if (Array.isArray(optional_services)) {
    totalPrice += optional_services.reduce((prev, cur) => {
      if (cur.accepted && cur.price > 0.01) prev += round2(cur.price * vat);

      return prev;
    }, 0);
  }

  return totalPrice;
};

const TotalAmount = ({ communication, kiosk, t }) => {
  const { include_vat, vat } = kiosk;
  const { interventions } = communication.appointment;
  const { check_in_results: optional_services } = communication;

  const totalAmount = getTotalPrice(interventions, optional_services, include_vat, vat);
  if (!totalAmount) return null;

  return (
    <Grid className="OrderContainer TotalAmount">
      <Grid.Row>
        <Grid.Column width={14}>
          <span className="name">{t("cc_TOTAL_AMOUNT").message || "TOTAL AMOUNT"}:</span>
        </Grid.Column>

        <Grid.Column width={2}>
          <span className="price">€ {totalAmount.toFixed(2)}</span>
        </Grid.Column>
      </Grid.Row>
      {include_vat && (
        <Grid.Row>
          <Grid.Column width={14}>
            <span className="vat-name">
              *{t("cc_including").message || "Including"} {vat}% {t("cc_vat").message || "VAT"}
            </span>
          </Grid.Column>

          <Grid.Column width={2}>
            <span className="price">€ {((totalAmount / (1 + vat / 100)) * (vat / 100)).toFixed(2)}</span>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default withTranslation()(TotalAmount);
