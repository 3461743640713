import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { getTyreItemName, getTyreSeasonIcon } from "../../../../../../util/tyre";

import "./index.scss";
import { ConditionIcon } from "../../../../../../components";
import { DesktopOnly, MobileOnly } from "../../../../../../layout";

class ExpandableTire extends Component {
  state = {
    isOpen: false,
  };

  handleToggleTire = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleIsOpen = result => {
    if (!this.state.isOpen) return;

    return (
      <div className="expandable-tire">
        <div className="expandable-tire-details">
          {getTyreSeasonIcon(result.tyre.season)}
          <span>{result.tyre.manufacturer}</span>
        </div>
        <span>{result.tyre.description} </span>
      </div>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { result, t } = this.props;

    return (
      <>
        <MobileOnly>
          <div className="expandable-tire-container">
            <div className="expandable-tire-mobile-container">
              <ConditionIcon className="optional-items-condition-icon" status={result.question_status} />
              <div className="expandable-tire-header expandable-tire-mobile-header" onClick={this.handleToggleTire}>
                <div>{getTyreItemName(t, result)}</div>
                <div className="expandable-tire-icon">{<FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />}</div>
              </div>
            </div>
            {this.handleIsOpen(result)}
          </div>
        </MobileOnly>

        <DesktopOnly>
          <ConditionIcon className="optional-items-condition-icon" status={result.question_status} />
          <div className="expandable-tire-container">
            <div className="expandable-tire-header" onClick={this.handleToggleTire}>
              <div>
                <span>{getTyreItemName(t, result)}</span>
              </div>
              <div className="expandable-tire-icon">{<FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />}</div>
            </div>
            {this.handleIsOpen(result)}
          </div>
        </DesktopOnly>
      </>
    );
  }
}

export default withTranslation()(ExpandableTire);
