import React from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";

const Support = ({ settings }) => {
  const { t } = useTranslation();

  return (
    <div className="support-container">
      <h1 className="support-title">{t("cc_support").message || "Support"}</h1>
      <div className="support-text" dangerouslySetInnerHTML={{ __html: settings.support_text }} />
    </div>
  );
};

export default Support;
