import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import MainLayout from "../../../layout";

import PickUpKey from "./PickUpKey";
import PickUpKeyHelp from "./PickUpKeyHelp";
import PickUpKeyError from "./PickUpKeyError";
import { CallForHelp, Cancel } from "../../../components";

import { MODE } from "../..";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  ERROR: 3,
  CALL_FOR_HELP: 4,
  CANCEL: 5,
};

const STEP = 2;
class PickUpKeyScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    errorMessage: "",
  };

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kl_step").message || "Step") + " " + STEP;
    const title = t("kl_pickup_your_keys").message || "Pick up your keys inside the box";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToMain = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goToErrorScreen = errorMessage => this.setState({ screen: SUBSCREENS.ERROR, errorMessage });

  render() {
    const { pin, keyLockerNumber, onGoToNextScreen, onAbort, softmode, adminOpenDoor } = this.props;

    switch (this.state.screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <PickUpKey
            renderLayout={this.renderLayout}
            onGoToNextScreen={onGoToNextScreen}
            onGoToHelpScreen={this.goToHelp}
            onGoToErrorScreen={this.goToErrorScreen}
            keyLockerNumber={keyLockerNumber}
            pin={pin}
            adminOpenDoor={adminOpenDoor}
            softmode={softmode}
          />
        );

      case SUBSCREENS.HELP:
        return <PickUpKeyHelp renderLayout={this.renderLayout} onGoBack={this.goToMain} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel keylockerMode={MODE.PICKUP} onGoBack={this.goToMain} onAbort={onAbort} />;

      case SUBSCREENS.ERROR:
        return (
          <PickUpKeyError
            renderLayout={this.renderLayout}
            onGoBack={this.goToMain}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            onAbort={this.goToCancel}
          />
        );

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;
    }
  }
}

export default withRouter(withTranslation()(PickUpKeyScreen));
