import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";

import { NavigationButton } from "../../../components";
import { VirtualKeyboard, LAYOUTS } from "../../../../../components";

import "./OrderEditCustomerName.scss";

class OrderEditCustomerName extends Component {
  state = { customerName: this.props.customerName || "" };

  onChange = customerName => {
    if (customerName.length >= 255) return;

    this.setState({ customerName });
  };

  render() {
    const { customerName } = this.state;
    const { renderLayout, onSave, onGoBack, t } = this.props;

    const content = (
      <div className="OrderEditCustomerNameScreen">
        <div className="OrderEditCustomerNameTitle KioskTitle">
          <FontAwesomeIcon icon={faPen} />
          {t("keylocker_customer_name").message || "Enter Your Name"}
        </div>
        <textarea
          id="OrderEditCustomerName"
          className="OrderEditCustomerName"
          value={customerName}
          placeholder={t("keylocker_type_something").message || "Type something"}
          disabled={true}
        />
        <VirtualKeyboard onChange={this.onChange} text={customerName} layout={LAYOUTS.FULL} />
      </div>
    );

    const leftButton = <NavigationButton back onClick={onGoBack} />;

    const rightButton = (
      <NavigationButton icon="check" onClick={() => onSave(customerName)}>
        {t("keylocker_save").message || "Save"}
      </NavigationButton>
    );

    return renderLayout({ rightButton, leftButton, content });
  }
}

export default withTranslation()(OrderEditCustomerName);
