import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer, faCar, faPhone, faGripHorizontal, faQrcode } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { NavigationButton, HelpButton, CancelButton } from "../../../../../components";

import "./index.scss";

const AuthenticationOptions = ({
  renderLayout,
  onGoToEnterPinScreen,
  onGoToEnterRegNrScreen,
  onGoToEnterPhoneNrScreen,
  onGoToHelpScreen,
  onGoToCancelScreen,
  onGoToScanQRCode,
  onGoBack,
  t,
}) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} />, <CancelButton key={2} onClick={onGoToCancelScreen} />];
  const content = (
    <div className="AuthenticationOptionsScreen">
      <div className="KeyLockerTitle">{t("kl_find_your_appointment").message || "Let’s find your appointment"}</div>
      <div className="AuthenticationOptionsScreenSubtitle">{t("kl_select_an_option").message || "Please select one of the following options"}</div>

      <div className="AuthenticationOptionsScreenOptions">
        <div className="AuthenticationOptionsScreenOption" onClick={onGoToEnterPinScreen}>
          <div className="AuthenticationOptionsScreenOptionContent">
            <div className="AuthenticationOptionsScreenIcon">
              <div className="AuthenticationOptionsScreenCompositeIcon">
                <FontAwesomeIcon icon={faGripHorizontal} />
                <br />
                <FontAwesomeIcon icon={faHandPointer} />
              </div>
            </div>
            <div className="AuthenticationOptionsScreenOptionTitle">{t("kl_pin_code").message || "PIN Code"}</div>
          </div>
        </div>

        <div className="AuthenticationOptionsScreenOption" onClick={onGoToEnterRegNrScreen}>
          <div className="AuthenticationOptionsScreenOptionContent">
            <div className="AuthenticationOptionsScreenIcon">
              <FontAwesomeIcon icon={faCar} className="AuthenticationOptionsScreenSingleIcon" />
            </div>
            <div className="AuthenticationOptionsScreenOptionTitle">
              <div>{t("kl_registration_number").message || "Registration Number"}</div>
            </div>
          </div>
        </div>

        <div className="AuthenticationOptionsScreenOption" onClick={onGoToEnterPhoneNrScreen}>
          <div className="AuthenticationOptionsScreenOptionContent">
            <div className="AuthenticationOptionsScreenIcon">
              <FontAwesomeIcon icon={faPhone} className="AuthenticationOptionsScreenSingleIcon" />
            </div>
            <div className="AuthenticationOptionsScreenOptionTitle">{t("kl_phone_number").message || "Phone Number"}</div>
          </div>
        </div>

        <div className="AuthenticationOptionsScreenOption" onClick={onGoToScanQRCode}>
          <div className="AuthenticationOptionsScreenOptionContent">
            <div className="AuthenticationOptionsScreenIcon">
              <FontAwesomeIcon icon={faQrcode} className="AuthenticationOptionsScreenSingleIcon" />
            </div>
            <div className="AuthenticationOptionsScreenOptionTitle">{t("qr_code").message || "QR Code"}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  return renderLayout({ headerButtons, leftButton, content });
};

export default withTranslation()(AuthenticationOptions);
