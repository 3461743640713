export const WS_MSG_TYPE = {
  SUBSCRIBE: 1,
  UNSUBSCRIBE: 2,
  PRIVATE: 3,
};

export const WS_STATUS = {
  OPEN_HOME_URL: 1,
  OPEN_DESK_URL: 2,
  PING: 3,
};

export const URL_STATUS = {
  DESK_URL_RECEIVED: 1,
  DESK_URL_FAILED: 2,
};
