import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { NavigationButton, CancelButton } from "../../components";

import { ScheduledServices, OptionalServices, TotalAmount, Agreements, Remarks, CustomerSignature } from "./components";

import "./Order.scss";

class Order extends Component {
  render() {
    const {
      renderLayout,
      agreements,
      communication,
      kiosk,
      onGoToCancelScreen,
      onGoToEditRemarks,
      additionalRemark,
      customerName,
      showAgreementError,
      showCustomerSignatureError,
      showCustomerNameError,
      handleAgreementChecked,
      handleCanvasRef,
      handleUpdateCanvas,
      onChangeCustomerName,
      onGoToNextScreen,
      isLoading,
      t,
    } = this.props;

    const headerButtons = [<CancelButton key={1} onClick={onGoToCancelScreen} />];

    const content = (
      <div className="KioskOrderScreen">
        <div className="OrderHeader">
          <div className="reg-num-box">
            <div className="reg-country">
              <div className="reg-country-text">{"NL"}</div>
            </div>
            <div className="reg-num">{communication.appointment.reg_number.split("-").join(" - ")}</div>
          </div>
          <div className="OrderCarMake">
            <FontAwesomeIcon icon={faCar} className="car-icon" />
            {`${communication.appointment.car_make} ${communication.appointment.car_model}`}
          </div>
        </div>
        <div className="OrderServices" id="OrderServices">
          <ScheduledServices communication={communication} kiosk={kiosk} />
          <OptionalServices communication={communication} kiosk={kiosk} />
          <TotalAmount communication={communication} kiosk={kiosk} />
          <Agreements agreements={agreements} showAgreementError={showAgreementError} handleAgreementChecked={handleAgreementChecked} />
          <Remarks additionalRemark={additionalRemark} />
          <CustomerSignature
            kiosk={kiosk}
            onChangeCustomerName={onChangeCustomerName}
            customerName={customerName}
            showCustomerSignatureError={showCustomerSignatureError}
            showCustomerNameError={showCustomerNameError}
            onCanvasRef={handleCanvasRef}
            onUpdateCanvas={handleUpdateCanvas}
          />
        </div>
      </div>
    );

    const rightButton = <NavigationButton loading={isLoading} onClick={onGoToNextScreen} />;

    const leftButton = (
      <NavigationButton secondary onClick={onGoToEditRemarks}>
        {!additionalRemark && (t("kiosk_add_remarks").message || "Add Remarks")}
        {additionalRemark && (t("kiosk_edit_remarks").message || "Edit Remarks")}
      </NavigationButton>
    );

    return renderLayout({ headerButtons, rightButton, leftButton, content });
  }
}

export default withTranslation()(Order);
