import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { Button, Input } from "semantic-ui-react";
import SignatureCanvas from "react-signature-canvas";

import { DESK_COMMUNICATION_STATUSES, DESK_COMMUNICATION_EVENTS } from "../../../../util/communication_statuses";
import { VirtualKeyboard, LAYOUTS } from "../../../../components";
import MainLayout from "../../layout";
import { TotalAmount, Agreements } from "./components";

import Service from "./service";

import "./index.scss";

class SignatureScreen extends Component {
  state = {
    error: false,
    isLoading: false,
    isKeyboardOpen: false,
    signatureImage: "",
    signatureName: "",
    signatureError: false,
    canvasWidth: 0,
    canvasHeight: 0,
    scrolledToBottom: false,
  };

  canvasRef = React.createRef();
  DeskCheckinContent = null;
  DeskCheckinSignature = null;
  TotalAmountAgreementsContainer = null;

  getIsScrolledToTheBottom = () => {
    const { clientHeight, scrollTop, scrollHeight } = this.DeskCheckinContent;
    this.setState({ scrolledToBottom: Math.abs(scrollHeight - scrollTop - clientHeight) < 1 });
  };

  componentDidMount() {
    const { readOnly, communication } = this.props;
    this.DeskCheckinContent = document.querySelector(".DeskCheckinContent");
    this.DeskCheckinSignature = document.querySelector(".DeskCheckinSignature");
    this.TotalAmountAgreementsContainer = document.querySelector(".total-amount-agreements-container");

    if (readOnly && communication.status >= DESK_COMMUNICATION_STATUSES.CHECKIN_ANSWERED) {
      const event = communication.events.find(e => e.type === DESK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED);

      if (event) this.setState({ signatureName: event.customer_name, signatureImage: event.customer_signature });
    }

    const d = document.querySelector(".signature-canvas");
    this.setState({
      canvasWidth: d?.offsetWidth || 740,
      canvasHeight: d?.offsetHeight || 400,
      scrolledToBottom: this.getIsScrolledToTheBottom(),
    });

    this.DeskCheckinContent.addEventListener("scroll", this.getIsScrolledToTheBottom);
  }

  componentWillUnmount() {
    this.DeskCheckinContent.removeEventListener("scroll", this.getIsScrolledToTheBottom);
  }

  isAgreementsBoxFullyVisible = () =>
    Math.round(this.DeskCheckinSignature.scrollHeight - this.DeskCheckinContent.scrollTop) <= Math.round(this.TotalAmountAgreementsContainer.clientHeight);

  handleValidate = () => {
    const { onShowAgreementError, approved } = this.props;
    let isError = false;

    if (this.canvasRef.isEmpty()) {
      this.setState({ signatureError: true });

      isError = true;
    }

    if (!this.state.signatureName && !this.getDriverName()) {
      this.setState({ signatureNameError: true });

      isError = true;
    }

    if (!approved) {
      if (!this.state.scrolledToBottom && !this.isAgreementsBoxFullyVisible()) this.TotalAmountAgreementsContainer.scrollIntoView({ behavior: "smooth", block: "end" });
      onShowAgreementError && onShowAgreementError(!approved);

      isError = true;
    }

    return isError;
  };

  handleSendAnswer = () => {
    const { onSendAnswer, onGoToNextScreen, routeKey } = this.props;

    if (this.handleValidate()) return;

    const signature = this.canvasRef.getTrimmedCanvas().toDataURL("image/png");

    this.setState({ isLoading: true, signatureError: false, error: false }, async () => {
      try {
        const signatureResponse = await fetch(signature);
        const file = await signatureResponse.blob();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", routeKey);

        const response = await Service.uploadSignature(formData);
        if (!response?.data?.data) throw Error();

        await onSendAnswer(response.data.data.url, this.state.signatureName || this.getDriverName());
        this.setState({ isLoading: false }, () => onGoToNextScreen());
      } catch (error) {
        console.log("Error sending answer", error);
        this.setState({ isLoading: false, error: true });
      }
    });
  };

  focusIn = () => this.setState({ isKeyboardOpen: true });

  handleChange = signatureName => this.setState({ signatureName, signatureNameError: false });

  handleKeyPress = button => {
    if (button === "{enter}") this.setState({ isKeyboardOpen: false });
  };

  handleClearSignature = () => this.canvasRef.clear();

  handleUpdateCanvas = () => this.setState({ signatureError: false });

  getDriverName = () => {
    const { driver_firstname, driver_surname, driver_title } = this.props.communication.appointment;

    return `${driver_title} ${driver_firstname} ${driver_surname}`.trim();
  };

  getSignatureNameText = () => {
    const { t } = this.props;

    if (this.getDriverName())
      return `${t("cc_signature_text_part1").message || "If you are not"} ${this.getDriverName()}, ${t("cc_signature_text_part2").message ||
        "please enter your name and surname."}`;

    return t("cc_signature_enter_name").message || "Please enter your name and surname.";
  };

  render() {
    const { t, onGoToPreviousScreen, readOnly, agreements, showAgreementError, onAgreed, communication, declinedInterventionIds, settings, optionalItems } = this.props;
    const { signatureName, signatureImage, isLoading, signatureError, signatureNameError, canvasWidth, canvasHeight, error } = this.state;

    const { interventions } = communication.appointment;

    const { include_vat, vat } = settings;

    const content = (
      <div className="DeskCheckinSignature">
        <div className="signature-container">
          <div className="signature-header">{t("signature").message || "Signature"}</div>
          <div className="signature-content">
            <h2>{t("cc_confirm_your_name_and_sign").message || "Please confirm your name and sign"}</h2>

            <div className="signature-name">
              <label htmlFor="signature_name">{this.getSignatureNameText()}</label>
              <Input
                readOnly
                type="text"
                error={signatureNameError}
                disabled={readOnly}
                placeholder={this.getDriverName()}
                name="signature_name"
                autoComplete="none"
                onFocus={this.focusIn}
                value={signatureName}
              />
            </div>

            <div className={`signature-canvas ${signatureError ? "error" : ""}`}>
              <span>{t("cc_signature").message || "Signature"}</span>

              {!readOnly && (
                <SignatureCanvas ref={ref => (this.canvasRef = ref)} canvasProps={{ width: canvasWidth, height: canvasHeight }} onBegin={this.handleUpdateCanvas} />
              )}

              {readOnly && <div className="signature-img-wrapper">{signatureImage && <img src={signatureImage} alt="signature" />}</div>}
            </div>

            <Button type="input" className="clear-signature-btn" onClick={this.handleClearSignature} disabled={readOnly}>
              {t("cc_clear_signature").message || "Clear signature"}
            </Button>

            <span className="error-msg">{error && (t("cc_something_went_wrong").message || "Something went wrong, please try again.")}</span>
          </div>
        </div>

        <div className="total-amount-agreements-container">
          <TotalAmount
            interventions={interventions}
            declinedInterventionIds={declinedInterventionIds}
            optionalItems={optionalItems}
            include_vat={include_vat}
            vat={vat}
          />
          <Agreements
            agreements={agreements}
            onAgreed={onAgreed}
            readOnly={readOnly}
            showAgreementError={showAgreementError}
            isCheckingOut={communication.is_checking_out}
          />
        </div>
      </div>
    );

    const navigationButtons = (
      <>
        <Button className="DeskCheckinNavigationLeftButton" onClick={onGoToPreviousScreen} loading={isLoading} disabled={isLoading}>
          <FontAwesomeIcon icon={faArrowLeft} className="navigation-icon" />
          <span className="navigation-text">{t("cc_back").message || "BACK"}</span>
        </Button>

        <Button className="DeskCheckinNavigationRightButton" onClick={this.handleSendAnswer} loading={isLoading} disabled={readOnly || isLoading}>
          <span className="navigation-text">{t("cc_CONFIRM").message || "CONFIRM"}</span>
          <FontAwesomeIcon icon={faArrowRight} className="navigation-icon" />
        </Button>
      </>
    );

    return (
      <>
        <MainLayout
          content={content}
          navigationButtons={navigationButtons}
          title={t("cc_desk_checkin").message || "Desk check in"}
          showTitle
          isCheckingOut={communication.is_checking_out}
        />
        {this.state.isKeyboardOpen && (
          <div className="DeskCheckinSignatureKeyboard">
            <VirtualKeyboard
              layout={LAYOUTS.SIGNATURE}
              keyboardOpen={this.state.isKeyboardOpen}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              text={signatureName}
            />
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(SignatureScreen);
