import React from "react";
import { withTranslation } from "react-i18next";

import { HelpButton, NavigationButton, Error } from "../../../../../components";

const AuthenticationError = ({ renderLayout, onGoBack, onGoToHelpScreen, onGoToCallForHelp, message, t }) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} />];

  const content = <Error>{message}</Error>;

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  const rightButton = (
    <NavigationButton icon="phone" onClick={onGoToCallForHelp}>
      {t("kl_call_for_help").message || "Call For Help"}
    </NavigationButton>
  );

  return renderLayout({ headerButtons, leftButton, rightButton, content });
};

export default withTranslation()(AuthenticationError);
