import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { Cancel } from "../../components";

import MainLayout from "../../layout";

import Order from "./Order";
import OrderEditRemarks from "./OrderEditRemarks";
import OrderError from "./OrderError";

import Service from "./service";

const SUBSCREENS = {
  MAIN: 1,
  CANCEL: 2,
  EDIT_REMARKS: 3,
  EDIT_SIGNATURE: 4,
  ERROR: 5,
};

const STEP = 3;
class OrderScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    additionalRemark: "",
    agreements: this.props.communication.agreements,
    customerName: "",
    customerSignature: "",
    showAgreementError: false,
    showCustomerSignatureError: false,
    showCustomerNameError: false,
    isLoading: false,
  };

  canvasRef = React.createRef();

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kiosk_step").message || "Step") + " " + STEP;
    const title = t("kiosk_validate_appointment_list").message || "Validate Appointment List";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToEditRemarks = () => this.setState({ screen: SUBSCREENS.EDIT_REMARKS });
  goToNextScreen = () => {
    const hasMissingMandatoryAgreements = this.state.agreements.some(a => !a.optional_kiosk_check_in && !a.accepted);

    if (hasMissingMandatoryAgreements) {
      this.setState({ showAgreementError: true }, () => {
        document.querySelector("#OrderServices").scrollTop = document.querySelector(".OrderAgreements").offsetTop;
      });
    } else if (this.props.kiosk.is_customer_name_in_kiosk_checkin_enabled && !this.state.customerName) {
      this.setState({ showCustomerNameError: true }, () => {
        document.querySelector("#OrderServices").scrollTop = document.querySelector(".OrderEditCustomerName").offsetTop;
      });
    } else if (this.props.kiosk.is_customer_signature_in_kiosk_checkin_enabled && !this.state.customerSignature) {
      this.handleSaveCustomerSignature();
    } else {
      this.props.onGoToNextScreenWithState({
        additionalRemark: this.state.additionalRemark,
        customerName: this.state.customerName,
        customerSignature: this.state.customerSignature,
        customerAcceptedAgreementsIDs: this.state.agreements.filter(a => a.accepted).map(a => a.id),
      });
    }
  };

  saveRemarks = additionalRemark => {
    this.setState({ screen: SUBSCREENS.MAIN, additionalRemark }, () => {
      document.querySelector("#OrderServices").scrollTop = document.querySelector("#OrderServices").scrollHeight;
    });
  };

  handleAgreementChecked = id => {
    const agreements = this.state.agreements.map(a => (a.id === id ? { ...a, accepted: !a.accepted } : a));

    this.setState({ agreements });
  };

  handleValidateCustomerSignature = () => {
    let isError = false;

    if (this.canvasRef.isEmpty()) {
      this.setState({ showCustomerSignatureError: true });
      document.querySelector("#OrderServices").scrollTop = document.querySelector(".OrderEditCustomerSignature").offsetTop;
      isError = true;
    }

    return isError;
  };

  handleSaveCustomerSignature = () => {
    const { communication } = this.props;

    if (this.handleValidateCustomerSignature()) return;

    const signature = this.canvasRef.getTrimmedCanvas().toDataURL("image/png");

    this.setState({ isLoading: true, showCustomerSignatureError: false }, async () => {
      try {
        const signatureResponse = await fetch(signature);
        const file = await signatureResponse.blob();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", communication.key);

        const response = await Service.uploadSignature(formData);
        if (!response?.data?.data) throw Error();

        this.setState({ isLoading: false, customerSignature: response.data.data.url }, () => {
          document.querySelector("#OrderServices").scrollTop = document.querySelector("#OrderServices").scrollHeight;
          this.goToNextScreen();
        });
      } catch (error) {
        console.log("Error saving signature", error);
        this.setState({ isLoading: false });
      }
    });
  };

  handleCanvasRef = ref => {
    this.canvasRef = ref;
  };

  handleUpdateCanvas = () => {
    this.setState({ showCustomerSignatureError: false });
  };

  handleChangeCustomerName = customerName => {
    if (customerName.length >= 255) return;

    this.setState({ customerName, showCustomerNameError: false });
  };

  render() {
    const {
      screen,
      agreements,
      additionalRemark,
      customerName,
      customerSignature,
      showAgreementError,
      showCustomerNameError,
      showCustomerSignatureError,
      isLoading,
    } = this.state;
    const { communication, kiosk, onAbort } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <Order
            renderLayout={this.renderLayout}
            communication={communication}
            kiosk={kiosk}
            onGoToCancelScreen={this.goToCancel}
            agreements={agreements}
            customerName={customerName}
            customerSignature={customerSignature}
            showAgreementError={showAgreementError}
            showCustomerSignatureError={showCustomerSignatureError}
            showCustomerNameError={showCustomerNameError}
            handleAgreementChecked={this.handleAgreementChecked}
            onChangeCustomerName={this.handleChangeCustomerName}
            onGoToEditRemarks={this.goToEditRemarks}
            onGoToNextScreen={this.goToNextScreen}
            handleCanvasRef={this.handleCanvasRef}
            handleUpdateCanvas={this.handleUpdateCanvas}
            additionalRemark={additionalRemark}
            isLoading={isLoading}
          />
        );

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.EDIT_REMARKS:
        return <OrderEditRemarks renderLayout={this.renderLayout} additionalRemark={additionalRemark} onGoBack={this.goBack} onSave={this.saveRemarks} />;

      case SUBSCREENS.ERROR:
        return <OrderError renderLayout={this.renderLayout} onGoBack={this.goBack} onAbort={onAbort} />;
    }
  }
}

export default withRouter(withTranslation()(OrderScreen));
