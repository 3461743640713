import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faTag } from "@fortawesome/pro-solid-svg-icons";

import { CancelButton, NavigationButton, Step } from "../../components";
import { LabelPicker } from "./components";
import SelectLabelErrorModal from "./SelectLabelErrorModal";

import Service from "./service";

import "./SelectLabel.scss";

class SelectLabel extends Component {
  state = {
    showError: false,
    isLoading: false,
  };

  handleConfirm = () => {
    const { pickedNumber, extraPhone, additionalRemark, agreements, communication, customerName, onGoToNextScreen, customerSignature, goToError } = this.props;

    if (pickedNumber <= 0) {
      this.setState({ showError: true });
      return;
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await Service.answerCheckin({
          private_key: this.props.match.params.kioskKey,
          additional_phone: extraPhone,
          additional_remark: additionalRemark || null,
          kiosk_label_number: pickedNumber,
          customer_name: customerName,
          customer_signature: customerSignature,
          agreed: agreements,
          communication_key: communication.key,
        });

        this.setState({ isLoading: false }, () => {
          onGoToNextScreen();
        });
      } catch (err) {
        this.setState({ isLoading: false }, () => {
          goToError();
        });
      }
    });
  };

  onCloseError = () => this.setState({ showError: false });

  render() {
    const { isLoading, showError } = this.state;
    const { t, communication, onPickedNumber, pickedNumber, onGoToCancelScreen, renderLayout } = this.props;

    const headerButtons = [<CancelButton key={1} onClick={onGoToCancelScreen} />];

    const content = (
      <div className="KioskSelectLabel__content">
        <Step
          number={1}
          icon={
            <div className="KioskSelectLabel-step-one">
              <FontAwesomeIcon icon={faTag} color="green" />
              <span className="plus">+</span>
              <FontAwesomeIcon className="fa-duotone" icon={faKey} />
            </div>
          }
          title={t("kiosk_pick_a_label_attach_it_to_key").message || "Pick a label and attach it to your key."}
          size="large"
        />

        <Step
          number={2}
          size="large"
          title={t("kiosk_select_your_keylabel_below").message || "Select your keylabel below."}
          child={<LabelPicker labels={communication.labels} picked={pickedNumber} onClick={onPickedNumber} size="large" />}
        />

        <SelectLabelErrorModal open={showError} onClose={this.onCloseError} />
      </div>
    );

    const mainButton = (
      <NavigationButton onClick={() => this.handleConfirm()} loading={isLoading} icon="check">
        {t("kiosk_confirm").message || "Confirm"}
      </NavigationButton>
    );

    return renderLayout({ headerButtons, mainButton, content });
  }
}

export default withRouter(withTranslation()(SelectLabel));
