import React from "react";
import { withTranslation } from "react-i18next";

import { NavigationButton } from "../";
import MainLayout from "../../layout";

import "./index.scss";

const Cancel = ({ onGoBack, onAbort, t }) => {
  const content = (
    <div className="CancelScreen">
      <p>
        {t("kiosk_you_are_about_to_cancel").message || "You are about to cancel checking in"}

        <br />
        {t("kiosk_are_you_sure").message || "Are you sure?"}
      </p>
    </div>
  );

  return (
    <MainLayout
      content={content}
      leftButton={<NavigationButton back onClick={onGoBack} />}
      rightButton={
        <NavigationButton onClick={() => onAbort()} icon="check">
          {t("kiosk_ok").message || "Ok"}
        </NavigationButton>
      }
    />
  );
};

export default withTranslation()(Cancel);
