import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import MainLayout from "../../layout";
import KeylockerDisabledScreen from "./subscreens/KeylockerDisabled";
import APP_CONFIG from "../../../../util/app-config";

import Service from "./service";

import "./index.scss";

class StartScreen extends Component {
  statusUpdateInterval = null;
  versionCheckInterval = null;

  componentDidMount() {
    this.statusUpdateInterval = setInterval(this.props.onStatusUpdate, 60000);
    this.versionCheckInterval = setInterval(this.checkLatestVersion, 360000);
  }

  componentWillUnmount() {
    clearInterval(this.statusUpdateInterval);
    clearInterval(this.versionCheckInterval);
  }

  checkLatestVersion = async () => {
    const {
      data: { latest_version },
    } = await Service.loadLatestVersion();

    if (latest_version > APP_CONFIG.FRONTEND_VERSION) document.location.reload();
  };

  renderContent = () => {
    const { onGoToDropScreens, onGoToPickUpScreens, t } = this.props;

    return (
      <div className="StartScreen">
        <div className="KeyLockerTitle">{t("kl_welcome").message || "Welcome"}</div>
        <div className="StartScreenSubtitle">{t("kl_select_an_option").message || "Please select one of the following options"}</div>

        <div className="StartScreenOptions">
          <div className="StartScreenOption" onClick={onGoToDropScreens}>
            <div className="StartScreenOptionContent">
              <div className="StartScreenIcon">
                <FontAwesomeIcon icon={faArrowDown} className="StartScreenSingleIcon" />
              </div>
              <div className="StartScreenOptionTitle">
                <div>{t("kl_drop_key").message || "Drop key"}</div>
              </div>
            </div>
          </div>

          <div className="StartScreenOption" onClick={onGoToPickUpScreens}>
            <div className="StartScreenOptionContent">
              <div className="StartScreenIcon">
                <FontAwesomeIcon icon={faArrowUp} className="StartScreenSingleIcon" />
              </div>
              <div className="StartScreenOptionTitle">{t("kl_pick_up_key").message || "Pick up key"}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { active } = this.props.keylocker;

    if (!active) return <KeylockerDisabledScreen />;

    return <MainLayout content={this.renderContent()} />;
  }
}

export default withRouter(withTranslation()(StartScreen));
