import React from "react";

import "./index.scss";

const MainLayout = ({ step, title, headerButtons, content, leftButton, rightButton, mainButton }) => {
  return (
    <div className="Kiosk">
      <div className="KioskHeader">
        {step && <div className="KioskHeaderStep">{step}</div>}
        <div className="KioskHeaderTitle">{title}</div>
        <div className="KioskHeaderActions">{headerButtons}</div>
      </div>
      <div className="KioskContent">{content}</div>
      <div className="KioskNavigation">
        <div className="KioskNavigationLeftButton">{leftButton}</div>
        <div className="KioskNavigationMainButton">{mainButton}</div>
        <div className="KioskNavigationRightButton">{rightButton}</div>
      </div>
    </div>
  );
};

export default MainLayout;
