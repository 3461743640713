import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import "./index.scss";

const TurboHoetHeader = ({ settings, communication, t }) => {
  const { logo } = settings;
  const { owner_firstname, owner_surname, wo_nr, car_apk_date, reg_number, car_model, car_make } = communication.appointment;

  return (
    <div className="report-simple-header-info-container">
      <h4>{t("cc_customer_product_info").message || "Customer & Product info"}</h4>
      <div className="simple-header-info-inner-container">
        <div className="simple-header-info-text">
          <div className="simple-header-info-column">
            {car_apk_date && (
              <div className="simple-header-info-text-content-wrapper">
                <span className="info-text-lable">{`${t("cc_date").message || "Date"}: `}</span>
                <span className="info-text">{moment(car_apk_date).format("DD-MM-YYYY")}</span>
              </div>
            )}

            {(owner_firstname || owner_surname) && (
              <div className="simple-header-info-text-content-wrapper">
                <span className="info-text-lable">{`${t("cc_customer_name").message || "Customer name"}: `}</span>
                <span className="info-text">{`${owner_firstname} ${owner_surname}`}</span>
              </div>
            )}

            {car_make && (
              <div className="simple-header-info-text-content-wrapper">
                <span className="info-text-lable">{`${t("cc_item_number").message || "Item number"}: `}</span>
                <span className="info-text">{car_make}</span>
              </div>
            )}
          </div>

          <div className="simple-header-info-column">
            {car_model && (
              <div className="simple-header-info-text-content-wrapper">
                <span className="info-text-lable">{`${t("cc_your_reference").message || "Your reference"}: `}</span>
                <span className="info-text">{car_model}</span>
              </div>
            )}

            {wo_nr && (
              <div className="simple-header-info-text-content-wrapper">
                <span className="info-text-lable">{`${t("cc_wo_number").message || "WO Number"}: `}</span>
                <span className="info-text">{wo_nr}</span>
              </div>
            )}

            {reg_number && (
              <div className="simple-header-info-text-content-wrapper">
                <span className="info-text-lable">{`${t("cc_so_number").message || "SO Number"}: `}</span>
                <span className="info-text">{reg_number}</span>
              </div>
            )}
          </div>
        </div>

        <div className="simple-header-info-logo">
          <img src={logo} alt="company logo" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(TurboHoetHeader);
