export const SCREENS = {
  FIRST_SCREEN: 1,
  INFO: 1,
  SCHEDULED_SERVICES: 2,
  OPTIONAL_SERVICES: 3,
  REMARKS: 4,
  ORDER: 5,
  THANK_YOU: 6,
  LAST_SCREEN: 6,
  SUPPORT: 7,
};
