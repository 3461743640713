import axios from "axios";

import ENV from "../../../../../util/env-config";

class Service {
  static failed = false;

  static openKeyLocker(locker, softmode) {
    if (softmode) {
      if (!this.failed) {
        this.failed = true;
        return Promise.reject({ message: "Softmode: Error opening keylocker box" });
      }

      return Promise.resolve();
    }

    return axios.get(`${ENV.keylockerRelayIPAddress}/state.xml?relay${locker}State=2`);
  }

  static relayOpenKeylockerError(requestData) {
    return axios.post(`${ENV.keylockerBase}/keylockers/relay_error`, requestData);
  }

  static customerOpened(requestData) {
    return axios.post(`${ENV.keylockerBase}/pickback/opened`, requestData);
  }

  static adminOpened(requestData) {
    return axios.post(`${ENV.keylockerBase}/keylockers/admin/opened`, requestData);
  }
}

export default Service;
