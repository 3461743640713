import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons";

import "./index.scss";

class IENotSupported extends Component {
  componentDidMount = () => {
    let lc = navigator.language || navigator.userLanguage;
    if (lc !== "en-GB" && lc !== "fr-FR" && lc !== "nl-NL" && lc !== "de-DE") lc = "en-GB";

    this.props.i18n.changeLanguage(lc);
  };

  render() {
    return (
      <div id="main">
        <div id="We_noticed_you_are_using_an_ol">
          <span>
            {this.props.t("cc_old_IE").message || "We noticed you are using an old version of Internet Explorer which is not supported."}
            <br />
            {this.props.t("cc_use_recent").message || "Make sure you are using the most recent version of your browser"}
            <br />
            {this.props.t("cc_or_switch").message || "or switch to supported browser to get full experience."}
          </span>
        </div>
        <img alt="main pic" id="mainpic" src="2459468.png" srcSet="2459468.png 1x, 2459468@2x.png 2x" />
        <a rel="noopener noreferrer" href="https://www.google.com/chrome/" target="_blank">
          <img alt="chrome" id="chromepic" src="chrome.png" srcSet="chrome.png 1x, chrome@2x.png 2x" />
        </a>
        <a rel="noopener noreferrer" href="https://firefox.com/" target="_blank">
          <img alt="firefox" id="firefoxpic" src="firefox.png" srcSet="firefox.png 1x, firefox@2x.png 2x" />
        </a>
        <a rel="noopener noreferrer" href="https://www.microsoft.com/microsoft-edge" target="_blank">
          <img alt="edge" id="edgepic" src="microsoft-edge-icon-26.png" srcSet="microsoft-edge-icon-26.png 1x, microsoft-edge-icon-26@2x.png 2x" />
        </a>
        <div id="Browser_update_required">
          <span>{this.props.t("cc_Browser_update_required").message || "Browser update required"}</span>
        </div>
        <div id="Supported_browsers">
          <span>{this.props.t("cc_Supported_browsers").message || "Supported browsers"}</span>
        </div>
        <div id="Group_1">
          <svg className="Path_1" viewBox="0 0 61 61">
            <path
              fill="rgba(217,248,230,1)"
              id="Path_1"
              d="M 30.5 0 C 47.34468078613281 0 61 13.65531730651855 61 30.5 C 61 47.34468078613281 47.34468078613281 61 30.5 61 C 13.65531730651855 61 0 47.34468078613281 0 30.5 C 0 13.65531730651855 13.65531730651855 0 30.5 0 Z"
            />
          </svg>
          <div id="_">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
        </div>
        <div id="Chrome">
          <span>
            <a rel="noopener noreferrer" href="https://www.google.com/chrome/" target="_blank">
              Chrome
            </a>
          </span>
        </div>
        <div id="Firefox">
          <span>
            <a rel="noopener noreferrer" href="https://firefox.com/" target="_blank">
              Firefox
            </a>
          </span>
        </div>
        <div id="Microsoft_Edge">
          <span>
            <a rel="noopener noreferrer" href="https://www.microsoft.com/microsoft-edge" target="_blank">
              Microsoft Edge
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(IENotSupported);
