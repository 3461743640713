import React, { Component } from "react";
import { Checkbox } from "semantic-ui-react";

import AgreementModal from "./AgreementModal";

class Agreements extends Component {
  state = {
    isAgreementModalOpen: false,
    selectedAgreement: null,
  };

  handleCloseAgreementModal = () => this.setState({ isAgreementModalOpen: false, selectedAgreement: null });

  handleOpenAgreementModal = selectedAgreement => this.setState({ isAgreementModalOpen: true, selectedAgreement });

  render() {
    const { isAgreementModalOpen, selectedAgreement } = this.state;
    const { agreements, showAgreementError, handleAgreementChecked } = this.props;
    if (!agreements || !agreements.length) return null;

    return (
      <div className="OrderAgreements">
        {agreements.map((a, i) => (
          <div key={"agreement" + i} className="agreement">
            <Checkbox defaultChecked={a.accepted} onChange={() => handleAgreementChecked(a.id)} className="agreement-box" />
            <button
              className={`agreement-open-button ${showAgreementError && !a.optional_keylocker_check_in && !a.accepted ? "agreement-error" : ""}`}
              onClick={() => this.handleOpenAgreementModal(a)}
            >
              {a.name}
              {!a.optional_keylocker_check_in ? "*" : ""}
            </button>
          </div>
        ))}

        <AgreementModal isOpen={isAgreementModalOpen} onClose={this.handleCloseAgreementModal} selectedAgreement={selectedAgreement} />
      </div>
    );
  }
}

export default Agreements;
