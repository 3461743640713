import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { Cancel, PhonePad } from "../../components";
import MainLayout from "../../layout";

import CheckInfo from "./CheckInfo";

const SUBSCREENS = {
  MAIN: 1,
  CANCEL: 2,
  ADD_PHONE_NUMBER: 3,
};

const STEP = 2;

class CheckInfoScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    extraPhone: "",
  };

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kiosk_step").message || "Step") + " " + STEP;
    const title = t("kiosk_check_validate").message || "Check and Validate Contact Information";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goToAddPhoneNumber = () => this.setState({ screen: SUBSCREENS.ADD_PHONE_NUMBER });
  saveExtraPhone = extraPhone => this.setState({ screen: SUBSCREENS.MAIN, extraPhone });

  render() {
    const { communication, onGoToNextScreenWithState, onAbort, t } = this.props;
    const { screen, extraPhone } = this.state;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <CheckInfo
            renderLayout={this.renderLayout}
            extraPhone={extraPhone}
            communication={communication}
            onGoToCancelScreen={this.goToCancel}
            onGoToAddPhoneNumber={this.goToAddPhoneNumber}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
          />
        );

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.ADD_PHONE_NUMBER:
        return (
          <PhonePad
            renderLayout={this.renderLayout}
            leftButtonCancel={true}
            rightButtonText={t("kiosk_next").message || "Next"}
            phone={extraPhone}
            onGoToCancelScreen={this.goToCancel}
            onBackCancel={this.goBack}
            onConfirm={this.saveExtraPhone}
          />
        );
    }
  }
}

export default withTranslation()(CheckInfoScreen);
