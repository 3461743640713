import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { NavigationButton, HelpButton } from "../../../components";
import Service from "./service";

import "./PickUpKey.scss";

class PickUpKey extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.openLocker();
  }

  openLocker() {
    const { keyLockerNumber, pin, adminOpenDoor, softmode, match, onGoToErrorScreen } = this.props;

    this.setState({ loading: true }, () => {
      Service.openKeyLocker(keyLockerNumber, softmode)
        .then(() => {
          const callResponse = adminOpenDoor
            ? Service.adminOpened({ private_key: match.params.keylockerKey, pin, box_number: keyLockerNumber })
            : Service.customerOpened({ private_key: match.params.keylockerKey, pin });

          callResponse
            .then(() => {
              this.setState({ loading: false });
            })
            .catch(error => {
              let errorMessage = "";
              try {
                if (error.response) {
                  if (error.response.data?.errors?.length) errorMessage = error.response.data.errors[0];
                  else errorMessage = JSON.stringify(error.response.data);
                } else errorMessage = error.message;
              } catch (error) {
                console.error("Failed to parse the error message", error);
                errorMessage = "Failed to parse the error message";
              }

              this.setState({ loading: false }, () => {
                Service.relayOpenKeylockerError({ private_key: match.params.keylockerKey, box_number: keyLockerNumber, error: errorMessage });
                onGoToErrorScreen();
              });
            });
        })
        .catch(error => {
          let errorMessage = "";
          try {
            if (error.response) {
              if (error.response.data?.errors?.length) errorMessage = error.response.data.errors[0];
              else errorMessage = JSON.stringify(error.response.data);
            } else errorMessage = error.message;
          } catch (error) {
            console.error("Failed to parse the error message", error);
            errorMessage = "Failed to parse the error message";
          }

          this.setState({ loading: false }, () => {
            Service.relayOpenKeylockerError({ private_key: match.params.keylockerKey, box_number: keyLockerNumber, error: errorMessage });
            onGoToErrorScreen();
          });
        });
    });
  }

  render() {
    const { loading } = this.state;
    const { keyLockerNumber, renderLayout, onGoToNextScreen, onGoToHelpScreen, t } = this.props;

    const headerButtons = [<HelpButton disabled={loading} key={1} onClick={onGoToHelpScreen} />];

    const content = (
      <div className="PickUpScreen">
        <div className="PickUpBox">{keyLockerNumber ? <div className="PickUpBoxNumber">{keyLockerNumber}</div> : <Loader active size="massive" />}</div>
        <div className="PickUpMessage">{t("kl_pickup_key_message1").message || "Pick up your car key in the box and close it."}</div>
        <div className="PickUpMessage">{t("kl_pickup_key_message2").message || "Then come back here and click on Confirm."}</div>
      </div>
    );

    const rightButton = (
      <NavigationButton loading={loading} onClick={onGoToNextScreen}>
        {t("kl_confirm").message || "Confirm"}
      </NavigationButton>
    );

    return renderLayout({ headerButtons, rightButton, content });
  }
}

export default withRouter(withTranslation()(PickUpKey));
