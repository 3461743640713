import React, { Component } from "react";

import { TurboHoetHeader } from "../../components";

import { SimpleImageGallery, DetailAnalysis } from "./components";
import { withTranslation } from "react-i18next";

import "./index.scss";

class TurboHoetCommTemplate extends Component {
  renderMainContent = () => {
    const { settings, communication, t } = this.props;
    const { appointment, results } = communication;
    const lettersMap = SimpleImageGallery.getLettersMap(appointment, results);

    return (
      <div className="mainContent">
        <div className="simple-header-container">
          <h1>{t("cc_turbocharge_rating").message || "Turbocharger rating"}</h1>
        </div>
        <TurboHoetHeader settings={settings} communication={communication} />
        <DetailAnalysis appointment={appointment} communication={communication} lettersMap={lettersMap} />
        <SimpleImageGallery lettersMap={lettersMap} />
        <div className="simple-footer">www.th-group.eu</div>
      </div>
    );
  };

  render() {
    return <div className="report-template">{this.renderMainContent()}</div>;
  }
}

export default withTranslation()(TurboHoetCommTemplate);
