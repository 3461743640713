import React from "react";
import { withTranslation } from "react-i18next";

import { CancelButton, NavigationButton, Error } from "../../components";

const OrderError = ({ renderLayout, onGoBack, onAbort, t }) => {
  const headerButtons = [<CancelButton key={1} onClick={onAbort} />];

  const content = <Error>{t("kiosk_order_error").message || "Something went wrong, please try again."}</Error>;

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  return renderLayout({ headerButtons, content, leftButton });
};

export default withTranslation()(OrderError);
