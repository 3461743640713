import React from "react";
import { withTranslation } from "react-i18next";
import { Icon, Button } from "semantic-ui-react";

import "./index.scss";

const NavigationButton = props => {
  const { secondary, back, cancel, icon, children, t, onClick, disabled, loading } = props;

  if (secondary) {
    return (
      <Button className="NavigationButton secondary" onClick={onClick}>
        {children}
      </Button>
    );
  }

  if (back) {
    return (
      <Button className="NavigationButton back" icon labelPosition="left" onClick={onClick}>
        {t("kl_back").message || "Back"}
        <Icon name="arrow left" />
      </Button>
    );
  }

  if (cancel) {
    return (
      <Button className="NavigationButton cancel" icon labelPosition="left" onClick={onClick}>
        {t("kl_cancel").message || "Cancel"}
        <Icon name="cancel" />
      </Button>
    );
  }

  return (
    <Button icon className="NavigationButton" loading={loading} disabled={disabled || loading} labelPosition="right" onClick={onClick}>
      {children || t("kl_next").message || "Next"}
      <Icon name={icon || "arrow right"} />
    </Button>
  );
};

export default withTranslation()(NavigationButton);
