import styled from "styled-components";

export const CloseButton = styled.div`
  .ui.basic.button.closeButton {
    margin-right: 10px;
    color: white !important;
    background-color: ${props => props.color || "green"} !important;
    border: 0;
    text-shadow: none !important;
    background-image: none !important;
    box-shadow: none;
  }
`;

export const MessageHeader = styled.div`
  h1 {
    color: ${props => props.color || "#11ca80"};
    opacity: 1;
  }
`;

export const LeftImage = styled.div`
  background-image: url('${props => props.welcomePicture || "/car1.png"}');
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  padding: 2rem;
  min-height: 460px;
`;

export const MessageContent = styled.div`
  text-align: left;
  letter-spacing: 0;
  font-size:1.5em;
  opacity: 1;
  margin-top: 20px;
  font-family: 'proxima-nova';
  font-weight: 300;
  padding-right: 1rem;
  word-wrap: break-word;
  padding: 5px;
  .bottom {
    text-align: center;
    button {
      margin-top: 20px;
      padding: 1.3rem;
      float: right;
      background: ${props => props.color || "#11ca80"} 0% 0% no-repeat
      padding-box;
      border-radius: 6px;
      opacity: 1;
      letter-spacing: 0;
      color: #ffffff;
      font-weight: normal;
      font-size: 13px;

      outline: none;
      &:hover,
      &:active,
      &:focus {
        background: ${props => props.color || "#11ca80"} 0% 0% no-repeat
        color: white;
      }
    }
`;

export const MessageBottom = styled.div`
  color: #5c7b8c;
  padding: 2rem 0rem;
  width: 100%;
`;

export const CommunicationNote = styled.span`
  padding: 1rem;
  display: block;
  background-color: ${props => props.color || "green"};
  color: white;
  border-radius: 5px;
  margin-top: 10px;

  svg {
    margin-right: 5px;
  }
`;
