import React from "react";

import "./index.scss";

const MainLayout = ({ step, title, headerButtons, content, leftButton, rightButton }) => {
  return (
    <div className="KeyLocker">
      <div className="KeyLockerHeader">
        {step && <div className="KeyLockerHeaderStep">{step}</div>}
        <div className="KeyLockerHeaderTitle">{title}</div>
        <div className="KeyLockerHeaderActions">{headerButtons}</div>
      </div>
      <div className="KeyLockerContent">{content}</div>
      <div className="KeyLockerNavigation">
        <div className="KeyLockerNavigationLeftButton">{leftButton}</div>
        <div className="KeyLockerNavigationRightButton">{rightButton}</div>
      </div>
    </div>
  );
};

export default MainLayout;
