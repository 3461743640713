import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { Cancel, CallForHelp } from "../../../../../components";

import AuthenticationHelp from "../AuthenticationHelp";
import AuthenticationError from "../AuthenticationError";
import EnterRegistration from "./EnterRegistration";

import Service from "./service";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  CALL_FOR_HELP: 4,
  ERROR: 5,
};

class EnterRegistrationScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    registrationNumber: "",
    emailAddress: "",
    loading: false,
  };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToErrorScreen = () => this.setState({ screen: SUBSCREENS.ERROR });

  onChange = registrationNumber => registrationNumber.length <= 6 && this.setState({ registrationNumber });

  handleGetCommunicationChannels = () => {
    const { registrationNumber: reg_nr } = this.state;
    const { match, onGoToConfirmAuthenticationScreen } = this.props;

    this.setState({ loading: true }, () => {
      Service.getCommunicationChannels({ reg_nr, private_key: match.params.keylockerKey })
        .then(response => {
          this.setState({ loading: false }, () => {
            if (response?.data?.data)
              onGoToConfirmAuthenticationScreen({
                reg_nr,
                emailAddress: response.data.data.email_address,
                displayedPhoneNr: response.data.data.phone_nr,
                appointment_id: response.data.data.appointment_id,
              });
            else this.goToErrorScreen();
          });
        })
        .catch(err => {
          this.setState({ loading: false }, () => this.goToErrorScreen());
        });
    });
  };

  render() {
    const { screen, registrationNumber, loading } = this.state;
    const { renderLayout, onAbort, onGoBack, t } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <EnterRegistration
            renderLayout={renderLayout}
            onGoBack={onGoBack}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancelScreen={this.goToCancel}
            onHandleGetCommunicationChannels={this.handleGetCommunicationChannels}
            registrationNumber={registrationNumber}
            onChange={this.onChange}
            loading={loading}
          />
        );

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={renderLayout} onGoBack={this.goBack} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;

      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={renderLayout}
            onGoBack={this.goBack}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            message={t("kl_authentication_error").message || "We could not find your appointment or we do not have your contact information to send you a PIN code."}
          />
        );
    }
  }
}

export default withRouter(withTranslation()(EnterRegistrationScreen));
