import React, { Component } from "react";
import { Button } from "semantic-ui-react";

import { ReportTemplate } from "../../../../";

import printJS from "./print-js";

class Print extends Component {
  state = {
    isPrintLoading: false,
  };

  stopLoading = () => this.setState({ isPrintLoading: false });

  handlePrint = () => {
    return this.setState({ isPrintLoading: true }, () => {
      printJS({ printable: "header-print-button-report", type: "html", copyStyles: true, onLoadingEnd: this.stopLoading, onError: this.stopLoading });
    });
  };

  render() {
    const { isPrintLoading } = this.state;
    return (
      <>
        <Button className="header-print-button bg-color" loading={isPrintLoading} onClick={() => this.handlePrint()} primary>
          {this.props.t("cc_print").message || "Print"}
        </Button>
        <div style={{ display: "none" }}>
          <div id="header-print-button-report">
            <ReportTemplate communication={this.props.communication} settings={this.props.settings} />
          </div>
        </div>
      </>
    );
  }
}

export default Print;
