import axios from "axios";

import ENV from "../../../../util/env-config";

class Service {
  static getChecklistReport(communication_key) {
    return axios.post(ENV.customComBase + `/repair_overview/download`, { communication_key }, { responseType: "blob" });
  }
}

export default Service;
