import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox, faSlash } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import MainLayout from "../../layout";

import "./index.scss";

const KioskDisabledScreen = ({ t }) => {
  const content = (
    <div className="KioskDisabledScreen">
      <div className="KioskTitle">
        <div className="KioskDisabledCompositeIcon">
          <FontAwesomeIcon icon={faSlash} />
          <br />
          <FontAwesomeIcon icon={faInbox} />
        </div>
        <span>{t("kiosk_temporarily_unavailable").message || "Temporarily unavailable"}!</span>
      </div>
      <div className="KioskDisabledMessage">{t("kiosk_disabled_message").message || "Kiosk is currently unavailable, we apologize for any inconvenience."}</div>
    </div>
  );
  return <MainLayout content={content} />;
};

export default withTranslation()(KioskDisabledScreen);
