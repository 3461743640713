import React from "react";

import "./index.scss";

const Step = ({ number, icon = null, title, size = "small", onClick = null, child = null, numberClassName = "" }) => {
  return (
    <div className={`StepContainer -size-${size}`} onClick={onClick}>
      <div className={`Step__number ${numberClassName}`}>{number}</div>
      <div className="Step__icon">{icon && icon}</div>
      <div className="Step__title">
        <p>{title}</p>
      </div>
      {child && <div className="StepContainer__child_elements">{child}</div>}
    </div>
  );
};

export default Step;
