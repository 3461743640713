import WelcomeScreen from "./Welcome";
import AuthenticationScreen from "./Authentication";
import CheckInfoScreen from "./CheckInfo";
import OrderScreen from "./Order";
import ThankYouScreen from "./ThankYou";
import SelectLabelScreen from "./SelectLabel";
import KioskDisabled from "./KioskDisabled";

const SCREENS = {
  FIRST_SCREEN: 1,
  WELCOME: 1,
  AUTH_OPTIONS: 2,
  CHECK_INFO: 3,
  ORDER: 4,
  SELECT_LABEL: 5,
  THANK_YOU: 6,
  LAST_SCREEN: 6,
};

export { SCREENS, WelcomeScreen, AuthenticationScreen, CheckInfoScreen, OrderScreen, SelectLabelScreen, ThankYouScreen, KioskDisabled };
