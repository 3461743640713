import React from "react";
import { withTranslation } from "react-i18next";

import { Help, NavigationButton } from "../../../components";

const OrderHelp = ({ renderLayout, onGoBack, onGoToCallForHelp, t }) => {
  const content = (
    <Help>
      <div className="OrderHelp">
        <div>
          <div>{t("kl_incorrect_list_call_for_help_part1").message || "If the list is incorrect, please abort or"}</div>
          <div>{t("kl_incorrect_list_call_for_help_part2").message || "press Call For Help."}</div>
        </div>
        <div>
          <div>{t("kl_incorrect_list_remarks_part1").message || "If you would like to add remarks, please"}</div>
          <div>{t("kl_incorrect_list_remarks_part2").message || "press Add Remarks."}</div>
        </div>
      </div>
    </Help>
  );

  const leftButton = (
    <NavigationButton back icon="left arrow" onClick={onGoBack}>
      {t("kl_back").message || "Back"}
    </NavigationButton>
  );

  const rightButton = (
    <NavigationButton icon="phone" onClick={onGoToCallForHelp}>
      {t("kl_call_for_help").message || "Call For Help"}
    </NavigationButton>
  );

  return renderLayout({ rightButton, leftButton, content });
};

export default withTranslation()(OrderHelp);
