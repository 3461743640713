import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import moment from "moment";

import MainLayout from "../../../layout";

import DropKey from "./DropKey";
import DropKeyHelp from "./DropKeyHelp";
import DropKeyError from "./DropKeyError";
import { CallForHelp, Cancel } from "../../../components";

import Service from "./service";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  ERROR: 3,
  CALL_FOR_HELP: 4,
  CANCEL: 5,
};

const STEP = 4;
class DropKeyScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    keyLockerNumber: null,
    errorMessage: "",
  };

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kl_step").message || "Step") + " " + STEP;
    const title = t("kl_drop_your_keys").message || "Drop Your Key inside Box";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goToErrorScreen = errorMessage => this.setState({ screen: SUBSCREENS.ERROR, errorMessage });
  saveKeyLockerNumber = keyLockerNumber => this.setState({ keyLockerNumber });

  handleAbortDropKey = () => {
    const { match, communication } = this.props;

    const data = {
      communication_key: communication.user_key,
      private_key: match.params.keylockerKey,
      message: this.state.errorMessage,
      created_at: moment()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssZ"),
    };

    Service.abortDropKey(data)
      .then(() => this.props.onAbort())
      .catch(() => {
        this.props.onAbort({ failure: data });
      });
  };

  render() {
    const { onGoToNextScreen, communication, extraPhone, additionalRemark, customerName, customerAcceptedAgreementsIDs, softmode } = this.props;

    switch (this.state.screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <DropKey
            renderLayout={this.renderLayout}
            onGoToNextScreen={onGoToNextScreen}
            onGoToHelpScreen={this.goToHelp}
            onGoToErrorScreen={this.goToErrorScreen}
            communication={communication}
            extraPhone={extraPhone}
            additionalRemark={additionalRemark}
            customerName={customerName}
            customerAcceptedAgreementsIDs={customerAcceptedAgreementsIDs}
            keyLockerNumber={this.state.keyLockerNumber}
            onLockerAssigned={this.saveKeyLockerNumber}
            softmode={softmode}
          />
        );

      case SUBSCREENS.HELP:
        return <DropKeyHelp renderLayout={this.renderLayout} onGoBack={this.goBack} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={this.handleAbortDropKey} />;

      case SUBSCREENS.ERROR:
        return (
          <DropKeyError
            renderLayout={this.renderLayout}
            onGoBack={this.goBack}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            onAbort={this.goToCancel}
          />
        );

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} communication={communication} />;
    }
  }
}

export default withRouter(withTranslation()(DropKeyScreen));
