import axios from "axios";
import ENV from "../../util/env-config";

const requestConfig = authToken => ({ headers: { Authorization: `Bearer ${authToken}` } });

class Service {
  static getTokenViaGoogle(requestData) {
    return axios.post(`${ENV.authBase}/session/login/google_sign_in`, requestData);
  }

  static getListOfReceptionists(authToken) {
    return axios.get(`${ENV.deskComBase}/counter_tablets/list_receptionists`, requestConfig(authToken));
  }

  static selectReceptionist(requestData, authToken) {
    return axios.post(`${ENV.deskComBase}/counter_tablets/register_tablet`, requestData, requestConfig(authToken));
  }

  static deskCheckinURL(requestData, authToken) {
    return axios.post(`${ENV.deskComBase}/counter_tablets/update_status`, requestData, requestConfig(authToken));
  }

  static getUserFromToken(authToken) {
    return axios.post(`${ENV.authBase}/session/me`, {}, requestConfig(authToken));
  }

  static getUserLocation(authToken, id) {
    return axios.post(`${ENV.apiBase}/locations/details`, { id }, requestConfig(authToken));
  }
}

export default Service;
