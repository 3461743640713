import React, { Component } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { Loader } from "semantic-ui-react";

import { NavigationButton } from "../../../../../components";

import Service from "./service";

import "./ScanQRCode.scss";

class ScanQRCode extends Component {
  state = {
    scanRequestKey: null,
    loading: false,
  };

  verifyScanRequestSetTimeoutID = null;

  componentDidMount() {
    this.getScanRequestKey();
  }

  getScanRequestKey = () => {
    this.setState({ loading: true }, () => {
      const private_key = this.props.match.params.keylockerKey;

      Service.initPickupScan({ private_key })
        .then(res => {
          this.setState({ loading: false, scanRequestKey: res.data.data.scan_request_key }, () => {
            setTimeout(() => {
              this.verifyScanRequest();
            }, 1000);
          });
        })
        .catch(() => {
          this.setState({ loading: false }, () => {
            this.props.onGoToErrorScreen();
          });
        });
    });
  };

  verifyScanRequest = () => {
    const private_key = this.props.match.params.keylockerKey;
    const scan_request_key = this.state.scanRequestKey;

    Service.verifyPickupScan({ private_key, scan_request_key })
      .then(res => {
        const { box_number } = res.data.data;

        if (box_number === 0) this.verifyScanRequestSetTimeoutID = setTimeout(() => this.verifyScanRequest(), 1000);
        else {
          this.setState({ loading: true, scanRequestKey: null }, () => {
            Service.openKeyLocker(box_number, this.props.softmode)
              .then(async () => {
                try {
                  await Service.verifyPickupOpened({ private_key, scan_request_key });
                  this.props.onAbort();
                } catch (err) {
                  this.props.onGoToErrorScreen();
                }
              })
              .catch(error => {
                Service.relayError({ private_key, error });
                this.props.onGoToErrorScreen();
              });
          });
        }
      })
      .catch(() => this.props.onGoBack());
  };

  resetScanRequestKey = () => this.setState({ scanRequestKey: null });

  goBackToMainScreen = () => {
    clearTimeout(this.verifyScanRequestSetTimeoutID);
    this.resetScanRequestKey();
    this.props.onGoBack();
  };

  render() {
    const { loading, scanRequestKey } = this.state;
    const { renderLayout } = this.props;

    const leftButton = <NavigationButton back loading={loading} onClick={this.goBackToMainScreen} />;

    const content = (
      <div className="KeylockerPickupKeyQRCodeContainer">
        <div className="KeylockerPickupKeyQRCode">{scanRequestKey && <QRCode bgColor="grey" value={scanRequestKey} size={300} />}</div>
      </div>
    );

    if (loading) return <Loader active size="massive" />;

    return renderLayout({ content, leftButton });
  }
}

export default withRouter(withTranslation()(ScanQRCode));
