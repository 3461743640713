import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faUserCheck, faListAlt, faKey, faInbox } from "@fortawesome/pro-solid-svg-icons";

import MainLayout from "../../layout";
import APP_CONFIG from "../../../../util/app-config";
import { NavigationButton, Step } from "../../components";

import Service from "./service";

import "./index.scss";

class WelcomeScreen extends Component {
  statusUpdateInterval = null;
  versionCheckInterval = null;

  componentDidMount() {
    this.statusUpdateInterval = setInterval(this.props.onStatusUpdate, 60000);
    this.versionCheckInterval = setInterval(this.checkLatestVersion, 360000);
  }

  componentWillUnmount() {
    clearInterval(this.statusUpdateInterval);
    clearInterval(this.versionCheckInterval);
  }

  checkLatestVersion = async () => {
    const {
      data: { latest_version },
    } = await Service.loadLatestVersion();

    if (latest_version > APP_CONFIG.FRONTEND_VERSION) document.location.reload();
  };

  renderContent = () => {
    const { onGoToNextScreen, t } = this.props;

    return (
      <div className="KioskWelcomeScreen">
        <h2>{t("kiosk_welcome").message || "Welcome"}</h2>
        <div className="WelcomeScreenMessage">
          {t("kiosk_welcome_message_part1").message || "To leave your key with this receptionist,"}
          <br />
          {t("kiosk_welcome_message_part2").message || "go through these four steps."}
        </div>
        <div centered className="WelcomeScreenSteps">
          <Step
            number={1}
            icon={<FontAwesomeIcon icon={faUserLock} className="WelcomeScreenStepSingleIcon" />}
            title={t("kiosk_authentication").message || "Authentication"}
            onClick={onGoToNextScreen}
          />
          <Step
            number={2}
            icon={<FontAwesomeIcon icon={faUserCheck} className="WelcomeScreenStepSingleIcon" />}
            title={t("kiosk_check_validate").message || "Check and Validate Contact Information"}
            onClick={onGoToNextScreen}
          />
          <Step
            number={3}
            icon={<FontAwesomeIcon icon={faListAlt} className="WelcomeScreenStepSingleIcon" />}
            title={t("kiosk_validate_appointment_list").message || "Validate Appointment List"}
            onClick={onGoToNextScreen}
          />
          <Step
            number={"4 & 5"}
            numberClassName="WelcomeScreenCompositeNumber"
            icon={
              <div className="WelcomeScreenStepCompositeIcon">
                <FontAwesomeIcon icon={faKey} />
                <br />
                <FontAwesomeIcon icon={faInbox} />
              </div>
            }
            title={t("kiosk_pick_label_number_and_drop_off_your_key").message || "Pick a label number & drop off your key"}
            onClick={onGoToNextScreen}
          />
        </div>
      </div>
    );
  };

  render() {
    const { onGoToNextScreen, t } = this.props;

    const mainButton = <NavigationButton onClick={onGoToNextScreen}>{t("kiosk_start").message || "Start"}</NavigationButton>;

    return <MainLayout mainButton={mainButton} content={this.renderContent()} />;
  }
}

export default withTranslation()(WelcomeScreen);
