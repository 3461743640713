import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import SelectLabel from "./SelectLabel";
import SelectLabelError from "./SelectLabelError";
import MainLayout from "../../layout";
import { Cancel } from "../../components";

const SUBSCREENS = {
  MAIN: 1,
  CANCEL: 2,
  ERROR: 3,
};

const STEP = 4;

class SelectLabelScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    pickedNumber: 0,
  };

  handlePickedNumber = pickedNumber => {
    this.setState({ pickedNumber });
  };

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kiosk_step").message || "Step") + " " + STEP;
    const title = t("kiosk_pick_a_label_number").message || "Pick a label number";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToError = () => this.setState({ screen: SUBSCREENS.ERROR });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });

  render() {
    const { pickedNumber, screen } = this.state;
    const { extraPhone, additionalRemark, agreements, communication, customerName, customerSignature, onAbort, onGoToNextScreen } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <SelectLabel
            extraPhone={extraPhone}
            additionalRemark={additionalRemark}
            agreements={agreements}
            pickedNumber={pickedNumber}
            communication={communication}
            customerName={customerName}
            customerSignature={customerSignature}
            renderLayout={this.renderLayout}
            onPickedNumber={this.handlePickedNumber}
            onGoToNextScreen={onGoToNextScreen}
            onGoToCancelScreen={this.goToCancel}
            goToError={this.goToError}
          />
        );

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.ERROR:
        return <SelectLabelError renderLayout={this.renderLayout} onGoBack={this.goBack} onAbort={onAbort} />;
    }
  }
}

export default withTranslation()(SelectLabelScreen);
