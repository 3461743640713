import React from "react";
import { withTranslation } from "react-i18next";

import "./index.scss";

const Maintenance = ({ t }) => {
  return (
    <div className="MaintenanceContainer">
      <div className="Maintenance">
        <div className="header">
          <div>Whoops!</div>
          <div>{t("cc_maintenance_message").message || "Sorry, we're down for scheduled maintenance, please try again later"}</div>
        </div>
        <div>
          <img src="/Car_image_404.png" alt="car background" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Maintenance);
