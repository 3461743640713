import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { AuthenticationOptions, EnterRegistrationScreen, EnterPhoneScreen } from "./subscreens";

import { Cancel } from "../../components";

import MainLayout from "../../layout";

const SUBSCREENS = {
  MAIN: 1,
  CANCEL: 2,
  ENTER_REGISTRATION: 3,
  ENTER_PHONE: 4,
};

const STEP = 1;
class AuthenticationScreen extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    reg_nr: null,
    phone_nr: null,
    appointment_id: null,
  };

  startInactivityTimer = () => (this.inactivityTimer = setTimeout(() => this.props.onGoBackToStartScreen(), 60000));

  handleResetInactivityTimer = () => {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  };

  componentDidMount() {
    this.startInactivityTimer();
    document.addEventListener("click", this.handleResetInactivityTimer);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleResetInactivityTimer);
    clearTimeout(this.inactivityTimer);
  }

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kiosk_step").message || "Step") + " " + STEP;
    const title = t("kiosk_authentication").message || "Authentication";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToEnterRegNrScreen = () => this.setState({ screen: SUBSCREENS.ENTER_REGISTRATION });
  goToEnterPhoneNrScreen = () => this.setState({ screen: SUBSCREENS.ENTER_PHONE });

  render() {
    const { screen } = this.state;
    const { onGoToNextScreenWithState, onGoToPreviousScreen, onAbort } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <AuthenticationOptions
            renderLayout={this.renderLayout}
            onGoBack={onGoToPreviousScreen}
            onGoToCancelScreen={this.goToCancel}
            onGoToEnterPinScreen={this.goToEnterPinScreen}
            onGoToEnterRegNrScreen={this.goToEnterRegNrScreen}
            onGoToEnterPhoneNrScreen={this.goToEnterPhoneNrScreen}
            onGoToScanQRCode={this.goToScanQRCode}
          />
        );

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.ENTER_REGISTRATION:
        return (
          <EnterRegistrationScreen
            renderLayout={this.renderLayout}
            onGoBack={this.goBack}
            onGoToCancelScreen={this.goToCancel}
            onGoToConfirmAuthenticationScreen={this.goToConfirmAuthenticationScreen}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
            onAbort={onAbort}
          />
        );

      case SUBSCREENS.ENTER_PHONE:
        return (
          <EnterPhoneScreen
            renderLayout={this.renderLayout}
            onGoBack={this.goBack}
            onGoToCancelScreen={this.goToCancel}
            onGoToConfirmAuthenticationScreen={this.goToConfirmAuthenticationScreen}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
            onAbort={onAbort}
          />
        );
    }
  }
}

export default withTranslation()(AuthenticationScreen);
