import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/pro-solid-svg-icons";

import "./index.scss";

const Expandable = ({ text, className }) => {
  const [expanded, setExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(true);
  const container = useRef(null);

  useEffect(() => {
    container.current && !expanded && container.current.scrollWidth <= container.current.clientWidth && setCanExpand(false);
  }, [expanded]);

  if (typeof text === "string" && !text.trim()) return null;

  return (
    <div className="expandable-container" onClick={() => canExpand && setExpanded(!expanded)}>
      <span ref={container} className={className + (canExpand && !expanded ? " collapsed" : " expanded")}>
        {text}
      </span>
      {canExpand && <FontAwesomeIcon icon={expanded ? faCaretUp : faCaretDown} />}
    </div>
  );
};

export default Expandable;
