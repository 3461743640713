import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Transition, Loader, Dimmer } from "semantic-ui-react";
import ReactGA from "react-ga";

import { normalizeCommunicationData } from "../../util/common";
import { ExternalScripts } from "../../components";
import { OnlineCheckIn, DiagnoseOverview, RepairOverview, DiagnoseOverviewV2, RepairOverviewV2 } from "../../modules/";
import { CUSTOMER_COMMUNICATION_STATUSES } from "../../util/communication_statuses";

import { IENotSupported, PageNotFound, Maintenance } from "../";

import Service from "./service";

class CustomerCommunication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failedToLoad: false,
      maintenanceMode: false,
      communication: null,
      settings: null,
      routeKey: props.location.hash ? props.location.hash.substring(1) : null,
    };
  }

  componentDidMount = () => {
    const { routeKey } = this.state;

    if (routeKey) {
      this.setState({ loading: true }, () => {
        Service.loadCommunication(routeKey)
          .then(result => {
            const communication = result.data?.data || {};

            if (normalizeCommunicationData(communication, this.props.i18n)) {
              this.setState({
                communication,
                settings: communication.settings,
                loading: false,
              });

              if (communication.settings.google_analytics_id) ReactGA.initialize(communication.settings.google_analytics_id);
            } else {
              if (communication?.settings?.maintenance_mode) {
                this.setState({ maintenanceMode: true, loading: false });
                return;
              }

              this.setState({ failedToLoad: true, loading: false });
              console.log("Failed to load the communication", result);
            }
          })
          .catch(error => {
            this.setState({ failedToLoad: true, loading: false });
            console.log("Failed to load the communication", error);
          });
      });
    }
  };

  render() {
    const { failedToLoad, maintenanceMode, loading, communication, settings, routeKey } = this.state;
    const { match, t } = this.props;

    if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) return <IENotSupported />;

    if (failedToLoad) return <PageNotFound />;

    if (loading) {
      return (
        <Transition visible={true} animation="fade" duration={300}>
          <Dimmer active={true}>
            <Loader size="massive">{t("cc_Loading").message || "Loading"}</Loader>
          </Dimmer>
        </Transition>
      );
    }

    if (maintenanceMode) return <Maintenance />;
    if (!communication || !settings) return <PageNotFound />;

    if (communication.status <= CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_CLOSED)
      return (
        <ExternalScripts settings={settings}>
          <OnlineCheckIn communication={communication} settings={settings} screen={match.params.screen} routeKey={routeKey} />
        </ExternalScripts>
      );

    if (communication.status <= CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_CLOSED)
      return (
        <ExternalScripts settings={settings}>
          {communication.version === 2 ? (
            <DiagnoseOverviewV2 communication={communication} settings={settings} screen={match.params.screen} routeKey={routeKey} />
          ) : (
            <DiagnoseOverview communication={communication} settings={settings} screen={match.params.screen} routeKey={routeKey} />
          )}
        </ExternalScripts>
      );

    if (communication.status <= CUSTOMER_COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED)
      return (
        <ExternalScripts settings={settings}>
          {communication.version === 2 ? (
            <RepairOverviewV2 communication={communication} settings={settings} screen={match.params.screen} routeKey={routeKey} />
          ) : (
            <RepairOverview communication={communication} settings={settings} screen={match.params.screen} routeKey={routeKey} />
          )}
        </ExternalScripts>
      );

    return null;
  }
}

export default withRouter(withTranslation()(CustomerCommunication));
