import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Transition, Loader, Dimmer } from "semantic-ui-react";

import { normalizeCommunicationData } from "../../util/common";
import { ReportTemplate } from "../../modules/";

import Service from "./service";
import PageNotFound from "../PageNotFound";

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      failedToLoad: false,
      communication: null,
      settings: null,
    };
  }

  componentDidMount = () => {
    const { i18n, match } = this.props;
    const routeKey = match.params.routeKey;

    window.addEventListener("message", msg => msg.data === "print" && window.print(), false);

    if (routeKey) {
      this.setState({ isLoading: true }, () => {
        Service.loadReport(routeKey)
          .then(result => {
            if (result.data?.data?.report) {
              try {
                const communication = JSON.parse(result.data.data.report);

                if (normalizeCommunicationData(communication, i18n))
                  return this.setState({
                    isLoading: false,
                    communication,
                    settings: communication.settings,
                  });

                this.setState({ isLoading: false, failedToLoad: true });
                console.log("Failed to normalize data");
              } catch (e) {
                this.setState({ isLoading: false, failedToLoad: true });
                console.log("Failed to parse data", e);
              }
            } else {
              this.setState({ isLoading: false, failedToLoad: true });
              console.log("Failed to load report");
            }
          })
          .catch(err => {
            this.setState({ isLoading: false, failedToLoad: true });
            console.log("Failed to load report", err);
          });
      });
    } else this.setState({ isLoading: false, failedToLoad: true });
  };

  render() {
    const { failedToLoad, communication, settings, isLoading } = this.state;
    const { t } = this.props;

    if (failedToLoad) return <PageNotFound />;

    if (isLoading) {
      return (
        <Transition visible={true} animation="fade" duration={300}>
          <Dimmer active={true}>
            <Loader size="massive">{t("cc_Loading").message || "Loading"}</Loader>
          </Dimmer>
        </Transition>
      );
    }

    if (!communication || !settings) return <PageNotFound />;

    return <ReportTemplate communication={communication} settings={settings} />;
  }
}

export default withRouter(withTranslation()(Report));
