import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { round2 } from "../../../../../../../util/common";

export const displayInterventionPrice = (price, include_vat, vat) => {
  if (!(price > 0.01)) return null;

  if (include_vat) price = price * (1 + vat / 100);

  return <span className="price">€ {round2(price).toFixed(2)}</span>;
};

const ScheduledServices = ({ communication, keylocker, t }) => {
  const { interventions } = communication.appointment;
  const { include_vat, vat } = keylocker;

  if (!interventions.length) return null;

  return (
    <Grid className="OrderContainer">
      <Grid.Row className="ContainerHeader">
        <FontAwesomeIcon icon={faCog} className="icon" />
        <h4>{t("cc_scheduled_services").message || "Scheduled services"}</h4>
      </Grid.Row>

      <Grid.Row className="ContainerHeading">
        <Grid.Column width={14}>
          <h3 className="title">{t("cc_SERVICE_NAME").message || "SERVICE NAME"}</h3>
        </Grid.Column>
        <Grid.Column width={2}>
          <h3 className="title">{t("cc_PRICE").message || "PRICE"}</h3>
        </Grid.Column>
      </Grid.Row>
      {interventions.map(intervention => (
        <Grid.Row key={intervention.id} className="ContainerItem">
          <Grid.Column width={14}>
            <span className="name">{intervention.title}</span>
          </Grid.Column>

          <Grid.Column width={2}>{displayInterventionPrice(intervention.price, include_vat, vat)}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

export default withTranslation()(ScheduledServices);
