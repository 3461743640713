import axios from "axios";

import ENV from "../../util/env-config";

class Service {
  static loadReport(routeKey) {
    return axios.post(`${ENV.appointmentBase}/repair_overview/preview/json`, {
      key: routeKey,
    });
  }
}

export default Service;
