import React from "react";
import { withTranslation } from "react-i18next";

import { round2 } from "../../../../../../util/common";

import "./index.scss";
import { ANSWERS } from "../../../../../../util/answers";
import { TYRE_POSITION } from "../../../../../../util/tyre";

const TotalAmount = ({ interventions, declinedInterventionIds, optionalItems, include_vat, vat, t }) => {
  const getTotalAmount = () => {
    let total = 0;
    let totalVAT = include_vat ? 1 + vat / 100 : 1;

    if (Array.isArray(interventions)) {
      total += interventions.reduce((prev, cur) => {
        if (!(cur.price > 0.01) || declinedInterventionIds?.includes(cur.id)) return prev;
        return prev + round2(cur.price * totalVAT);
      }, 0);
    }

    if (Array.isArray(optionalItems)) {
      total += optionalItems.reduce((prev, cur) => {
        if (TYRE_POSITION.IS_TYRE(cur.tyre_position) && cur.tyre_replacements?.length) {
          cur.tyre_replacements.forEach(rep => {
            if (cur.status === ANSWERS.ADD_TO_CART && rep.selected && rep.price > 0.01) prev += round2(rep.price * totalVAT);
          });
        } else {
          if (cur.price > 0.01 && cur.status === ANSWERS.ADD_TO_CART) prev += round2(cur.price * totalVAT);
        }

        return prev;
      }, 0);
    }

    return total;
  };

  const totalAmount = getTotalAmount();

  if (totalAmount <= 0.01) return null;

  return (
    <div className="desk-checkin-order-total-amount-container">
      <div className="desk-checkin-order-total-amount-money-container">
        <span className="desk-checkin-order-total-amount-money-title">{`${t("cc_TOTAL_AMOUNT").message || "TOTAL AMOUNT"}:`}</span>
        <span className="desk-checkin-order-total-amount-money-amount">€ {totalAmount.toFixed(2)}</span>
      </div>
      {include_vat && (
        <div className="desk-checkin-order-total-amount-vat-container">
          <span className="desk-checkin-order-total-amount-vat-title">
            *{t("cc_including").message || "Including"} {vat}% {t("cc_vat").message || "VAT"}
          </span>
          <span className="desk-checkin-order-total-amount-vat-amount">€ {((totalAmount / (1 + vat / 100)) * (vat / 100)).toFixed(2)}</span>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(TotalAmount);
