import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/pro-solid-svg-icons";

import "./index.scss";

class NumPad2 extends Component {
  handleNumPress = evt => {
    evt.persist();
    this.props.onNumPress(evt.target.dataset.value);
  };

  onTouchStart = e => {
    e.currentTarget.classList.add("button-touch-highlight");
  };

  onTouchEnd = e => {
    const currentElement = e.currentTarget;
    setTimeout(() => {
      currentElement.classList.remove("button-touch-highlight");
    }, 110);
  };

  render() {
    const { onErase } = this.props;

    return (
      <div className="NumPad">
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="1">
          1
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="2">
          2
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="3">
          3
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="4">
          4
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="5">
          5
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="6">
          6
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="7">
          7
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="8">
          8
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="9">
          9
        </button>
        <button className="num" onClick={this.handleNumPress} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} data-value="0">
          0
        </button>
        <button className="erase-box" onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} onClick={onErase}>
          <FontAwesomeIcon icon={faBackspace} className="erase-icon" />
        </button>
      </div>
    );
  }
}

export default NumPad2;
