import styled from "styled-components";

export const MessageHeader = styled.div`
  h1 {
    color: ${props => props.color || "#11ca80"};
    opacity: 1;
  }
`;

export const LeftImage = styled.div`
  background-image: url('${props => props.welcomePicture || "/car1.png"}');
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  padding: 2rem;
  min-height: 460px;
`;

export const MessageContent = styled.div`
  text-align: left;
  letter-spacing: 0;
  font-size:1.5em;
  opacity: 1;
  margin-top: 20px;
  font-family: 'proxima-nova';
  font-weight: 300;
  padding-right: 1rem;
  .bottom {
    text-align: center;
    button {
      margin-top: 20px;
      padding: 1.3rem;
      float: right;
      background: ${props => props.color || "#11ca80"} 0% 0% no-repeat
      padding-box;
      border-radius: 6px;
      opacity: 1;
      letter-spacing: 0;
      color: #ffffff;
      font-weight: normal;
      font-size: 13px;

      outline: none;
      &:hover,
      &:active,
      &:focus {
        background: ${props => props.color || "#11ca80"} 0% 0% no-repeat
        color: white;
      }
    }
`;

export const MessageBottom = styled.div`
  color: #5c7b8c;
  padding: 2rem 0rem;
  width: 100%;
`;

export const RepairOverviewNote = styled.span`
  padding: 1rem;
  display: block;
  background-color: ${props => props.color || "green"};
  color: white;
  border-radius: 5px;
  margin-top: 10px;

  svg {
    margin-right: 5px;
  }
`;

export const ContactUsSectionRepairOverview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ui.basic.button.post-comm-welcome-modal-close-btn {
    margin-left: 20px;
    color: white !important;
    background-color: ${props => props.color || "green"} !important;
    border: 0;
    text-shadow: none !important;
    background-image: none !important;
    box-shadow: none;
  }

  .contact-us-icons {
    margin-left: 15px;
  }

  @media (min-width: 1920px) {
    .ui.basic.button.post-comm-welcome-modal-close-btn {
      margin-left: 2em;
    }
  }

  @media (min-width: 769px) {
    position: absolute;
    bottom: 1em;
    width: 90%;
  }

  @media (max-width: 1223px) {
    margin-top: 10px;
    display: block;

    .contact-us-section-post-comm,
    .contact-us-section-post-comm span {
      width: 100%;
      display: block;
      margin-left: 0 !important;
      margin-top: 10px;
    }

    .ui.basic.button.post-comm-welcome-modal-close-btn {
      margin-top: 1em;
      margin-left: 0px;
      display: block;
    }
  }
`;
