import React from "react";
import { Grid } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

import { WrappedApp } from "./index.style.js";
import "./css-variables.css";
import "./index.scss";

export function MainLayout(props) {
  return (
    <WrappedApp color={props.color}>
      {props.popups}

      <MobileOnly className="mobile-heading">
        <h1>{props.mobileTitle}</h1>
      </MobileOnly>

      <Grid className="mainGrid">
        <Grid.Row className="mainRow">
          {props.leftColumn && shouldBeDisplayed(props.leftColumn) && (
            <Grid.Column computer={3} mobile={16} className="orderColumn">
              {props.leftColumn}
            </Grid.Column>
          )}

          {props.mainColumn && shouldBeDisplayed(props.mainColumn) && (
            <Grid.Column computer={13} mobile={16} className="mainColumn">
              {props.mainColumn}
            </Grid.Column>
          )}

          {props.rightColumn && shouldBeDisplayed(props.rightColumn) && (
            <Grid.Column computer={3} mobile={16} className="orderColumn">
              {props.rightColumn}
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {props.navigation}
    </WrappedApp>
  );
}

export const Responsive = ({ className, showMobile, children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  return isDesktop || showMobile ? className ? <div className={className}>{children}</div> : children : null;
};

export const DesktopOnly = ({ className, children }) => {
  return useMediaQuery({ minWidth: 1224 }) ? className ? <div className={className}>{children}</div> : children : null;
};

export const MobileOnly = ({ className, children }) => {
  return useMediaQuery({ maxWidth: 1223 }) ? className ? <div className={className}>{children}</div> : children : null;
};

const shouldBeDisplayed = element => {
  return typeof element.type !== "function" || !element.props || !!element.type(element.props);
};
