import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";

import "es6-promise/auto";

import "./util/i18n";

import "semantic-ui-less/semantic.less";

import "./index.scss";

import { isLocalhost, unregisterServiceWorker } from "./serviceWorker";
import AppRoutes from "./routes";

if (window.location.protocol !== "https:" && !isLocalhost) {
  window.location.replace(window.location.href.replace("http://", "https://"));
} else {
  ReactDOM.render(<AppRoutes />, document.getElementById("root"));
  unregisterServiceWorker();
}
