import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";

import { NavigationButton } from "../../../components";
import { VirtualKeyboard, LAYOUTS } from "../../../../../components";

import "./OrderEditRemarks.scss";

class OrderEditRemarks extends Component {
  state = { additionalRemark: this.props.additionalRemark || "" };

  onChange = additionalRemark => {
    this.setState({ additionalRemark });
    document.getElementById("OrderEditRemarks").scrollTop = document.getElementById("OrderEditRemarks").scrollHeight;
  };

  render() {
    const { additionalRemark } = this.state;
    const { renderLayout, onSave, onGoBack, t } = this.props;

    const content = (
      <div className="OrderRemarksScreen">
        <div className="OrderRemarksTitle KeyLockerTitle">
          <FontAwesomeIcon icon={faPen} />
          {t("kl_additional_remarks").message || "Additional Remarks"}
        </div>
        <textarea
          id="OrderEditRemarks"
          className="OrderEditRemarks"
          value={additionalRemark}
          placeholder={t("kl_type_something").message || "Type something"}
          disabled={true}
        />
        <VirtualKeyboard onChange={this.onChange} text={additionalRemark} layout={LAYOUTS.FULL} />
      </div>
    );

    const leftButton = <NavigationButton back onClick={onGoBack} />;

    const rightButton = (
      <NavigationButton icon="check" onClick={() => onSave(additionalRemark)}>
        {t("kl_save").message || "Save"}
      </NavigationButton>
    );

    return renderLayout({ rightButton, leftButton, content });
  }
}

export default withTranslation()(OrderEditRemarks);
