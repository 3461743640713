import React, { Component } from "react";
import { Checkbox } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import AgreementModal from "./AgreementModal";

import "./index.scss";

class Agreements extends Component {
  state = {
    isAgreementModalOpen: false,
    selectedAgreement: null,
  };

  handleAgreementChecked = id => {
    if (this.props.readOnly) return;

    this.props.onAgreed(id);
  };

  handleCloseAgreementModal = () => this.setState({ isAgreementModalOpen: false, selectedAgreement: null });

  handleOpenAgreementModal = selectedAgreement => this.setState({ isAgreementModalOpen: true, selectedAgreement });

  render() {
    const { isAgreementModalOpen, selectedAgreement } = this.state;
    const { readOnly, isCheckingOut, agreements, showAgreementError, t } = this.props;
    if (!agreements || !agreements.length) return null;

    return (
      <div className="desk-checkin-order-terms-container">
        {agreements.map((a, i) => {
          const isMandatoryAgreement = isCheckingOut ? !a.optional_desk_check_out : !a.optional_desk_check_in;

          return (
            <div key={"agreement" + i} className="desk-checkin-order-terms-item">
              <Checkbox defaultChecked={a.accepted} disabled={readOnly} onChange={() => this.handleAgreementChecked(a.id)} className="big-box" />
              <div className="desk-checkin-agreement-name-and-error-container">
                <span className="agreement-name" onClick={() => this.handleOpenAgreementModal(a)}>
                  {a.name}
                </span>
                {!a.accepted && isMandatoryAgreement && showAgreementError && (
                  <div className="desk-checkin-order-terms-error">
                    {t("cc_accept_agreeements_desk_communication").message || "*Please accept the agreement to continue!'"}
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <AgreementModal isOpen={isAgreementModalOpen} onClose={this.handleCloseAgreementModal} selectedAgreement={selectedAgreement} />
      </div>
    );
  }
}

export default withTranslation()(Agreements);
