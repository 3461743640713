import React from "react";
import { withTranslation } from "react-i18next";

import { Help, NavigationButton } from "../../../../../components";

import "./index.scss";

const AuthenticationHelp = ({ renderLayout, onGoBack, onGoToCallForHelp, t }) => {
  const content = (
    <Help className="AuthenticationOptionsHelp">
      <div>
        <div>{t("kl_authentication_help1_part1").message || "If you submitted the Online Check-in, a confirmation"}</div>
        <div>{t("kl_authentication_help1_part2").message || "email containing a PIN code was sent to you."}</div>
      </div>
      <div>
        {t("kl_example").message || "Example"}
        <span className="AuthenticationOptionsHelpCode">123 456</span>
      </div>
      <div>
        <div>{t("kl_authentication_help2_part1").message || "If you did not submit the Online Check-in,"}</div>
        <div>{t("kl_authentication_help2_part2").message || "please select the Registration number or the"}</div>
        <div>{t("kl_authentication_help2_part3").message || "Phone number option to receive a PIN code."}</div>
      </div>
    </Help>
  );

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  const rightButton = (
    <NavigationButton icon="phone" onClick={onGoToCallForHelp}>
      {t("kl_call_for_help").message || "Call For Help"}
    </NavigationButton>
  );

  return renderLayout({ leftButton, rightButton, content });
};

export default withTranslation()(AuthenticationHelp);
