import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { MainLayout, Responsive } from "../../../layout";
import { getPhoneNumber, getEmail } from "../../../util/common";
import { APPOINTMENT_NOTE_TYPES } from "../../../util/notes";

import { Header, NavigationButtons, WorkingItems, Order, SideMenu, Support, TiresOverview, NotWorkedOnItems } from "../components";

import { PopupMessage, FixedItems, NotFixedItems } from "./components";
import { SCREENS } from "./util/screens";

class RepairOverview extends Component {
  constructor(props) {
    super(props);
    const { communication } = this.props;

    const fixedInterventions = communication.appointment.interventions.filter(i => i.mechanic_fixed);
    const billNote = communication.appointment.notes.find(note => note.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BILL_NOTE && note.attachments.length);

    this.state = {
      defaultScreen: SCREENS.FIRST_SCREEN,
      fixedInterventions,
      notFixedInterventionCount: communication.appointment.interventions.length - fixedInterventions.length,
      fixedItems: FixedItems.filterFixedItems(communication.results),
      notFixedItems: NotFixedItems.filterNotFixedItems(communication.results),
      workingItems: WorkingItems.filterWorkingsItems(communication.results.filter(r => !r.mechanic_fixed)),
      notWorkedOnItems: NotWorkedOnItems.filterNotWorkedOnItems(communication.results),
      bill: billNote?.attachments[0],
    };
  }

  getCurrentScreen = () => {
    let { screen } = this.props;
    if (screen) {
      screen = parseInt(screen);
      if (screen === SCREENS.SUPPORT || (screen >= SCREENS.FIRST_SCREEN && screen <= SCREENS.LAST_SCREEN)) return screen;
    }

    return this.state.defaultScreen;
  };

  handleGoToScreen = currentScreen => {
    if (!currentScreen || currentScreen < SCREENS.FIRST_SCREEN || currentScreen > SCREENS.LAST_SCREEN) currentScreen = this.state.defaultScreen;

    this.props.history.push("/" + currentScreen + this.props.location.hash);
    window.scrollTo(0, 0);
  };

  handleGoToNextScreen = () => {
    const { fixedItems, notFixedItems, workingItems, notWorkedOnItems, fixedInterventions, notFixedInterventionCount } = this.state;
    const currentScreen = this.getCurrentScreen();
    let shift = 1;

    if (currentScreen + shift === SCREENS.FIXED_ITEMS && fixedItems.length + fixedInterventions.length < 1) shift++;

    if (currentScreen + shift === SCREENS.NOT_FIXED_ITEMS && notFixedItems.length + notFixedInterventionCount < 1) shift++;

    if (currentScreen + shift === SCREENS.OTHER_ITEMS && !workingItems.length && !notWorkedOnItems.length) shift++;

    this.handleGoToScreen(currentScreen + shift);
  };

  handleGoToPreviousScreen = () => {
    const { fixedItems, notFixedItems, workingItems, notWorkedOnItems, fixedInterventions, notFixedInterventionCount } = this.state;
    const currentScreen = this.getCurrentScreen();
    let shift = 1;

    if (currentScreen - shift === SCREENS.OTHER_ITEMS && !workingItems.length && !notWorkedOnItems.length) shift++;

    if (currentScreen - shift === SCREENS.NOT_FIXED_ITEMS && notFixedItems.length + notFixedInterventionCount < 1) shift++;

    if (currentScreen - shift === SCREENS.FIXED_ITEMS && fixedItems.length + fixedInterventions.length < 1) shift++;

    this.handleGoToScreen(currentScreen - shift);
  };

  handleGoToSupportScreen = () => {
    this.setState({ screenBeforeSupport: this.getCurrentScreen() });
    this.props.history.push("/" + SCREENS.SUPPORT + this.props.location.hash);
    window.scrollTo(0, 0);
  };

  handleGoBackFromSupportScreen = () => {
    this.handleGoToScreen(this.state.screenBeforeSupport);
  };

  renderPopups = () => {
    const { communication, settings } = this.props;

    return <PopupMessage type="welcome" communication={communication} settings={settings} />;
  };

  renderMainColumn = () => {
    const { fixedItems, notFixedItems, workingItems, notWorkedOnItems } = this.state;
    const { communication, settings, routeKey, t } = this.props;
    const title = t("cc_repair_overview").message || "Repair overview";
    const currentScreen = this.getCurrentScreen();

    return (
      <Responsive showMobile={currentScreen !== SCREENS.ORDER} className="mainContent">
        {currentScreen === SCREENS.SUPPORT && <Support settings={settings} />}
        {currentScreen !== SCREENS.SUPPORT && (
          <>
            <Header
              showFooter
              showPrintDownload
              showStatusLegend
              title={title}
              communication={communication}
              settings={settings}
              routeKey={routeKey}
              showMobile={currentScreen === SCREENS.INFO}
              correctEmail={communication.correct_email || getEmail(communication)}
              correctPhone={communication.correct_phone || getPhoneNumber(communication)}
            />
            <FixedItems fixedItems={fixedItems} settings={settings} communication={communication} showMobile={currentScreen === SCREENS.FIXED_ITEMS} />
            <NotFixedItems notFixedItems={notFixedItems} settings={settings} communication={communication} showMobile={currentScreen === SCREENS.NOT_FIXED_ITEMS} />
            <TiresOverview communication={communication} settings={settings} />
            <WorkingItems workingItems={workingItems} communication={communication} showMobile={currentScreen === SCREENS.OTHER_ITEMS} />
            <NotWorkedOnItems notWorkedOnItems={notWorkedOnItems} showMobile={currentScreen === SCREENS.OTHER_ITEMS} />
          </>
        )}
      </Responsive>
    );
  };

  renderOrderColumn = side => {
    const { layout } = this.props.settings;
    if ((layout === 1 && side === "right") || (layout === 2 && side === "left")) {
      const { fixedItems, fixedInterventions, bill } = this.state;
      const { settings, communication } = this.props;
      const currentScreen = this.getCurrentScreen();

      return (
        <Responsive showMobile={currentScreen === SCREENS.ORDER}>
          <SideMenu
            settings={settings}
            communication={communication}
            supportActive={currentScreen === SCREENS.SUPPORT}
            goToSupportScreen={this.handleGoToSupportScreen}
            goBackFromSupportScreen={this.handleGoBackFromSupportScreen}
          >
            <Order settings={settings} fixedItems={fixedItems} interventions={fixedInterventions} bill={bill} readOnly={true} />
          </SideMenu>
        </Responsive>
      );
    }

    return null;
  };

  renderNavigation = () => {
    const currentScreen = this.getCurrentScreen();
    return (
      <NavigationButtons
        SCREENS={SCREENS}
        onGoToPreviousScreen={this.handleGoToPreviousScreen}
        onGoToNextScreen={this.handleGoToNextScreen}
        currentScreen={currentScreen}
        backOnly={currentScreen === SCREENS.ORDER}
      />
    );
  };

  render() {
    const { settings, t } = this.props;

    return (
      <MainLayout
        mobileTitle={t("cc_repair_overview").message || "Repair overview"}
        color={settings.color}
        popups={this.renderPopups()}
        leftColumn={this.renderOrderColumn("left")}
        mainColumn={this.renderMainColumn()}
        rightColumn={this.renderOrderColumn("right")}
        navigation={this.renderNavigation()}
      />
    );
  }
}

export default withRouter(withTranslation()(RepairOverview));
