import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import SignatureCanvas from "react-signature-canvas";

import { VirtualKeyboard, LAYOUTS } from "../../../../../../components";

import "./CustomerSignature.scss";

class CustomerSignature extends Component {
  state = {
    canvasWidth: 0,
    canvasHeight: 0,
  };

  componentDidMount() {
    const d = document.querySelector(".signature-canvas");
    this.setState({
      canvasWidth: d?.offsetWidth || 740,
      canvasHeight: d?.offsetHeight || 400,
    });
  }

  render() {
    const { canvasHeight, canvasWidth } = this.state;
    const { kiosk, onChangeCustomerName, showCustomerSignatureError, showCustomerNameError, customerName, onCanvasRef, onUpdateCanvas, t } = this.props;

    return (
      <div className="OrderContainer OrderCustomerSignature">
        <div className="ContainerHeader">
          <FontAwesomeIcon icon={faPen} />
          <h4>{t("kiosk_signature").message || "Signature"}</h4>
        </div>
        <div className="OrderEditCustomerNameScreen">
          {kiosk.is_customer_name_in_kiosk_checkin_enabled && (
            <input
              id="OrderEditCustomerName"
              className={`OrderEditCustomerName ${showCustomerNameError ? "error" : ""}`}
              type="text"
              value={customerName}
              placeholder={t("kiosk_type_your_full_name").message || "Type your full name"}
              disabled={true}
            />
          )}
          {kiosk.is_customer_signature_in_kiosk_checkin_enabled && (
            <div className={`OrderEditCustomerSignature signature-canvas ${showCustomerSignatureError ? "error" : ""}`}>
              <span>{t("kiosk_signature").message || "Signature"}</span>

              <SignatureCanvas ref={ref => onCanvasRef(ref)} canvasProps={{ width: canvasWidth, height: canvasHeight }} onBegin={onUpdateCanvas} />
            </div>
          )}

          {kiosk.is_customer_name_in_kiosk_checkin_enabled && <VirtualKeyboard onChange={onChangeCustomerName} text={customerName} layout={LAYOUTS.FULL} />}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomerSignature);
