import React, { useState } from "react";
import { Grid, Modal, Image, Button, Icon, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { faCalendarAlt, faInfoCircle, faMapMarkedAlt, faPhone, faEnvelope } from "@fortawesome/pro-regular-svg-icons";

import "./index.scss";
import { WrappedApp } from "../../../../../layout/index.style";

const COMMUNICATION_CHANNELS = {
  EMAIL: 1,
  SMS: 2,
};

const PopUpMessage = ({
  type,
  communication,
  preferredCommChannel,
  settings,
  correctEmail,
  correctPhone,
  updateCorrectPhone,
  updateCorrectEmail,
  onSetPreferredCommChannel,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCorrectEmail, setIsEditingCorrectEmail] = useState(false);
  const [isEditingCorrectPhone, setIsEditingCorrectPhone] = useState(false);

  let mapLink = "";
  if (settings.location_street || settings.location_postal_code || settings.location_city)
    mapLink =
      "https://www.google.com/maps/search/?api=1&query=" +
      settings.location_street +
      ", " +
      settings.location_postal_code +
      ", " +
      settings.location_city +
      ", " +
      settings.location_country;

  const handleSaveEditing = () => {
    setIsEditing(false);
    setIsEditingCorrectEmail(false);
    setIsEditingCorrectPhone(false);
  };

  const renderHeader = () => {
    let welcomeMsg = t("cc_Welcome").message || "Welcome";
    if (communication?.customer?.title && communication.customer.surname) {
      welcomeMsg += ` ${communication.customer.title}`;
      if (communication.customer.firstname) welcomeMsg += ` ${communication.customer.firstname}`;

      welcomeMsg += ` ${communication.customer.surname}`;
    }

    const { logo } = settings;
    return (
      <div className="checkin-modal-header-container">
        <div className="checkin-modal-header-logo-container">
          <Image size="small" src={logo} />
        </div>
        <h1 className="checkin-modal-header-title color">{welcomeMsg},</h1>
        {settings.check_in_welcome_text && <div className="checkin-modal-welcome-text">{settings.check_in_welcome_text}</div>}
        <p className="checkin-modal-header-text">{`${t("cc_continue_or_change").message || "Continue or change your"}`}</p>
        <div className="checkin-modal-header-email-phone-container">
          <span className="checkin-modal-header-info-text">{`${t("cc_e_mail_address").message || "e-mail address"}`}</span>
          <span> {t("cc_and").message || "and"} </span>
          <span className="checkin-modal-header-info-text">{`${t("cc_phone_number").message || "phone number"}`}</span>
        </div>
      </div>
    );
  };

  const renderContactInfo = () => {
    return (
      <div className="checkin-modal-contact-info-container">
        {isEditing && (
          <div className="checkin-modal-contact-info-edit">
            <div className="input-field-container">
              <label>{t("cc_email").message || "Email"}</label>
              <Input type="text" defaultValue={correctEmail} onChange={evt => updateCorrectEmail(evt.target.value)} name="correctEmail" />
            </div>
            <div className="input-field-container">
              <label>{t("cc_Phone_number").message || "Phone Number"}</label>
              <Input type="text" defaultValue={correctPhone} onChange={evt => updateCorrectPhone(evt.target.value)} name="correctPhone" />
            </div>
          </div>
        )}

        {!isEditing && (
          <div className="checkin-modal-contact-info" style={{ paddingLeft: "3em" }}>
            <Icon name="envelope" style={{ fontSize: "18px", marginRight: "0.5em" }} />
            <span className="checkin-modal-contact-text">{correctEmail || ""}</span>
            <br />
            <Icon name="phone" style={{ fontSize: "18px", marginRight: "0.5em" }} />
            <span className="checkin-modal-contact-text">{correctPhone || ""}</span>
          </div>
        )}
      </div>
    );
  };

  const renderContactInfoWithPreferredCommChannel = () => {
    return (
      <div className="checkin-modal-contact-info-container">
        <div className="preferred-channel-title">{t("preferred_channel").message || "Preferred Channel"}</div>

        {isEditing && (
          <div className="checkin-modal-contact-info-edit">
            <div className="input-field-container">
              <label>{t("cc_email").message || "Email"}</label>
              <Input type="text" defaultValue={correctEmail} onChange={evt => updateCorrectEmail(evt.target.value)} name="correctEmail" />
            </div>

            <div className="input-field-container">
              <label>{t("cc_Phone_number").message || "Phone Number"}</label>
              <Input type="text" defaultValue={correctPhone} onChange={evt => updateCorrectPhone(evt.target.value)} name="correctPhone" />
            </div>
          </div>
        )}

        {!isEditing && (
          <div className="preferred-channel-buttons-container">
            {!isEditingCorrectEmail ? (
              <button
                className={`preferred-channel-button ${preferredCommChannel === COMMUNICATION_CHANNELS.EMAIL ? "active-preferred-channel" : ""}`}
                onClick={!correctEmail ? () => setIsEditingCorrectEmail(true) : () => onSetPreferredCommChannel(COMMUNICATION_CHANNELS.EMAIL)}
              >
                <Icon name="mail" /> <span>{correctEmail || "Email"}</span>
              </button>
            ) : (
              <div className="input-field-container">
                <label>{t("cc_email").message || "Email"}</label>
                <Input type="text" defaultValue={correctEmail} onChange={evt => updateCorrectEmail(evt.target.value)} name="correctEmail" />
              </div>
            )}

            {!isEditingCorrectPhone ? (
              <button
                className={`preferred-channel-button ${preferredCommChannel === COMMUNICATION_CHANNELS.SMS ? "active-preferred-channel" : ""}`}
                onClick={!correctPhone ? () => setIsEditingCorrectPhone(true) : () => onSetPreferredCommChannel(COMMUNICATION_CHANNELS.SMS)}
              >
                <Icon name="mobile alternate" /> <span>{correctPhone || "SMS"}</span>
              </button>
            ) : (
              <div className="input-field-container">
                <label>{t("cc_Phone_number").message || "Phone Number"}</label>
                <Input type="text" defaultValue={correctPhone} onChange={evt => updateCorrectPhone(evt.target.value)} name="correctPhone" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderThankYouNote = () => {
    return (
      <div className="checkin-modal-thanks-container">
        <span className="checkin-modal-thanks-text">{t("cc_thanks").message || "Thanks"},</span>
        <br />
        {settings.dealer_name && <i className="checkin-modal-thanks-text">{settings.dealer_name}.</i>}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="checkin-modal-buttons-container">
        {!isEditing && !isEditingCorrectEmail && !isEditingCorrectPhone && (
          <Button basic className="popup-btn color box-shadow-color" onClick={() => setIsEditing(true)}>
            {t("cc_edit").message || "Edit"}
          </Button>
        )}
        {(isEditing || isEditingCorrectEmail || isEditingCorrectPhone) && (
          <Button basic className="popup-btn color box-shadow-color" onClick={handleSaveEditing}>
            {t("cc_save").message || "Save"}
          </Button>
        )}
        <Button
          disabled={isEditing || isEditingCorrectEmail || isEditingCorrectPhone}
          className="continue-btn bg-color hover-bg-color focus-bg-color"
          onClick={() => setModalOpen(false)}
        >
          {t("cc_continue").message || "Continue"}
        </Button>
      </div>
    );
  };

  const renderAppointmentInformation = () => {
    const { appointment } = communication;

    return (
      <Grid.Column computer={16}>
        <div className="online-checkin-info-container">
          <h4 className="online-checkin-info-title">{`${t("cc_your_appointment_info").message || "Your appointment information"}:`}</h4>
          <div className="online-checkin-info-content-container">
            <div className="online-checkin-info-text">
              <FontAwesomeIcon icon={faCalendarAlt} style={{ color: settings.color }} />
              <span className="online-checkin-info-text-data">
                {appointment.due_in
                  ? moment(appointment.due_in)
                      .format("DD-MM-YYYY HH:mm")
                      .replace(" 00:00", "")
                  : moment(appointment.time_car_app).format("DD-MM-YYYY")}
              </span>
            </div>
            <div className="online-checkin-info-text">
              <FontAwesomeIcon icon={faInfoCircle} style={{ color: settings.color }} />
              <span className="online-checkin-info-text-data">{appointment.wo_nr}</span>
            </div>
            <div className="online-checkin-info-text">
              <FontAwesomeIcon icon={faMapMarkedAlt} style={{ color: settings.color }} />
              <span className="online-checkin-info-text-data">
                {settings.dealer_name}, {settings.location_name}
              </span>
            </div>
            {mapLink && (
              <div className="online-checkin-info-text">
                <a href={mapLink} rel="noopener noreferrer" target="_blank">
                  <img alt="Google maps" src="https://developers.google.com/maps/images/maps-icon.svg" />
                  Google maps
                </a>
              </div>
            )}
          </div>
        </div>
      </Grid.Column>
    );
  };

  const renderContactUsSection = () => (
    <div className="online-checkin-contact-us-section-box">
      <div className="OnlineCheckinContactUsSection" style={{ marginTop: "10px" }}>
        <span>{t("cc_CONTACT US").message || "CONTACT US"}:</span>
        <span style={{ marginLeft: "15px" }}>
          <FontAwesomeIcon icon={faPhone} /> {settings.phone_number}
        </span>
        <span style={{ marginLeft: "15px" }}>
          <FontAwesomeIcon icon={faEnvelope} /> {settings.email}
        </span>
      </div>
    </div>
  );

  const renderClosedMessage = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <div className="online-checkin-icon-container">
            <FontAwesomeIcon icon={faCheckCircle} className="online-checkin-icon" style={{ color: settings.color ? settings.color : "#1CC15E" }} />
          </div>
          <div>
            <h2 className="online-checkin-title">{t("online_check_in_closed_title").message || "The online check-in is now closed."}</h2>
            <p className="online-checkin-text">
              {t("online_check_in_closed_subtitle").message || "If you have any questions, please contact us using the contact information below."}
            </p>
          </div>
        </Grid.Column>
        {renderAppointmentInformation()}

        <Grid.Column width={16}>{renderContactUsSection()}</Grid.Column>
      </Grid.Row>
    </Grid>
  );

  const renderCustomerContact = () => (
    <>
      {renderHeader()}
      {settings.has_multiple_communication_channels ? renderContactInfoWithPreferredCommChannel() : renderContactInfo()}
      {renderThankYouNote()}
      {renderButtons()}
    </>
  );

  return (
    <Modal className="direct-checkin-pop-up-modal" dimmer closeOnDimmerClick={false} open={type === "closed" ? true : modalOpen}>
      <Modal.Content className="modal-content">
        <WrappedApp color={settings.color}>{type === "closed" ? renderClosedMessage() : renderCustomerContact()}</WrappedApp>
      </Modal.Content>
    </Modal>
  );
};

export default PopUpMessage;
