import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCamera } from "@fortawesome/pro-regular-svg-icons";

import "./index.scss";

const ImageLetters = ({ images, style, lettersMap }) => {
  if (!Array.isArray(images) || images.length === 0) return null;

  if (!Array.isArray(lettersMap) || !Object.keys(lettersMap).length) return null;
  return (
    <div style={style} className="image-video-letter">
      <FontAwesomeIcon icon={faCamera} className="image-video-icon" />
      <span>
        {images
          .map(i => lettersMap[i.url])
          .filter(f => f !== undefined)
          .join(",")}
      </span>
    </div>
  );
};

export default ImageLetters;
