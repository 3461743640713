import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { Modal } from "semantic-ui-react";

const SelectLabelErrorModal = ({ t, open, onClose }) => {
  return (
    <Modal className="KioskLabelErrorModal" open={open} onClose={onClose} closeIcon>
      <Modal.Content className="KioskLabelErrorModal__content">
        <h1>
          <FontAwesomeIcon icon={faExclamationCircle} color="red" />
          {t("kiosk_try_again").message || "Try again!"}
        </h1>

        <p>{t("kiosk_please_select_your_label").message || "Please select your keylabel before you continue."}</p>
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation()(SelectLabelErrorModal);
