export const SCREENS = {
  FIRST_SCREEN: 1,
  INFO: 1,
  SCHEDULED_SERVICES: 2,
  CRITICAL_ITEMS: 3,
  ADVISED_ITEMS: 4,
  OTHER_ITEMS: 5,
  REMARKS: 6,
  ORDER: 7,
  THANK_YOU: 8,
  LAST_SCREEN: 8,
  SUPPORT: 9,
};
