import React from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarWash } from "@fortawesome/pro-regular-svg-icons";

import { round2 } from "../../../../../../util/common";

const OptionalServices = ({ communication, kiosk, t }) => {
  const optional_services = communication.check_in_results;
  const { include_vat, vat } = kiosk;

  if (!optional_services?.length) return null;

  return (
    <Grid className="OrderContainer">
      <Grid.Row className="ContainerHeader">
        <FontAwesomeIcon icon={faCarWash} className="icon" />
        <h4>{t("cc_additional_services").message || "Additional services"}</h4>
      </Grid.Row>
      <Grid.Row className="ContainerHeading">
        <Grid.Column width={14}>
          <h3 className="title">{t("cc_SERVICE_NAME").message || "SERVICE NAME"}</h3>
        </Grid.Column>
        <Grid.Column width={2}>
          <h3 className="title">{t("cc_PRICE").message || "PRICE"}</h3>
        </Grid.Column>
      </Grid.Row>
      {optional_services.map(service => (
        <Grid.Row key={service.id} className="ContainerItem">
          <Grid.Column width={14}>
            <span className="name">{service.name}</span>
          </Grid.Column>
          <Grid.Column width={2}>
            {service.price > 0.01 && (
              <>
                {include_vat && <span className="price">{`€ ${(round2(service.price) * (1 + vat / 100)).toFixed(2)}`}</span>}
                {!include_vat && <span className="price">{`€ ${service.price.toFixed(2, 10)}`}</span>}
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

export default withTranslation()(OptionalServices);
