import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Modal } from "semantic-ui-react";

import { CUSTOMER_COMMUNICATION_STATUSES } from "../../../util/communication_statuses";
import { MainLayout, Responsive } from "../../../layout";
import { getPhoneNumber, getEmail } from "../../../util/common";

import { SuccessMsg, ScheduledServices, Header, Remarks, NavigationButtons, Order, SideMenu, Support } from "../components";

import { SCREENS } from "./util/screens";
import { PopUpMessage, OptionalServices } from "./components";

import Service from "./service";

class OnlineCheckIn extends Component {
  constructor(props) {
    super(props);

    const { communication } = props;

    let optionalServices = communication.check_in_results;
    optionalServices.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));

    const agreements = communication.agreements.filter(a => a.visible_online_check_in);
    let remarks = communication.check_in_remarks;
    remarks.forEach(r => {
      if (!Array.isArray(r.attachments)) r.attachments = [];

      r.attachments &&
        r.attachments.forEach(a => {
          a.name = a.name.substr(-9);
        });
    });

    if (communication.status < CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_ANSWERED && this.props.settings.checkin_remarks_enabled)
      remarks.push({ title: "", description: "", attachments: [] });

    this.state = {
      defaultScreen: communication.status !== CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_ANSWERED ? SCREENS.FIRST_SCREEN : SCREENS.LAST_SCREEN,
      originalCorrectEmail: communication.correct_email || getEmail(communication),
      correctEmail: communication.correct_email || getEmail(communication),
      originalCorrectPhone: communication.correct_phone || getPhoneNumber(communication),
      correctPhone: communication.correct_phone || getPhoneNumber(communication),
      communication_status: communication.status,
      customerName: "",
      optionalServices,
      remarks,
      approvedMandatoryAgreements: agreements.length === 0 || !agreements.some(a => !a.optional_online_check_in && !a.accepted),
      agreements,
      readOnly: communication.status >= CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_ANSWERED || communication.is_receptionist,
      showAgreementError: false,
      showCustomerNameRequiredError: false,
      keylocker_pin: null,
      preferredCommChannel: props.settings.has_multiple_communication_channels ? communication.customer.preferred_communication_channel : null,
      is_acses_available: false,
      dropInAcsesLocker: false,
      acses_pin: null,
      acses_locker_name: "",
      isLastOptionalServiceNotVisible: false,
      showMaintenanceModal: false,
    };

    this.OptionalItemsObserver = null;
  }

  componentDidMount() {
    this.isAcsesAvailable();
  }

  componentWillUnmount() {
    this.disconnectOptionalItemsObserver();
  }

  componentDidUpdate() {
    this.setUpOptionalItemsObserver();
  }

  connectOptionalItemsObserver = () => {
    const lastOptService = document.querySelector(".optional-services-item:last-child");

    if (lastOptService) {
      const IntersectionObserverOptions = {
        root: null,
        rootMargin: "0px 0px -80px 0px",
        threshold: 1.0,
      };
      const callback = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) this.setState({ isLastOptionalServiceNotVisible: false });
          else this.setState({ isLastOptionalServiceNotVisible: true });
        });
      };
      this.OptionalItemsObserver = new IntersectionObserver(callback, IntersectionObserverOptions);
      this.OptionalItemsObserver.observe(lastOptService);
    }
  };

  disconnectOptionalItemsObserver = () => {
    if (this.OptionalItemsObserver) {
      this.OptionalItemsObserver.disconnect();
      this.OptionalItemsObserver = null;
    }
  };

  setUpOptionalItemsObserver = () => {
    if (this.getCurrentScreen() === SCREENS.OPTIONAL_SERVICES && !this.OptionalItemsObserver) this.connectOptionalItemsObserver();
    else if (this.getCurrentScreen() !== SCREENS.OPTIONAL_SERVICES && this.OptionalItemsObserver) this.disconnectOptionalItemsObserver();
  };

  getCurrentScreen = () => {
    let { screen } = this.props;
    if (screen) {
      screen = parseInt(screen);
      if (screen === SCREENS.SUPPORT || (screen >= SCREENS.FIRST_SCREEN && screen <= SCREENS.LAST_SCREEN)) {
        return screen;
      }
    }

    return this.state.defaultScreen;
  };

  handleGoToScreen = currentScreen => {
    if (!currentScreen || currentScreen < SCREENS.FIRST_SCREEN || currentScreen > SCREENS.LAST_SCREEN) currentScreen = this.state.defaultScreen;

    if (currentScreen === SCREENS.LAST_SCREEN) {
      this.props.history.replace("", null);
    }

    if (currentScreen !== SCREENS.OPTIONAL_SERVICES) {
      this.setState({ isLastOptionalServiceNotVisible: false });
    }

    this.props.history.push("/" + currentScreen + this.props.location.hash);
    window.scrollTo(0, 0);
  };

  isAcsesAvailable = async () => {
    if (!this.props.settings.is_acses_enabled || this.state.readOnly) return;

    try {
      const response = await Service.isAcsesAvailable({ key: this.props.routeKey });

      if (response?.data?.data) this.setState({ is_acses_available: response.data.data.available });
    } catch (error) {
      console.log(error);
    }
  };

  handleDropInAcsesChange = (_e, { checked }) => {
    this.setState({ dropInAcsesLocker: checked });
  };

  handleGoToNextScreen = () => {
    const { communication, settings } = this.props;
    const { remarks, optionalServices } = this.state;
    const currentScreen = this.getCurrentScreen();
    let shift = 1;

    if (currentScreen + shift === SCREENS.SCHEDULED_SERVICES && !communication.appointment.interventions.length) shift++;

    if (currentScreen + shift === SCREENS.OPTIONAL_SERVICES && !optionalServices.length) shift++;

    if (currentScreen + shift === SCREENS.REMARKS && (!settings.checkin_remarks_enabled || !remarks.length)) shift++;

    this.handleGoToScreen(currentScreen + shift);
  };

  handleGoToPreviousScreen = () => {
    const { communication, settings } = this.props;
    const { remarks, optionalServices } = this.state;
    const currentScreen = this.getCurrentScreen();
    let shift = 1;

    if (currentScreen - shift === SCREENS.REMARKS && (!settings.checkin_remarks_enabled || !remarks.length)) shift++;

    if (currentScreen - shift === SCREENS.OPTIONAL_SERVICES && !optionalServices.length) shift++;

    if (currentScreen - shift === SCREENS.SCHEDULED_SERVICES && !communication.appointment.interventions.length) shift++;

    this.handleGoToScreen(currentScreen - shift);
  };

  handleGoToSuccessScreen = ({ keylocker_pin, acses_pin, acses_locker_name }) => {
    let newState = { communication_status: CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_ANSWERED, defaultScreen: SCREENS.LAST_SCREEN, readOnly: true };

    if (keylocker_pin) newState.keylocker_pin = keylocker_pin;
    if (acses_pin) newState = { ...newState, acses_pin, acses_locker_name };

    this.setState(newState, () => this.handleGoToScreen(SCREENS.THANK_YOU));
  };

  handleGoToSupportScreen = () => {
    this.setState({ screenBeforeSupport: this.getCurrentScreen() });
    this.props.history.push("/" + SCREENS.SUPPORT + this.props.location.hash);
    window.scrollTo(0, 0);
  };

  handleGoBackFromSupportScreen = () => {
    this.handleGoToScreen(this.state.screenBeforeSupport);
  };

  updateCorrectPhone = correctPhone => {
    this.setState({ correctPhone });
  };

  updateCorrectEmail = correctEmail => {
    this.setState({ correctEmail });
  };

  addNewRemark = () => {
    let { remarks } = this.state;

    remarks = remarks.filter(r => r.title || r.description || r.attachments.length > 0);
    remarks.push({ title: "", description: "", attachments: [] });

    this.setState({ remarks });
  };

  updateRemarks = remarks => {
    this.setState({ remarks });
  };

  updateOptionalServices = optionalServices => {
    this.setState({ optionalServices });
  };

  handleAgreed = id => {
    let { agreements } = this.state;
    let agreement = agreements.find(a => a.id === id);
    if (agreement) {
      agreement.accepted = !agreement.accepted;
      this.setState({ agreements: [...agreements], approvedMandatoryAgreements: !agreements.some(a => !a.optional_online_check_in && !a.accepted) });
    }
  };

  handleCustomerName = customerName => {
    this.setState({ customerName });
  };

  handleShowCustomerNameRequiredError = showCustomerNameRequiredError => {
    this.setState({ showCustomerNameRequiredError });
  };

  handleSetPreferredCommChannel = preferredCommChannel => {
    this.setState({ preferredCommChannel });
  };

  handleSendAnswer = () => {
    let {
      remarks,
      originalCorrectPhone,
      originalCorrectEmail,
      correctEmail,
      correctPhone,
      customerName,
      preferredCommChannel,
      optionalServices,
      agreements,
      dropInAcsesLocker,
    } = this.state;

    const { routeKey, communication } = this.props;

    let filledRemarks = remarks.filter(r => r.title || r.description || r.attachments.length > 0);
    let email = correctEmail === originalCorrectEmail ? "" : correctEmail;
    let phone = correctPhone === originalCorrectPhone ? "" : correctPhone;

    return Service.sendAnswer({
      key: routeKey,
      results: optionalServices,
      remarks: filledRemarks,
      agreement_ids: agreements.filter(a => a.accepted).map(a => a.id),
      correct_phone: phone,
      correct_email: email,
      customer_name: customerName,
      preferred_communication_channel: preferredCommChannel,
      drop_in_acses_locker: dropInAcsesLocker,
      intervention_ids: communication.appointment.interventions?.map(i => i.id),
    });
  };

  displayMaintenanceModal = () => this.setState({ showMaintenanceModal: true });

  renderPopups = () => {
    const { correctEmail, correctPhone, preferredCommChannel, communication_status, showMaintenanceModal } = this.state;
    const { communication, settings, t } = this.props;

    if (showMaintenanceModal)
      return (
        <Modal size="small" open={showMaintenanceModal} closeOnDimmerClick={false}>
          <Modal.Content>
            <div style={{ textAlign: "center", fontSize: "18px" }}>
              {t("cc_maintenance_message").message || "Sorry, we're down for scheduled maintenance, please try again later"}
            </div>
          </Modal.Content>
        </Modal>
      );

    return (
      <PopUpMessage
        type={communication_status === CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_CLOSED ? "closed" : "customer_contact"}
        communication={communication}
        settings={settings}
        correctEmail={correctEmail}
        correctPhone={correctPhone}
        preferredCommChannel={preferredCommChannel}
        updateCorrectPhone={this.updateCorrectPhone}
        updateCorrectEmail={this.updateCorrectEmail}
        onSetPreferredCommChannel={this.handleSetPreferredCommChannel}
      />
    );
  };

  renderMainColumn = () => {
    const { remarks, optionalServices, correctEmail, correctPhone, readOnly, keylocker_pin, acses_pin, acses_locker_name } = this.state;
    const { communication, settings, routeKey, t } = this.props;
    const title = t("cc_online_checkin").message || "Online check in";
    const currentScreen = this.getCurrentScreen();

    return (
      <Responsive showMobile={currentScreen !== SCREENS.ORDER} className="mainContent">
        {currentScreen === SCREENS.SUPPORT && <Support settings={settings} />}
        {currentScreen !== SCREENS.SUPPORT && (
          <>
            <Header
              showActionLegend
              title={title}
              communication={communication}
              settings={settings}
              showMobile={currentScreen === SCREENS.INFO}
              correctEmail={correctEmail}
              correctPhone={correctPhone}
            />
            <ScheduledServices communication={communication} settings={settings} showMobile={currentScreen === SCREENS.SCHEDULED_SERVICES} />
            <OptionalServices
              readOnly={readOnly}
              settings={settings}
              showMobile={currentScreen === SCREENS.OPTIONAL_SERVICES}
              optionalServices={optionalServices}
              updateOptionalServices={this.updateOptionalServices}
            />
            {settings.checkin_remarks_enabled && (
              <Remarks
                uploadImages={Service.uploadImages}
                addMore
                readOnly={readOnly}
                routeKey={routeKey}
                showMobile={currentScreen === SCREENS.REMARKS}
                remarks={remarks}
                addNewRemark={this.addNewRemark}
                updateRemarks={this.updateRemarks}
              />
            )}
            <SuccessMsg
              communication={communication}
              settings={settings}
              keylocker_pin={keylocker_pin}
              acses_pin={acses_pin}
              acses_locker_name={acses_locker_name}
              isOpen={currentScreen === SCREENS.THANK_YOU}
              onClose={() => this.handleGoToScreen(SCREENS.INFO)}
            />
          </>
        )}
      </Responsive>
    );
  };

  handleShowAgreementError = showAgreementError => {
    this.setState({ showAgreementError });
  };

  renderOrderColumn = side => {
    const { layout } = this.props.settings;
    if ((layout === 1 && side === "right") || (layout === 2 && side === "left")) {
      const {
        optionalServices,
        remarks,
        readOnly,
        agreements,
        approvedMandatoryAgreements,
        showAgreementError,
        customerName,
        showCustomerNameRequiredError,
        dropInAcsesLocker,
        is_acses_available,
      } = this.state;
      const { communication, settings } = this.props;
      const currentScreen = this.getCurrentScreen();

      return (
        <Responsive showMobile={currentScreen === SCREENS.ORDER}>
          <SideMenu
            communication={communication}
            settings={settings}
            supportActive={currentScreen === SCREENS.SUPPORT}
            goToSupportScreen={this.handleGoToSupportScreen}
            goBackFromSupportScreen={this.handleGoBackFromSupportScreen}
          >
            <Order
              readOnly={readOnly}
              interventions={communication.appointment.interventions}
              settings={settings}
              showMobile={true}
              optionalServices={optionalServices}
              remarks={remarks}
              agreements={agreements}
              approved={approvedMandatoryAgreements}
              onAgreed={this.handleAgreed}
              onSendAnswer={this.handleSendAnswer}
              onGoToSuccessScreen={this.handleGoToSuccessScreen}
              showAgreementError={showAgreementError}
              onShowAgreementError={this.handleShowAgreementError}
              allowedToOrder={() => true}
              onCustomerNameChange={this.handleCustomerName}
              customerName={customerName}
              customerNameVisible={settings.online_check_in_name_visible}
              onShowCustomerNameRequiredError={this.handleShowCustomerNameRequiredError}
              showCustomerNameRequiredError={showCustomerNameRequiredError}
              onDropInAcsesChange={this.handleDropInAcsesChange}
              dropInAcsesLocker={dropInAcsesLocker}
              acsesIsAvailable={is_acses_available}
              showMaintenanceModal={this.displayMaintenanceModal}
            />
          </SideMenu>
        </Responsive>
      );
    }

    return null;
  };

  renderNavigation = () => {
    const { communication_status, approvedMandatoryAgreements, showAgreementError, customerName, isLastOptionalServiceNotVisible } = this.state;
    const { settings, t } = this.props;

    return (
      <NavigationButtons
        SCREENS={SCREENS}
        answered={communication_status >= CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_ANSWERED}
        answerLabel={t("cc_CHECK_IN").message || "ONLINE CHECK IN"}
        approved={approvedMandatoryAgreements}
        currentScreen={this.getCurrentScreen()}
        settings={settings}
        onGoToPreviousScreen={this.handleGoToPreviousScreen}
        onGoToNextScreen={this.handleGoToNextScreen}
        onSendAnswer={this.handleSendAnswer}
        onGoToSuccessScreen={this.handleGoToSuccessScreen}
        canOrder={true}
        showAgreementError={showAgreementError}
        onShowAgreementError={this.handleShowAgreementError}
        customerName={customerName}
        customerNameVisible={settings.online_check_in_name_visible}
        isLastOptionalServiceNotVisible={isLastOptionalServiceNotVisible}
        onShowCustomerNameRequiredError={this.handleShowCustomerNameRequiredError}
        showMaintenanceModal={this.displayMaintenanceModal}
      />
    );
  };

  render() {
    const { settings, t } = this.props;

    return (
      <>
        <MainLayout
          mobileTitle={t("cc_online_checkin").message || "Online check in"}
          color={settings.color}
          popups={this.renderPopups()}
          leftColumn={this.renderOrderColumn("left")}
          mainColumn={this.renderMainColumn()}
          rightColumn={this.renderOrderColumn("right")}
          navigation={this.renderNavigation()}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(OnlineCheckIn));
