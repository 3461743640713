import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

import { ConditionIcon, GalleryButton, Expandable } from "../../../../../../components";
import { getItemName, QUESTION_RESULT_STATUS } from "../../../../../../util/common";

import "./index.scss";

class WorkingItems extends Component {
  render() {
    let { workingItems, t } = this.props;

    if (!workingItems?.length) return null;

    return (
      <Grid className="desk-working-items-container component-container">
        <Grid.Row computer={16} className="working-items-heading">
          <FontAwesomeIcon icon={faCheckCircle} className="working-items-heading-icon" />
          <h4 className="working-items-heading-title">{t("cc_checked_working_properly").message || "Checked items working properly"}</h4>
        </Grid.Row>

        <Grid className="working-items-with-remarks" verticalAlign="middle">
          {workingItems.map((result, index) => (
            <Grid.Row key={result.question_result_id * 10 + result.tyre_position} className="working-items-row">
              <Grid.Column width={16} className="working-items-title-container">
                <ConditionIcon status={0} />
                <span className="working-items-title">{<Expandable text={getItemName(this.props.t, result)} />}</span>
              </Grid.Column>
              {(result.tyre_profile || result.mechanic_notes) && (
                <Grid.Column width={16} className="working-items-remarks">
                  {result.tyre_profile && <Expandable text={result.tyre_profile + "mm"} />}
                  {result.mechanic_notes && <Expandable className="desk-checkin-expandable" text={result.mechanic_notes} />}
                </Grid.Column>
              )}
              <Grid.Column width={16} className="working-items-images">
                <GalleryButton images={result.images} videos={result.video} />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Grid>
    );
  }
}

const TranslatedWorkingItems = withTranslation()(WorkingItems);

TranslatedWorkingItems.filterWorkingsItems = results => results.filter(r => r.question_status < QUESTION_RESULT_STATUS.ADVISED);

export default TranslatedWorkingItems;
