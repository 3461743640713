import React from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";

const Remarks = ({ additionalRemark, t }) => {
  if (!additionalRemark) return null;

  return (
    <div className="OrderContainer OrderRemarks">
      <div className="ContainerHeader">
        <FontAwesomeIcon icon={faPen} />
        <h4>{t("kl_additional_remarks").message || "Additional remarks"}</h4>
      </div>
      <div className="ContainerText" dangerouslySetInnerHTML={{ __html: additionalRemark.replace(/\n/g, "<br />") }} />
    </div>
  );
};

export default withTranslation()(Remarks);
