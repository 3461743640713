import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { ImageLetters } from "../../components";
import { getTyreSeasonIcon, TYRE_POSITION } from "../../../../util/tyre";
import { getItemName, round2 } from "../../../../util/common";

import "./index.scss";

class FixedCheckItems extends Component {
  renderRow = (item, id, description, price, images) => {
    let rep = null;
    if (TYRE_POSITION.IS_TYRE(item.tyre_position) && item.tyre_replacements.length > 0) rep = item.tyre_replacements.find(r => r.mechanic_fixed);

    return (
      <Grid.Row key={id} className={"fixed-checks-row"}>
        <Grid.Column width={14}>
          {images && images.length > 0 && (
            <>
              <ImageLetters images={images} lettersMap={this.props.lettersMap} />
              &nbsp;&nbsp;
            </>
          )}
          {item.title && <span className="fixed-checks-title">{getItemName(this.props.t, item)}</span>}
          {TYRE_POSITION.IS_TYRE(item.tyre_position) && (
            <>
              {getTyreSeasonIcon(item.tyre.season, "#578091")}
              <span className="fixed-checks-tyre-manufacturer">{item.tyre.manufacturer}</span>
              <span className="fixed-checks-tyre-description">{item.tyre.description}</span>
              <span className="fixed-checks-profile">{item.tyre_profile}</span>
            </>
          )}

          {description && (
            <div className="fixed-checks-profile-desc-container">
              <span className="fixed-checks-description">{description}</span>
            </div>
          )}
        </Grid.Column>
        <Grid.Column width={2}>{!rep && price}</Grid.Column>

        {rep && (
          <>
            <Grid.Column width={14} className="fixed-checks-replaced-with-tyre-container">
              <span className="fixed-checks-replaced-with-tyre">{this.props.t("Replacement").message || "Replacement"}</span>
              {getTyreSeasonIcon(rep.tyre.season, "#578091")}
              <span className="fixed-checks-replaced-with-replacement">
                {rep.tyre.manufacturer} {rep.tyre.description}
              </span>
              {rep.remark && <div className="tyre-remark">{rep.remark}</div>}
            </Grid.Column>

            <Grid.Column textAlign="right" width={2}>
              {this.renderPrice(rep.price)}
            </Grid.Column>
          </>
        )}
      </Grid.Row>
    );
  };

  renderPrice = price => {
    const { include_vat, vat } = this.props.settings;

    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="fixed-checks-price">€ {round2(price).toFixed(2)}</span>;
  };

  renderInterventions = interventions => {
    if (!Array.isArray(interventions)) return null;

    const { appointment } = this.props.communication;
    const hasImages = Array.isArray(appointment.final_check_images);

    return interventions.map(i =>
      this.renderRow(
        i,
        i.id,
        i.description,
        this.renderPrice(i.price),
        hasImages && appointment.final_check_images.filter(img => img.intervention_index === i.intervention_index)
      )
    );
  };

  renderFixedItems = fixedItems => {
    if (!Array.isArray(fixedItems)) return null;

    return fixedItems
      .filter(nfi => nfi.question_status > -1)
      .map(nfi => this.renderRow(nfi, nfi.question_result_id + "_" + nfi.tyre_position, nfi.mechanic_notes, this.renderPrice(nfi.price, false), nfi.images));
  };

  render() {
    let { communication, fixedItems, t } = this.props;
    let appointment = communication.appointment;
    let interventions = appointment.interventions.filter(i => i.mechanic_fixed);

    if (interventions.length < 1 && fixedItems.length < 1) return null;

    return (
      <Grid className="report-fixed-checks-container component-checks-container component-container">
        <Grid.Row className="fixed-checks-heading">
          <Grid.Column width={16}>
            <div className="fixed-checks-heading-title-container">
              <FontAwesomeIcon icon={faCog} className="fixed-checks-heading-icon" />
              <h2 className="fixed-checks-heading-title">{t("cc_items_fixed").message || "Items fixed"}</h2>
            </div>
          </Grid.Column>
          <Grid.Column width={16}>
            <div className="fixed-checks-heading-subtitle-container">
              <h3>{t("cc_fixed_checks_heading_explainer").message || "The items below have been carried out."}</h3>
            </div>
          </Grid.Column>
        </Grid.Row>

        {this.renderInterventions(interventions)}
        {this.renderFixedItems(fixedItems)}
      </Grid>
    );
  }
}

const TranslatedFixedCheckItems = withTranslation()(FixedCheckItems);

TranslatedFixedCheckItems.filterFixedItems = results => {
  return results.filter(r => {
    if (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.length > 0) {
      let rep = r.tyre_replacements.find(tr => tr.mechanic_fixed);
      if (!rep) return false;

      r.price = rep.price;
      return true;
    } else {
      return r.mechanic_fixed;
    }
  });
};

export default TranslatedFixedCheckItems;
