import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import "./index.scss";

class Help extends Component {
  render() {
    const { className, t } = this.props;

    return (
      <div className="HelpScreen">
        <div className="HelpTitle KioskTitle">
          <Icon name="question" circular />
          {t("kiosk_help").message || "Help"}
        </div>
        <div className={`HelpContent ${className}`}>{this.props.children}</div>
      </div>
    );
  }
}

export default withTranslation()(Help);
