import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

import { ImageLetters } from "../";
import { TYRE_POSITION } from "../../../../util/tyre";
import { ConditionIcon } from "../../../../components/";
import { getItemName, round2 } from "../../../../util/common";

import "./index.scss";

class FixedItems extends Component {
  renderRow = (item, id, description, price, images, customer_ok, status) => {
    const { hideStatusIcons } = this.props;

    let rep = null;
    if (TYRE_POSITION.IS_TYRE(item.tyre_position) && item.tyre_replacements.length > 0) rep = item.tyre_replacements.find(r => r.mechanic_fixed);

    return (
      <>
        <Grid.Row key={id} className={"fixed-row"}>
          <Grid.Column width={hideStatusIcons ? 12 : 8} className="fixed-title">
            {item.title && <span>{getItemName(this.props.t, item)}</span>}
          </Grid.Column>

          <Grid.Column width={2}>
            <ImageLetters images={images} lettersMap={this.props.lettersMap} />
          </Grid.Column>
          <Grid.Column width={2}>{price}</Grid.Column>
          {!hideStatusIcons && (
            <>
              <Grid.Column width={1} textAlign="center">
                <ConditionIcon status={status} size="small" />
              </Grid.Column>

              <Grid.Column width={1} textAlign="center">
                {Number.isInteger(status) && <FontAwesomeIcon className="fixed-arrow" icon={faLongArrowRight} />}
              </Grid.Column>
              <Grid.Column width={1} textAlign="center">
                <ConditionIcon status={0} size="small" />
              </Grid.Column>
              <Grid.Column width={1} textAlign="center">
                {customer_ok && <ConditionIcon status={10} size="small" />}
              </Grid.Column>
            </>
          )}

          {rep && (
            <>
              <Grid.Column width={5} className="fixed-replaced">
                {this.props.t("cc_replaced_with").message || "Replaced with"}: {rep.tyre.manufacturer} {rep.tyre.description}
              </Grid.Column>
              <Grid.Column width={5} className="fixed-description">
                {rep.remark && <span>{rep.remark}</span>}
              </Grid.Column>
            </>
          )}
        </Grid.Row>
        {description && (
          <Grid.Row className="description-row">
            <Grid.Column width={16}>{description}</Grid.Column>
          </Grid.Row>
        )}
      </>
    );
  };

  renderPrice = price => {
    const { include_vat, vat } = this.props.settings;

    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="fixed-price">€ {round2(price).toFixed(2)}</span>;
  };

  renderInterventions = interventions => {
    if (!Array.isArray(interventions)) return null;

    const { appointment } = this.props.communication;
    const hasImages = Array.isArray(appointment.final_check_images);

    return interventions.map(i =>
      this.renderRow(
        i,
        i.id,
        i.description,
        this.renderPrice(i.price),
        hasImages && appointment.final_check_images.filter(img => img.intervention_index === i.intervention_index),
        i.customer_ok
      )
    );
  };

  renderFixedItems = fixedItems => {
    if (!Array.isArray(fixedItems)) return null;

    return fixedItems
      .filter(nfi => nfi.question_status > -1)
      .map(nfi =>
        this.renderRow(
          nfi,
          nfi.question_result_id + "_" + nfi.tyre_position,
          nfi.mechanic_notes,
          this.renderPrice(nfi.price, false),
          nfi.images,
          nfi.customer_approved,
          nfi.question_status
        )
      );
  };

  render() {
    let { communication, settings, fixedItems, hideStatusIcons, t } = this.props;
    let appointment = communication.appointment;
    let interventions = appointment.interventions.filter(i => i.mechanic_fixed);

    if (interventions.length < 1 && fixedItems.length < 1) return null;

    return (
      <Grid verticalAlign="middle" className="report-fixed-container component-container">
        <Grid.Row className="fixed-heading">
          <Grid.Column width={16}>
            <div className="fixed-heading-title-container">
              <FontAwesomeIcon icon={faCog} className="fixed-heading-icon" />
              <h4 className="fixed-heading-title">{t("cc_items_fixed").message || "Items fixed"}</h4>
            </div>
            {!hideStatusIcons && (
              <div className="fixed-heading-status-container">
                <div className="fixed-heading-status-item">
                  <ConditionIcon status={0} size="small" />
                  <span className="fixed-heading-status-text">{t("cc_all_right").message || "All right"}</span>
                </div>
                <div className="fixed-heading-status-item">
                  <ConditionIcon status={2} size="small" />
                  <span className="fixed-heading-status-text">{t("cc_advisable").message || "Advisable"}</span>
                </div>
                <div className="fixed-heading-status-item">
                  <ConditionIcon status={3} size="small" />
                  <span className="fixed-heading-status-text">{t("cc_necessary").message || "Necessary"}</span>
                </div>
                <div className="fixed-heading-status-item">
                  <ConditionIcon status={10} size="small" />
                  <span className="fixed-heading-status-text">{t("cc_customer_ok").message || "Customer OK"}</span>
                </div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="fixed-header">
          <Grid.Column width={hideStatusIcons ? 12 : 8}>{t("cc_item_name").message || "ITEM NAME"}</Grid.Column>

          <Grid.Column width={2}>{t("cc_photo").message || "PHOTO"}</Grid.Column>
          <Grid.Column width={2}>{settings.include_vat ? t("cc_vat_price").message || "Incl. VAT" : t("cc_no_vat_price").message || "Excl. VAT"}</Grid.Column>
          {!hideStatusIcons && (
            <>
              <Grid.Column width={1} textAlign="center">
                {t("cc_initial_status").message || "INITIAL STATUS"}
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={1} textAlign="center">
                {t("cc_current_status").message || "CURRENT STATUS "}
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
            </>
          )}
        </Grid.Row>

        {this.renderInterventions(interventions)}
        {this.renderFixedItems(fixedItems)}
      </Grid>
    );
  }
}

const TranslatedFixedItems = withTranslation()(FixedItems);

TranslatedFixedItems.filterFixedItems = results => {
  return results.filter(r => {
    if (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.length > 0) {
      let rep = r.tyre_replacements.find(tr => tr.mechanic_fixed);
      if (!rep) return false;

      r.price = rep.price;
      r.customer_approved = rep.customer_answer;
      return true;
    } else {
      return r.mechanic_fixed;
    }
  });
};

export default TranslatedFixedItems;
