import React, { useState } from "react";
import { Grid, Table, Modal, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faArrowToRight, faCaretDown, faCaretUp, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Expandable } from "../../../../components";
import { Responsive, DesktopOnly, MobileOnly } from "../../../../layout";
import { round2, renderInterventionElementType } from "../../../../util/common";

import "./index.scss";

const ScheduledServices = ({ communication, settings, collapsing, showMobile }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!collapsing || false);
  const [showRemarkID, setShowRemarkID] = useState(null);
  const [selectedIntervention, setSelectedIntervention] = useState(null);

  const { interventions } = communication.appointment;

  const { include_vat, vat, display_intervention_elements } = settings;
  collapsing = !!collapsing;

  const displayPrice = price => {
    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="scheduled-services-price">€ {round2(price).toFixed(2)}</span>;
  };

  const toggleRemark = id => {
    if (showRemarkID === id) {
      setShowRemarkID(null);
      return;
    }

    setShowRemarkID(id);
  };

  const renderInterventionElementsModal = () => {
    return (
      <Modal open={!!selectedIntervention} className="scheduled-services-elements-modal" size="small">
        <Modal.Header>
          <div className="scheduled-services-elements-title">{selectedIntervention?.title}</div>
          <FontAwesomeIcon icon={faTimes} onClick={() => setSelectedIntervention(null)} />
        </Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Body>
              {selectedIntervention?.elements.map(e => (
                <Table.Row key={e.id}>
                  <Table.Cell>
                    {e.parent_dms_nr && (
                      <div className="intervention-element-arrow-to-right">
                        <FontAwesomeIcon icon={faArrowToRight} />
                      </div>
                    )}
                    {renderInterventionElementType(e.element_type)}
                    <div className="intervention-element-description">{e.description}</div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  };

  if (!interventions.length) return null;

  return (
    <Responsive showMobile={showMobile}>
      <DesktopOnly>
        <Grid className="scheduled-services-container component-container">
          <Grid.Row>
            <div className="scheduled-services-header">
              <div className="scheduled-services-header-title">
                <FontAwesomeIcon icon={faCog} className="scheduled-services-header-icon" />
                <h4>{t("cc_scheduled_services").message || "Scheduled services"}</h4>
              </div>
              {collapsing && (
                <div className="scheduled-services-header-button" onClick={() => setIsOpen(!isOpen)}>
                  <span className="scheduled-services-header-button-title">{isOpen ? t("cc_HIDE").message || "HIDE" : t("cc_SEE_ALL").message || "SEE ALL"}</span>
                  <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} className="scheduled-services-header-button-icon" />
                </div>
              )}
            </div>
          </Grid.Row>
          {isOpen && (
            <>
              <Grid.Row className="scheduled-services-heading">
                <Grid.Column width={4}>
                  <h3 className="scheduled-services-heading-title">{t("cc_SERVICE_NAME").message || "SERVICE NAME"}</h3>
                </Grid.Column>
                <Grid.Column width={10}>
                  <h3 className="scheduled-services-heading-title">{t("cc_DESCRIPTION").message || "DESCRIPTION"}</h3>
                </Grid.Column>
                <Grid.Column width={2}>
                  <h3 className="scheduled-services-heading-title align-center">{t("cc_PRICE").message || "PRICE"}</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid verticalAlign="middle">
                {interventions.map(intervention => (
                  <Grid.Row key={intervention.id} className="scheduled-services-item">
                    <Grid.Column width={4}>
                      {display_intervention_elements && intervention.elements.length > 0 && (
                        <Icon name="info circle" color="black" onClick={() => setSelectedIntervention(intervention)} />
                      )}
                      <span className="scheduled-services-name">{intervention.title}</span>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Expandable text={intervention.description} className="scheduled-services-remark" />
                    </Grid.Column>
                    <Grid.Column width={2} className="align-center">
                      {displayPrice(intervention.price)}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </DesktopOnly>
      <MobileOnly>
        <Grid className="scheduled-services-container component-container">
          <Grid.Row className="scheduled-services-header">
            <FontAwesomeIcon icon={faCog} className="scheduled-services-header-icon" />
            <h4>{t("cc_scheduled_services").message || "Scheduled services"}</h4>
          </Grid.Row>
          <Grid verticalAlign="middle">
            {interventions.map(intervention => (
              <Grid.Row
                onClick={() => {
                  intervention.description && toggleRemark(intervention.id);
                }}
                key={intervention.id}
                className="scheduled-services-item"
              >
                <Grid.Column width={11}>
                  <div className="scheduled-services-name-container">
                    <div>
                      {display_intervention_elements && intervention.elements.length > 0 && (
                        <Icon name="info circle" color="black" onClick={() => setSelectedIntervention(intervention)} />
                      )}
                      <span className="scheduled-services-name">{intervention.title}</span>
                    </div>
                    {intervention.description && <Icon name={showRemarkID === intervention.id ? "caret up" : "caret down"} style={{ color: "#1C3E52" }} />}
                  </div>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  {displayPrice(intervention.price)}
                </Grid.Column>
                {showRemarkID === intervention.id && (
                  <Grid.Column width={16}>
                    <span className="scheduled-services-remark">{intervention.description}</span>
                  </Grid.Column>
                )}
              </Grid.Row>
            ))}
          </Grid>
        </Grid>
      </MobileOnly>
      {renderInterventionElementsModal()}
    </Responsive>
  );
};

export default ScheduledServices;
