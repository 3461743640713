import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Segment, Header, Grid, Card, Modal, Button, Transition, Loader, Dimmer, Image, Message } from "semantic-ui-react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { WS_MSG_TYPE, WS_STATUS, URL_STATUS } from "./util";
import Service from "./service";

import "./index.scss";

import ENV from "../../util/env-config";

const WS_URL = `${ENV.webSocketEndpoint}/connect?client_id=`;

const initialState = {
  authToken: "",
  WS: null,
  listOfReceptionists: [],
  receptionist_id: 0,
  communication_key: "",
  openHomeURL: "",
  openDeskURL: "",
  isError: false,
  isLoading: false,
  wsConnected: false,
  isSigningIn: false,
};

class CounterTablet extends Component {
  state = { ...initialState };

  componentWillMount = () => {
    document.body.style.overscrollBehavior = "contain";
  };

  componentDidMount = () => {
    this.requestWakeLock();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  };

  requestWakeLock = async () => {
    try {
      await navigator.wakeLock.request();
    } catch (err) {}
  };

  handleVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      await this.requestWakeLock();
    }
  };

  logout = () => {
    this.setState({ authToken: "" }, () => {
      this.state.WS && this.state.WS.close();
      this.setState({ ...initialState });
    });
  };

  handleGoogleResponse = token => {
    this.setState({ isSigningIn: true }, async () => {
      try {
        let response;

        response = await Service.getTokenViaGoogle({ id_token: token?.credential });
        const authToken = response.data?.data?.token || "";

        response = await Service.getUserFromToken(authToken);
        const user = response.data?.data || {};

        response = await Service.getUserLocation(authToken, user.dealer_location_id);
        const location = response.data?.data || { language_code: "en-GB" };

        if (location.language_code !== "en-GB") this.props.i18n.changeLanguage(location.language_code);

        response = await Service.getListOfReceptionists(authToken);
        const listOfReceptionists = Array.isArray(response.data?.data) ? response.data.data : [];

        const receptionist_key = this.generateReceptionistKey();

        this.setState({ authToken, listOfReceptionists, receptionist_key, isSigningIn: false }, () => this.connectWebsocket(user.id));
      } catch (error) {
        if (error.response?.status === 401) return this.logout();
        this.setState({ isSigningIn: false });
      }
    });
  };

  connectWebsocket = userID => {
    const WS = new WebSocket(WS_URL + userID);

    WS.onopen = () => {
      const message = {
        _type: WS_MSG_TYPE.SUBSCRIBE,
        _queues: "counter-tablet-" + this.state.receptionist_key,
      };

      WS.send(JSON.stringify(message));

      this.setState({ WS, wsConnected: true });

      // wake lock seems to keep the screen on but network/cpu still goes to sleep
      clearInterval(this.stayAwakeInterval);
      this.stayAwakeInterval = setInterval(
        () => WS.send(JSON.stringify({ _type: WS_MSG_TYPE.PRIVATE, _queues: "counter-tablet-" + this.state.receptionist_key, body: { status: WS_STATUS.PING } })),
        60000
      );
    };

    WS.onmessage = message => {
      let body = {};
      try {
        body = JSON.parse(message.data)?.body;
      } catch (error) {}

      switch (body.status) {
        case WS_STATUS.OPEN_HOME_URL:
          if (body.delay) {
            setTimeout(() => {
              this.setState({ openDeskURL: "", communication_key: "", openHomeURL: body.url, isLoading: true, isError: false });
            }, body.delay * 1000);
          } else this.setState({ openDeskURL: "", communication_key: "", openHomeURL: body.url, isLoading: true, isError: false });
          break;

        case WS_STATUS.OPEN_DESK_URL:
          this.openDeskURL(body.key);
          break;

        default:
          break;
      }
    };

    WS.onerror = err => {
      console.log("WS error:", err);
    };

    WS.onclose = () => {
      this.setState({ WS: null, wsConnected: false }, () => {
        clearInterval(this.stayAwakeInterval);
        if (this.state.authToken) setTimeout(() => this.connectWebsocket(userID), 1000); // wait a second in case it loop over
      });
    };
  };

  openDeskURL = async communication_key => {
    try {
      await Service.deskCheckinURL({ status: URL_STATUS.DESK_URL_RECEIVED, communication_key }, this.state.authToken);
      this.setState({ openHomeURL: "", openDeskURL: `/#${communication_key}`, communication_key: communication_key, isLoading: true, isError: false });
    } catch (error) {
      if (error.response?.status === 401) return this.logout();
      this.setState({ isLoading: false, isError: true });
    }
  };

  retryOpenHomeURL = () => {
    const { openHomeURL } = this.state;
    this.setState({ openHomeURL: "" }, () => this.setState({ isLoading: true, openHomeURL }));
  };

  renderHomeURL = () => {
    const { openHomeURL, isLoading } = this.state;

    if (!openHomeURL) return null;

    if (/^http.*\.(jpg|jpeg|png)/.test(openHomeURL)) return <Image src={openHomeURL} fluid />;

    return (
      <>
        {isLoading && this.renderIsLoading()}
        <iframe
          title="home-url"
          style={{ width: "100vw", height: "100vh", border: "none", margin: "0px", padding: "0px" }}
          src={openHomeURL}
          onLoad={() => this.setState({ isLoading: false })}
          onError={() => this.setState({ isLoading: false, isError: true })}
        ></iframe>
      </>
    );
  };

  renderDeskURL = () => {
    const { openDeskURL, isLoading } = this.state;

    if (!openDeskURL) return null;

    return (
      <>
        {isLoading && this.renderIsLoading()}
        <iframe
          title="desk-url"
          style={{ width: "100vw", height: "100vh", border: "none", margin: "0px", padding: "0px" }}
          src={`/deskcheckin/${openDeskURL}`}
          onLoad={() => this.setState({ isLoading: false })}
          onError={this.deskURLFailed}
        ></iframe>
      </>
    );
  };

  deskURLFailed = async () => {
    try {
      const { communication_key, authToken } = this.state;
      await Service.deskCheckinURL({ status: URL_STATUS.DESK_URL_FAILED, communication_key }, authToken);
      this.setState({ isLoading: false, isError: true });
    } catch (error) {
      if (error.response?.status === 401) return this.logout();
      this.setState({ isLoading: false, isError: true });
    }
  };

  renderLogin = () => (
    <GoogleOAuthProvider clientId={ENV.clientID}>
      <Grid textAlign="center" style={{ margin: 0 }}>
        <Segment className="counter-tablet-container" padded="very" raised size="massive" compact textAlign="center">
          <Header className="header-title">
            <span>Claire</span> Automotive Support
          </Header>

          <GoogleLogin width={350} onSuccess={this.handleGoogleResponse} onError={this.handleGoogleResponse} />
        </Segment>
      </Grid>
    </GoogleOAuthProvider>
  );

  renderConnecting = () => (
    <Grid textAlign="center" className="counter-tablet-container">
      <Message negative>{this.props.t("cc_connecting_please_wait").message || "Connecting, please wait"}</Message>
    </Grid>
  );

  renderError = () => {
    const { openHomeURL } = this.state;
    const { t } = this.props;

    return (
      <Grid className="counter-tablet-container" textAlign="center" container>
        <Segment padded="very" raised size="massive" compact textAlign="center">
          <Message negative>
            {openHomeURL
              ? t("open_home_url_error").message || "An error occurred, waiting for the next communication"
              : t("open_desk_url_error").message || "An error occurred, please wait"}
          </Message>

          {openHomeURL && (
            <Modal.Actions>
              <Button positive onClick={this.retryOpenHomeURL}>
                {t("retry").message || "Retry"}
              </Button>
            </Modal.Actions>
          )}
        </Segment>
      </Grid>
    );
  };

  renderNoReceptionist = () => {
    const { t } = this.props;

    return (
      <Grid textAlign="center" className="counter-tablet-container" container>
        <Segment padded="very" raised size="massive" compact textAlign="center">
          <Message negative>
            {t("cc_no_user_enabled_use_counter_table").message ||
              "There is no user with the option 'Use counter tablets' enabled in this location, please go to your account page and enable it, or contact Claire support for help."}
          </Message>

          <Button positive onClick={this.logout}>
            {t("cc_close").message || "Close"}
          </Button>
        </Segment>
      </Grid>
    );
  };

  renderIsLoading = () => (
    <Transition visible={true} animation="fade" duration={300}>
      <Dimmer active={true}>
        <Loader size="massive">{this.props.t("cc_Loading").message || "Loading"}</Loader>
      </Dimmer>
    </Transition>
  );

  renderSelectReceptionist = () => {
    const { listOfReceptionists, isLoading } = this.state;

    return (
      <Grid textAlign="center" className="counter-tablet-container" container>
        <Segment padded="very" raised size="massive" compact textAlign="center" loading={isLoading}>
          <Header className="header-title">{this.props.t("cc_select_user_to_connect").message || "Select the user to connect with this tablet"}</Header>

          <br />

          <Card.Group itemsPerRow={2}>
            {listOfReceptionists.map(r => (
              <Card onClick={() => !isLoading && this.selectReceptionist(r.id)} key={r.id}>
                <Image src={r.profile_picture || "/employee.png"} wrapped ui={false} />
                <Card.Content>
                  <Card.Description>{`${r.first_name} ${r.last_name}`}</Card.Description>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Segment>
      </Grid>
    );
  };

  selectReceptionist = receptionist_id => {
    const { WS, authToken, receptionist_key } = this.state;

    if (!WS) return this.setState({ wsConnected: false }); // not supposed to happen at this point, just in case

    this.setState({ isLoading: true }, async () => {
      try {
        await Service.selectReceptionist({ receptionist_id, receptionist_key }, authToken);

        this.setState({ isLoading: false, receptionist_id });
      } catch (error) {
        if (error.response?.status === 401) return this.logout();
        this.setState({ isLoading: false, isError: true });
      }
    });
  };

  generateReceptionistKey = () => {
    var key = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 64; i++) key += characters.charAt(Math.floor(Math.random() * charactersLength));
    return key;
  };

  render() {
    const { authToken, wsConnected, isError, listOfReceptionists, receptionist_id, openHomeURL, openDeskURL } = this.state;

    if (!authToken) return this.renderLogin();

    if (!wsConnected) return this.renderConnecting();

    if (isError) return this.renderError();

    if (!listOfReceptionists.length) return this.renderNoReceptionist();

    if (!receptionist_id) return this.renderSelectReceptionist();

    if (openDeskURL) return this.renderDeskURL();

    if (openHomeURL) return this.renderHomeURL();

    return (
      <Grid textAlign="center" className="counter-tablet-container" container>
        <Message negative>{this.props.t("cc_unexpected_error_restart_app").message || "An unexpected error occurred, please restart the application"}</Message>
      </Grid>
    );
  }
}

export default withTranslation()(CounterTablet);
