const ENVIRONMENTS = {
  dev: {
    apiBase: "https://clairedev.nl/api/v2",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    keylockerRelayIPAddress: "https://relaymodule.mycarcheck.pro",
    distrimateBase: "https://distrimate.com/keylocker",
    customComBase: "https://customcom.clairedev.nl/v1",
    customComBaseV2: "https://customcom.clairedev.nl/v2",
    appointmentBase: "https://appointment.clairedev.nl/v1",
    deskComBase: "https://desk.clairedev.nl/v1",
    authBase: "https://auth.clairedev.nl/v1",
    keylockerBase: "https://keylocker.clairedev.nl/v1",
    kioskBase: "https://kiosk.clairedev.nl/v1",
    systemBase: "https://system.clairedev.nl/v1",
  },
  local: {
    apiBase: "https://clairedev.nl/api/v2",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    keylockerRelayIPAddress: "https://relaymodule.mycarcheck.pro",
    distrimateBase: "https://distrimate.com/keylocker",
    customComBase: "https://customcom.clairedev.nl/v1",
    customComBaseV2: "https://customcom.clairedev.nl/v2",
    appointmentBase: "https://appointment.clairedev.nl/v1",
    deskComBase: "https://desk.clairedev.nl/v1",
    keylockerBase: "https://keylocker.clairedev.nl/v1",
    authBase: "https://auth.clairedev.nl/v1",
    kioskBase: "https://kiosk.clairedev.nl/v1",
    systemBase: "https://system.clairedev.nl/v1",
  },
  localWithBackend: {
    apiBase: "http://localhost:8080/api/v2",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    keylockerRelayIPAddress: "https://relaymodule.mycarcheck.pro",
    distrimateBase: "https://distrimate.com/keylocker",
    customComBase: "https://customcom.clairedev.nl/v1",
    customComBaseV2: "https://customcom.clairedev.nl/v2",
    appointmentBase: "https://appointment.clairedev.nl/v1",
    deskComBase: "https://desk.clairedev.nl/v1",
    keylockerBase: "https://keylocker.clairedev.nl/v1",
    authBase: "https://auth.clairedev.nl/v1",
    kioskBase: "https://kiosk.clairedev.nl/v1",
    systemBase: "https://system.clairedev.nl/v1",
  },
  prod: {
    apiBase: "/api/v2",
    clientID: "674901036874-hrv3lhqlkp3b0uh8lhoaj0hlmrqd37it.apps.googleusercontent.com",
    webSocketEndpoint: "wss://notifier.claireit.com",
    keylockerRelayIPAddress: "https://relaymodule.mycarcheck.pro",
    distrimateBase: "https://distrimate.com/keylocker",
    appointmentBase: "https://appointment.claireit.com/v1",
    customComBase: "https://mycarcheck.pro/v1",
    customComBaseV2: "https://mycarcheck.pro/v2",
    authBase: "https://auth.claireit.com/v1",
    deskComBase: "https://desk.claireit.com/v1",
    keylockerBase: "https://keylocker.claireit.com/v1",
    kioskBase: "https://kiosk.claireit.com/v1",
    systemBase: "https://system.claireit.com/v1",
  },
};

const ENV = ENVIRONMENTS[process.env.REACT_APP_ENV];

export default ENV;
