import axios from "axios";

import ENV from "../../../../../util/env-config";

class Service {
  static failed = false;

  static getKeyLockerNumber(requestData) {
    return axios.post(ENV.keylockerBase + "/drop/assign", requestData);
  }

  static setKeyLockerClosed(requestData) {
    return axios.post(ENV.keylockerBase + "/drop/closed", requestData);
  }

  static abortDropKey(requestData) {
    return axios.post(ENV.keylockerBase + "/drop/abort", requestData);
  }

  static openKeyLocker(locker, softmode) {
    if (softmode) {
      if (!this.failed) {
        this.failed = true;
        return Promise.reject({ message: "Softmode: Error opening keylocker box" });
      }

      return Promise.resolve();
    }

    return axios.get(`${ENV.keylockerRelayIPAddress}/state.xml?relay${locker}State=2`);
  }

  static async checkKeyLockerStatus(softmode, keyLockerNumber) {
    try {
      if (softmode) return "0";

      const xmlData = await axios.get(`${ENV.keylockerRelayIPAddress}/state.xml`);
      const parser = new DOMParser();
      const xml = parser.parseFromString(xmlData.data, "application/xml");
      const nodes = xml.getElementsByTagName(`digitalInput${keyLockerNumber}`);
      if (nodes.length) return nodes[0].firstChild.nodeValue;

      return xml
        .getElementsByTagName("inputstates")[0]
        .firstChild.nodeValue.split("")
        .reverse()[keyLockerNumber - 1];
    } catch (error) {
      throw error;
    }
  }

  static relayOpenKeylockerError(requestData) {
    return axios.post(`${ENV.keylockerBase}/keylockers/relay_error`, requestData);
  }
}

export default Service;
