import React from "react";

import "./index.scss";

const DealerInfoHeader = ({ settings }) => {
  const { logo, dealer_name, headline, location_street, location_postal_code, location_city, email, phone_number, website } = settings;

  return (
    <div className="report-dealer-header-container component-container">
      <div className="report-header-img-container">
        <img className="report-header-img" src={logo} alt="logo" />
      </div>

      <div className="report-header-info-container">
        <div>
          <div className="report-header-info-item">{headline ? headline : dealer_name}</div>
          <div className="report-header-info-item">{location_street}</div>
          <div className="report-header-info-item">{`${location_postal_code} ${location_city}`}</div>
        </div>

        <div>
          <div className="report-header-info-item">{email}</div>
          <div className="report-header-info-item">{website}</div>
          <div className="report-header-info-item">T. {phone_number}</div>
        </div>
      </div>
    </div>
  );
};

export default DealerInfoHeader;
