import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { HelpButton, CancelButton, NavigationButton } from "../../../../../components";
import { VirtualKeyboard, LAYOUTS } from "../../../../../../../components";

import "./EnterRegistration.scss";

const EnterRegistration = ({
  renderLayout,
  onChange,
  onGoToCancelScreen,
  onGoToHelpScreen,
  registrationNumber,
  onHandleGetCommunicationChannels,
  loading,
  onGoBack,
  t,
}) => {
  const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} disabled={loading} />, <CancelButton key={2} onClick={onGoToCancelScreen} disabled={loading} />];
  const content = (
    <div className="EnterRegistrationScreen">
      <FontAwesomeIcon icon={faCar} className="EnterRegistrationHeaderIcon" />
      <div className="KeyLockerTitle EnterRegistrationHeaderTitle">{t("kl_registration_number").message || "Registration Number"}</div>
      <div className="EnterRegistrationScreenRegContainer">
        <div className="reg-number-container">
          <div className="reg-country">
            <div className="reg-country-text">NL</div>
          </div>
          <div className="reg-value-container">
            <div className="reg-value-inner-container">
              <div className="reg-number">{registrationNumber[0] || ""}</div>
              <div className="reg-number">{registrationNumber[1] || ""}</div>
              <div className="reg-number">{registrationNumber[2] || ""}</div>
              <div className="reg-number">{registrationNumber[3] || ""}</div>
              <div className="reg-number">{registrationNumber[4] || ""}</div>
              <div className="reg-number">{registrationNumber[5] || ""}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="EnterRegistrationScreenKeyboardContainer">
        <VirtualKeyboard layout={LAYOUTS.REGISTRATION} onChange={onChange} text={registrationNumber} />
      </div>
    </div>
  );

  const leftButton = <NavigationButton loading={loading} back onClick={onGoBack} />;

  const rightButton = <NavigationButton loading={loading} onClick={onHandleGetCommunicationChannels} disabled={registrationNumber.length !== 6} />;

  return renderLayout({ headerButtons, leftButton, rightButton, content });
};

export default withRouter(withTranslation()(EnterRegistration));
