import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { Cancel, CallForHelp } from "../../../../../components";

import AuthenticationHelp from "../AuthenticationHelp";
import AuthenticationError from "../AuthenticationError";
import EnterPIN from "./EnterPIN";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  ERROR: 4,
  CALL_FOR_HELP: 5,
};

class EnterPinScreen extends Component {
  state = { screen: SUBSCREENS.MAIN };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goToMain = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToErrorScreen = () => this.setState({ screen: SUBSCREENS.ERROR });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });

  render() {
    const { renderLayout, onGoToNextScreenWithState, onGoBack, onAbort, communicationChannel, t } = this.props;

    switch (this.state.screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <EnterPIN
            renderLayout={renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
            onGoToErrorScreen={this.goToErrorScreen}
            communicationChannel={communicationChannel}
            onGoBack={onGoBack}
          />
        );

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={renderLayout} onGoBack={this.goToMain} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goToMain} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;

      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={renderLayout}
            onGoBack={this.goToMain}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            message={t("kl_enter_pin_error").message || "The PIN code is incorrect or something went wrong, please check or call for help."}
          />
        );
    }
  }
}

export default withTranslation()(EnterPinScreen);
