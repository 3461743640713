import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faUserCheck, faListAlt, faKey, faInbox } from "@fortawesome/pro-solid-svg-icons";

import MainLayout from "../../../layout";
import { NavigationButton } from "../../../components";
import KeylockerFull from "./subscreens/KeylockerFull";

import "./index.scss";

class WelcomeScreen extends Component {
  startInactivityTimer = () => this.inactivityTimer = setTimeout(this.props.onGoBackToStartScreen, 60000);

  handleResetInactivityTimer = () => {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  };

  componentDidMount() {
    this.startInactivityTimer();
    document.addEventListener("click", this.handleResetInactivityTimer);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleResetInactivityTimer);
    clearTimeout(this.inactivityTimer);
  }

  renderContent = () => {
    const { onGoToNextScreen, t } = this.props;

    return (
      <div className="WelcomeScreen">
        <div className="WelcomeScreenMessage">
          {t("kl_welcome_message_part1").message || "To drop your car key in this locker"}
          <br />
          {t("kl_welcome_message_part2").message || "go through these four steps:"}
        </div>
        <Grid centered className="WelcomeScreenSteps">
          <Grid.Row centered>
            <Grid.Column width="4" textAlign="center" onClick={onGoToNextScreen}>
              <div className="WelcomeScreenStep">
                <div className="WelcomeScreenStepNumber">1</div>
                <FontAwesomeIcon icon={faUserLock} className="WelcomeScreenStepSingleIcon" />
                <div className="WelcomeScreenStepTitle">
                  <p>{t("kl_authentication").message || "Authentication"}</p>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width="4" textAlign="center" onClick={onGoToNextScreen}>
              <div className="WelcomeScreenStep">
                <div className="WelcomeScreenStepNumber">2</div>
                <FontAwesomeIcon icon={faUserCheck} className="WelcomeScreenStepSingleIcon" />
                <div className="WelcomeScreenStepTitle">
                  <p>{t("kl_check_validate").message || "Check and Validate Contact Information"}</p>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width="4" textAlign="center" onClick={onGoToNextScreen}>
              <div className="WelcomeScreenStep">
                <div className="WelcomeScreenStepNumber">3</div>
                <FontAwesomeIcon icon={faListAlt} className="WelcomeScreenStepSingleIcon" />
                <div className="WelcomeScreenStepTitle">
                  <p>{t("kl_validate_appointment_list").message || "Validate Appointment List"}</p>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width="4" textAlign="center" onClick={onGoToNextScreen}>
              <div className="WelcomeScreenStep">
                <div className="WelcomeScreenStepNumber">4</div>
                <div className="WelcomeScreenStepCompositeIcon">
                  <FontAwesomeIcon icon={faKey} />
                  <br />
                  <FontAwesomeIcon icon={faInbox} />
                </div>
                <div className="WelcomeScreenStepTitle">
                  <p>{t("kl_drop_your_keys").message || "Drop Your Key inside Box"}</p>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  render() {
    const { keylocker, onGoBackToStartScreen, onGoToNextScreen, t } = this.props;
    const { active_box_count, key_count } = keylocker;

    if (active_box_count <= key_count) return <KeylockerFull onGoBack={onGoBackToStartScreen} />;

    const leftButton = <NavigationButton back onClick={onGoBackToStartScreen} />;
    const rightButton = <NavigationButton onClick={onGoToNextScreen}>{t("kl_next").message || "Next"}</NavigationButton>;

    return <MainLayout leftButton={leftButton} rightButton={rightButton} content={this.renderContent()} />;
  }
}

export default withTranslation()(WelcomeScreen);
