import React from "react";
import moment from "moment";
import { Button, Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCalendarAlt, faInfoCircle, faMapMarkedAlt, faCar, faEnvelope, faPhone, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

import MainLayout from "../../layout";
import { renderCarRegistration, getEmail, getPhoneNumber } from "../../../../util/common";

import "./index.scss";

const InfoScreen = ({ communication, settings, onGoToNextScreen, t }) => {
  const renderAppointmentInformation = () => {
    return (
      <>
        {communication.appointment.time_car_app && (
          <div className="margin-bottom-8 line-height">
            <FontAwesomeIcon icon={faCalendarAlt} className="info-icon color" />
            <span className="info-text">
              {communication.appointment.due_in
                ? moment(communication.appointment.due_in)
                    .format("DD-MM-YYYY HH:mm")
                    .replace(" 00:00", "")
                : moment(communication.appointment.time_car_app).format("DD-MM-YYYY")}
            </span>
          </div>
        )}
        {communication.appointment.wo_nr && (
          <div className="margin-bottom-8 line-height">
            <FontAwesomeIcon icon={faInfoCircle} className="info-icon color" />
            <span className="info-text">{communication.appointment.wo_nr}</span>
          </div>
        )}
        {settings.location_street && settings.location_city && (
          <div>
            <FontAwesomeIcon icon={faMapMarkedAlt} className="info-icon color" />
            <span className="info-text">{`${settings.location_street}, ${settings.location_city}`}</span>
          </div>
        )}
      </>
    );
  };

  const renderCarInformation = () => {
    return (
      <>
        {renderCarRegistration(settings.location_country, communication.appointment.reg_number)}

        {communication.appointment.vin_nr && (
          <div className="margin-bottom-8 line-height">
            <Popup
              trigger={<FontAwesomeIcon icon={faInfoCircle} className="info-icon color" />}
              content={t("cc_VIN_number").message || "VIN Number"}
              position="bottom center"
              inverted
            />
            <span className="info-text">{communication.appointment.vin_nr}</span>
          </div>
        )}
        {(communication.appointment.car_make || communication.appointment.car_model) && (
          <div>
            <FontAwesomeIcon icon={faCar} className="info-icon color" />
            <span className="info-text">
              {communication.appointment.car_make || ""} {communication.appointment.car_model || ""}
            </span>
          </div>
        )}
      </>
    );
  };

  const renderCustomerInformation = () => {
    let fullname = "";

    const correctEmail = communication.correct_email || getEmail(communication);
    const correctPhone = communication.correct_phone || getPhoneNumber(communication);

    if (communication.customer) {
      if (communication.customer.title) fullname += communication.customer.title + " ";

      if (communication.customer.firstname) fullname += communication.customer.firstname + " ";

      if (communication.customer.surname) fullname += communication.customer.surname;
    }

    return (
      <>
        {fullname && (
          <div className="margin-bottom-8">
            <FontAwesomeIcon icon={faUser} className="info-icon color" />
            <span className="info-text">{fullname}</span>
          </div>
        )}
        {correctEmail && (
          <div className="margin-bottom-8">
            <FontAwesomeIcon icon={faEnvelope} className="info-icon color" />
            <span className="info-text">{correctEmail}</span>
          </div>
        )}
        {correctPhone && (
          <div>
            <FontAwesomeIcon icon={faPhone} className="info-icon color" />
            <span className="info-text">{correctPhone}</span>
          </div>
        )}
      </>
    );
  };

  const content = (
    <div className="info-content-container">
      <div className="info-container component-container">
        <div className="info-heading">{t("cc_appointment_information").message || "Appointment information"}</div>
        <div className="info-content">{renderAppointmentInformation()}</div>
      </div>
      <div className="info-container component-container">
        <div className="info-heading">{t("cc_car_information").message || "Car information"}</div>
        <div className="info-content">{renderCarInformation()}</div>
      </div>
      {communication.customer && (
        <div className="info-container component-container">
          <div className="info-heading">{t("cc_customer_information").message || "Customer information"}</div>
          <div className="info-content">{renderCustomerInformation()}</div>
        </div>
      )}
    </div>
  );

  const navigationButtons = (
    <Button className="DeskCheckinNavigationButton" onClick={onGoToNextScreen}>
      <span className="navigation-text">{t("cc_next").message || "NEXT"}</span>
      <FontAwesomeIcon icon={faArrowRight} className="navigation-icon" />
    </Button>
  );

  return <MainLayout content={content} navigationButtons={navigationButtons} showTitle isCheckingOut={communication.is_checking_out} />;
};

export default withTranslation()(InfoScreen);
