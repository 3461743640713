import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { Cancel, CallForHelp } from "../../../../../components";

import MainLayout from "../../../../../layout";

import AuthenticationHelp from "../AuthenticationHelp";
import AuthenticationError from "../AuthenticationError";
import EnterPIN from "./EnterPIN";

import { MODE } from "../../../..";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  ERROR: 4,
  CALL_FOR_HELP: 5,
};

const STEP = 1;
class EnterPinScreen extends Component {
  state = { screen: SUBSCREENS.MAIN, errorMessage: "" };

  renderLayout = props => {
    const { t } = this.props;

    const step = (t("kl_step").message || "Step") + " " + STEP;
    const title = t("kl_authentication").message || "Authentication";

    return <MainLayout step={step} title={title} {...props} />;
  };

  goToMain = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goToErrorScreen = errorMessage => this.setState({ screen: SUBSCREENS.ERROR, errorMessage });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });

  render() {
    const { onGoToNextScreenWithState, onGoBack, onAbort, t } = this.props;

    switch (this.state.screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <EnterPIN
            renderLayout={this.renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancelScreen={this.goToCancel}
            onGoToNextScreenWithState={onGoToNextScreenWithState}
            onGoToErrorScreen={this.goToErrorScreen}
            onGoBack={onGoBack}
          />
        );

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={this.renderLayout} onGoBack={this.goToMain} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel keylockerMode={MODE.PICKUP} onGoBack={this.goToMain} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;

      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={this.renderLayout}
            onGoBack={this.goToMain}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            message={this.state.errorMessage || t("kl_enter_pin_error").message || "The PIN code is incorrect or something went wrong, please check or call for help."}
          />
        );
    }
  }
}

export default withTranslation()(EnterPinScreen);
