import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { normalizeCommunicationData } from "../../../../../../../util/common";
import { Cancel, CallForHelp } from "../../../../../components";
import AuthenticationHelp from "../AuthenticationHelp";
import AuthenticationError from "../AuthenticationError";
import ConfirmAuthenticationWithout2FAMethod from "./ConfirmAuthenticationWithout2FAMethod";

import Service from "./service";

import "./index.scss";

const SUBSCREENS = {
  MAIN: 1,
  HELP: 2,
  CANCEL: 3,
  CALL_FOR_HELP: 4,
  ERROR: 5,
};

class ConfirmAuthenticationWithout2FA extends Component {
  state = {
    screen: SUBSCREENS.MAIN,
    loading: false,
  };

  goToHelp = () => this.setState({ screen: SUBSCREENS.HELP });
  goToCancel = () => this.setState({ screen: SUBSCREENS.CANCEL });
  goBack = () => this.setState({ screen: SUBSCREENS.MAIN });
  goToCallForHelp = () => this.setState({ screen: SUBSCREENS.CALL_FOR_HELP });
  goToErrorScreen = () => this.setState({ screen: SUBSCREENS.ERROR });

  handleConfirmAuthenticationWithout2FA = () => {
    const { onGoToOrderScreenWithState, appointment_id, match, i18n } = this.props;

    this.setState({ loading: true }, () => {
      const requestData = { private_key: match.params.keylockerKey, appointment_id };

      Service.auth(requestData)
        .then(res => {
          const communication = res?.data?.data ? res.data.data : {};

          if (normalizeCommunicationData(communication, i18n)) {
            this.setState({ loading: false }, () => onGoToOrderScreenWithState({ communication }));
          } else {
            this.setState({ loading: false }, () => this.goToErrorScreen());
          }
        })
        .catch(err => {
          this.setState({ loading: false }, () => this.goToErrorScreen());
          console.log("keylocker error getting communication", err);
        });
    });
  };

  render() {
    const { screen, loading } = this.state;
    const { renderLayout, onAbort, emailAddress, t } = this.props;

    switch (screen) {
      default:
      case SUBSCREENS.MAIN:
        return (
          <ConfirmAuthenticationWithout2FAMethod
            renderLayout={renderLayout}
            onGoToHelpScreen={this.goToHelp}
            onGoToCancel={this.goToCancel}
            handleConfirmAuthenticationWithout2FA={this.handleConfirmAuthenticationWithout2FA}
            emailAddress={emailAddress}
            loading={loading}
          />
        );

      case SUBSCREENS.HELP:
        return <AuthenticationHelp renderLayout={renderLayout} onGoBack={this.goBack} onGoToCallForHelp={this.goToCallForHelp} />;

      case SUBSCREENS.CANCEL:
        return <Cancel onGoBack={this.goBack} onAbort={onAbort} />;

      case SUBSCREENS.CALL_FOR_HELP:
        return <CallForHelp onGoBack={this.goToHelp} />;

      case SUBSCREENS.ERROR:
        return (
          <AuthenticationError
            renderLayout={renderLayout}
            onGoBack={this.goBack}
            onGoToHelpScreen={this.goToHelp}
            onGoToCallForHelp={this.goToCallForHelp}
            message={t("kl_confirm_email_error").message || "An error occurred, please try again or abort."}
          />
        );
    }
  }
}

export default withRouter(withTranslation()(ConfirmAuthenticationWithout2FA));
