import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

import MainLayout from "../../layout";

import { NavigationButton } from "..";

import CallForHelpError from "./CallForHelpError";
import Service from "./service";

import "./index.scss";

const SCREENS = {
  MAIN: 1,
  ERROR: 2,
};

class CallForHelp extends Component {
  state = {
    screen: SCREENS.MAIN,
    isLoading: false,
  };

  componentDidMount() {
    this.getCallForHelp();
  }

  getCallForHelp = () => {
    const { communication, match } = this.props;
    const requestData = { private_key: match.params.keylockerKey };

    if (communication && communication.user_key) requestData.communication_key = communication.user_key;

    this.setState({ isLoading: true }, () => {
      Service.callForHelp(requestData)
        .then(res => {
          this.setState({ isLoading: false, screen: SCREENS.MAIN });
        })
        .catch(err => {
          this.setState({ isLoading: false, screen: SCREENS.ERROR });
        });
    });
  };

  render() {
    const { t, onGoBack } = this.props;
    const { screen, isLoading } = this.state;

    const content = (
      <div className="CallForHelpScreen">
        <div className="CallForHelpTitle KeyLockerTitle">
          <FontAwesomeIcon icon={faPhoneAlt} className="CallForHelpIcon" />
          <span>{t("kl_call").message || "Call"}</span>
        </div>
        <div className="CallForHelpMessage">
          <div>{t("kl_call_for_help_message_part1").message || "Please wait, someone from the Service Desk"}</div>
          <div>{t("kl_call_for_help_message_part2").message || "will come and help you."}</div>
        </div>
      </div>
    );

    const leftButton = <NavigationButton disabled={isLoading} back onClick={this.props.onGoBack} />;

    switch (screen) {
      default:
      case SCREENS.MAIN:
        return <MainLayout content={content} leftButton={leftButton} />;

      case SCREENS.ERROR:
        return <CallForHelpError onGoBack={onGoBack} />;
    }
  }
}

export default withRouter(withTranslation()(CallForHelp));
