import axios from "axios";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import ENV from "./env-config";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: "en-GB",
    returnObjects: true,

    ns: ["translations"],
    defaultNS: "translations",

    debug: false,

    interpolation: {
      escapeValue: false,
    },

    language: "en-GB",
    load: "currentOnly",

    backend: {
      request: async function(_options, _url, _payload, callback) {
        try {
          const response = await axios.post(ENV.systemBase + "/translations/list_by_language_id", { language_code: i18n.language || "en-GB" });
          callback(null, { status: response.status, data: response.data?.data?.translations });
        } catch (error) {
          console.log("Error getting translations", error);
          callback(error);
        }
      },
      crossDomain: true,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
