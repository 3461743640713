import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { saveAs } from "file-saver";

import { getYYYMMDD } from "../../../../../util/common";

import Service from "../service";

class Save extends Component {
  state = {
    isSaving: false,
  };

  handleDownload = () => {
    this.setState({ isSaving: true }, () => {
      Service.getChecklistReport(this.props.routeKey)
        .then(result => {
          let { appointment } = this.props;
          saveAs(new Blob([result.data]), appointment.car_make + "_" + appointment.car_model + "_" + appointment.reg_number_escaped + "_" + getYYYMMDD() + ".pdf");
          this.setState({ isSaving: false });
        })
        .catch(error => {
          console.error("Error getting communication report.", error);
          this.setState({ isSaving: false });
        });
    });
  };

  render() {
    const { isSaving } = this.state;
    return (
      <Button className="header-save-button bg-color" loading={isSaving} onClick={this.handleDownload} primary>
        {this.props.t("cc_download").message || "Download"}
      </Button>
    );
  }
}

export default Save;
