import axios from "axios";
import ENV from "../../../../../../../util/env-config";

class Service {
  static confirmAuthenticationMethod(requestData) {
    return axios.post(ENV.keylockerBase + "/drop/send_pin", requestData);
  }
}

export default Service;
