import React, { Component } from "react";

import { ExternalScripts } from "../../components";
import { TurboHoetCommTemplate, TurboHoetCheckTemplate, MerdecesCheckTemplate, StandardCheckTemplate } from "./templates";

import "./index.scss";

class ReportTemplate extends Component {
  render() {
    const { communication, settings } = this.props;

    switch (settings.report_template_id) {
      case 1:
        return <MerdecesCheckTemplate communication={communication} settings={settings} />;

      case 2:
        return (
          <ExternalScripts settings={settings} noExternalJS>
            <StandardCheckTemplate communication={communication} settings={settings} />
          </ExternalScripts>
        );

      case 3:
        return (
          <ExternalScripts settings={settings} noExternalJS>
            <TurboHoetCommTemplate communication={communication} settings={settings} />
          </ExternalScripts>
        );

      case 4:
        return <StandardCheckTemplate communication={communication} settings={settings} />;

      case 5:
        return <TurboHoetCheckTemplate communication={communication} settings={settings} />;

      default:
        return null;
    }
  }
}

export default ReportTemplate;
