import React, { Component } from "react";
import { Label } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoVideo } from "@fortawesome/pro-regular-svg-icons";

import Lightbox from "./react-image-video-lightbox";

import "./index.scss";

class GalleryButton extends Component {
  state = { galleryVisible: false };

  openGallery = r => {
    this.setState({ images: r.images, videos: r.videos, imageGalleryVisible: true });
  };

  closeGallery = () => {
    this.setState({ imageGalleryVisible: false });
  };

  renderLightbox = () => {
    let { images, videos } = this.props;
    let { imageGalleryVisible } = this.state;

    if (!imageGalleryVisible) return null;

    let data = [];
    if (Array.isArray(images) && images.length > 0)
      data = images.map(i => {
        return { url: i.url, type: "photo" };
      });

    if (Array.isArray(videos) && videos.length > 0)
      data = data.concat(
        videos.map(v => {
          return { url: v.url, type: "video" };
        })
      );

    if (data.length === 0) return null;

    return <Lightbox data={data} startIndex={0} showResourceCount={true} onCloseCallback={this.closeGallery} />;
  };

  render() {
    const { images, videos } = this.props;

    if ((!Array.isArray(images) || images.length === 0) && (!Array.isArray(videos) || videos.length === 0)) return null;

    return (
      <>
        <Label color="teal" className="gallery-button-label" onClick={this.openGallery}>
          <FontAwesomeIcon icon={faPhotoVideo} color="white" />
          {(Array.isArray(images) ? images.length : 0) + (Array.isArray(videos) ? videos.length : 0)}
        </Label>
        {this.renderLightbox()}
      </>
    );
  }
}

export default GalleryButton;
