import React from "react";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CancelButton, NavigationButton } from "../../components";

import "./CheckInfo.scss";

const CheckInfo = ({ renderLayout, extraPhone, communication, onGoToCancelScreen, onGoToAddPhoneNumber, onGoToNextScreenWithState, t }) => {
  const headerButtons = [<CancelButton key={1} onClick={onGoToCancelScreen} />];
  let phone = null;

  if (communication.appointment?.customer_driver) {
    if (communication.appointment.customer_driver.tel_mobile_private) phone = communication.appointment.customer_driver.tel_mobile_private;
    else if (communication.appointment.customer_driver.tel_mobile_business) phone = communication.appointment.customer_driver.tel_mobile_business;
  }

  const content = (
    <div className="KioskCheckInfoScreen">
      <div className="KioskTitle">
        <div className="KioskTitleIcon">
          <FontAwesomeIcon icon={faPaperPlane} />
        </div>
        {t("kiosk_appointment_found").message || "Appointment found"}
      </div>
      {!phone && (
        <>
          <div className="CheckInfoMessage">
            {t("kiosk_missing_phone_number").message || "We are missing your phone number."}
            <br />
            {t("kiosk_please_enter_it_below").message || "Please enter it below so we can use it to contact you if necessary."}
          </div>
          <div className="CheckInfoDetail"></div>
        </>
      )}
      {phone && (
        <>
          <div className="CheckInfoMessage">{t("kiosk_this_is_the_info_we_have_from_you").message || "This is the information that we have from you:"}</div>
          <div className="CheckInfoDetail">
            <div>
              <Icon name="phone" />
              {phone}
            </div>
          </div>
          <div className="CheckInfoMessage">{t("kiosk_we_will_use_them_to_contact_you_if_necessary").message || "We will use them to contact you if necessary."}</div>
        </>
      )}

      {!extraPhone && phone && <div className="CheckInfoMessage">{t("kiosk_do_you_want_to_add_extra_phone").message || "Do you want to add an extra phone number?"}</div>}
      {extraPhone && (
        <>
          <div className="CheckInfoExtraPhone">{t("kiosk_additional_number").message || "Additional number"}</div>
          <div className="CheckInfoDetail">
            <div>
              <Icon name="phone" />
              {extraPhone}
            </div>
          </div>
        </>
      )}

      <div className="NavigationButtonContainer">
        <NavigationButton secondary onClick={onGoToAddPhoneNumber}>
          {!extraPhone && (t("kiosk_add_phone_number").message || "Add Phone Number")}
          {extraPhone && (t("kiosk_edit_phone_number").message || "Edit Phone Number")}
        </NavigationButton>
      </div>
    </div>
  );

  const mainButton = (
    <NavigationButton onClick={() => onGoToNextScreenWithState({ extraPhone: extraPhone || null })} disabled={!phone && !extraPhone}>
      {t("kiosk_next").message || "Next"}
    </NavigationButton>
  );

  return renderLayout({ headerButtons, mainButton, content });
};

export default withTranslation()(CheckInfo);
