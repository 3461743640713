import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox, faSlash } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import MainLayout from "../../../../../layout";
import { NavigationButton } from "../../../../../components";

import "./index.scss";

const KeyLockerFullScreen = ({ onGoBack, t }) => {
  const content = (
    <div className="KeyLockerFullScreen">
      <div className="KeyLockerTitle">
        <div className="KeyLockerFullCompositeIcon">
          <FontAwesomeIcon icon={faSlash} />
          <br />
          <FontAwesomeIcon icon={faInbox} />
        </div>
        <span>{t("kl_temporarily_unavailable").message || "Temporarily unavailable"}!</span>
      </div>
      <div className="KeyLockerFullMessage">
        {t("kl_full_message").message || "This keylocker is full, you cannot drop the key at the moment. Sorry for the inconvenience."}
      </div>
    </div>
  );

  const leftButton = <NavigationButton back onClick={onGoBack} />;

  return <MainLayout content={content} leftButton={leftButton} />;
};

export default withTranslation()(KeyLockerFullScreen);
