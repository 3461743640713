import React, { useState } from "react";
import { Grid, Modal, Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCarWash } from "@fortawesome/pro-regular-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

import { ActionButtons, Expandable } from "../../../../../components";
import { Responsive, MobileOnly, DesktopOnly } from "../../../../../layout";
import { round2 } from "../../../../../util/common";
import { ANSWERS } from "../../../../../util/answers";

import "./index.scss";

const OptionalServices = ({ settings, showMobile, optionalServices, updateOptionalServices, readOnly }) => {
  const { t } = useTranslation();
  const [iframeURL, setIframeURL] = useState(null);

  if (!optionalServices?.length) return null;

  const handleAcceptService = (id, action) => {
    if (readOnly) return;

    let service = optionalServices.find(os => os.id === id);
    if (service) {
      service.accepted = action === ANSWERS.ADD_TO_CART;
      updateOptionalServices(optionalServices);
    }
  };

  const convertYoutubeLink = link => {
    if (!link || !(link.includes("youtube.com") || link.includes("youtu.be"))) return link;

    const match = link.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);

    return "https://www.youtube.com/embed/" + match[2] + "?autoplay=1";
  };

  const renderServiceURL = service => {
    if (!service.info_url) return null;

    return (
      <FontAwesomeIcon
        icon={service.info_url.includes("youtube.com") || service.info_url.includes("youtu.be") ? faYoutube : faGlobe}
        className="optional-services-video-icon"
        onClick={() => setIframeURL(convertYoutubeLink(service.info_url.replace("http://", "https://")))}
      />
    );
  };

  const renderOptionalServices = () => {
    const { include_vat, vat } = settings;

    return optionalServices.map(service => (
      <Grid.Row key={"service_row_" + service.id} className="optional-services-item">
        <Grid.Column mobile={16} computer={4}>
          <span className="optional-services-name">{service.name}</span>
        </Grid.Column>
        <Grid.Column mobile={16} computer={6}>
          <Expandable text={service.description} className="optional-services-description" />
        </Grid.Column>
        <DesktopOnly>
          <Grid.Column computer={2} textAlign="center">
            {renderServiceURL(service)}
          </Grid.Column>
        </DesktopOnly>
        <Grid.Column mobile={16} computer={2}>
          {service.price > 0.01 && (
            <>
              {include_vat && <span className="optional-services-price">{`€ ${(round2(service.price) * (1 + vat / 100)).toFixed(2)}`}</span>}
              {!include_vat && <span className="optional-services-price">{`€ ${service.price.toFixed(2, 10)}`}</span>}
            </>
          )}
        </Grid.Column>
        <Grid.Column mobile={16} computer={2} className="optional-services-actions-container">
          {!(service.price > 0.01) && (
            <MobileOnly>
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
            </MobileOnly>
          )}
          <MobileOnly className="optional-services-action-url">{renderServiceURL(service)}</MobileOnly>

          <ActionButtons
            cartOnly
            status={service.accepted ? ANSWERS.ADD_TO_CART : ANSWERS.NOT_ANSWERED}
            onAnswer={action => handleAcceptService(service.id, action)}
            readOnly={readOnly}
          />
        </Grid.Column>
      </Grid.Row>
    ));
  };

  return (
    <Responsive showMobile={showMobile}>
      {
        <Modal open={iframeURL !== null} size="fullscreen">
          <Modal.Actions>
            <Button onClick={() => setIframeURL(null)} negative icon="times" />
          </Modal.Actions>
          <Modal.Content className="modalContent">
            <iframe
              scrolling="yes"
              frameBorder="0"
              style={{ height: "75vh" }}
              src={iframeURL}
              title={t("cc_additional_service_info").message || "Additional service info"}
              width="100%"
              allow="autoplay; encrypted-media"
            ></iframe>
          </Modal.Content>
        </Modal>
      }

      <Grid className="optional-services-container component-container">
        <Grid.Row className="optional-services-header">
          <FontAwesomeIcon icon={faCarWash} className="optional-services-header-icon" />
          <h4>{t("cc_additional_services").message || "Additional services"}</h4>
        </Grid.Row>
        <DesktopOnly>
          <Grid.Row className="optional-services-heading">
            <Grid.Column width={4}>
              <h3 className="optional-services-heading-title">{t("cc_SERVICE_NAME").message || "SERVICE NAME"}</h3>
            </Grid.Column>
            <Grid.Column width={6}>
              <h3 className="optional-services-heading-title">{t("cc_DESCRIPTION").message || "DESCRIPTION"}</h3>
            </Grid.Column>
            <Grid.Column width={2} textAlign="center">
              <h3 className="optional-services-heading-title">{t("cc_LINKS").message || "LINKS"}</h3>
            </Grid.Column>
            <Grid.Column width={2}>
              <h3 className="optional-services-heading-title">{t("cc_PRICE").message || "PRICE"}</h3>
            </Grid.Column>
            <Grid.Column width={2}>
              <h3 className="optional-services-heading-title align-center">{t("cc_ADD_CART").message || "ADD IN CART"}</h3>
            </Grid.Column>
          </Grid.Row>
        </DesktopOnly>
        <Grid verticalAlign="middle">{renderOptionalServices()}</Grid>
      </Grid>
    </Responsive>
  );
};

export default OptionalServices;
