import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { NavigationButton, HelpButton, CancelButton } from "../../../../../components";
import { NumPad } from "../../../../../../../components";

import Service from "./service";

import "./index.scss";

class EnterPIN extends Component {
  state = {
    pinCode: "",
    loading: false,
  };

  handleNumPress = num => {
    if (this.state.pinCode.length === 6) return;
    this.setState(({ pinCode }) => ({
      pinCode: (pinCode += num),
    }));
  };

  handleErasePinCode = () => {
    if (!this.state.pinCode.length) return;
    this.setState(({ pinCode }) => ({
      pinCode: pinCode.substring(0, pinCode.length - 1),
    }));
  };

  handleGoToPickupKeyScreen = () => {
    const pin = Number(this.state.pinCode);
    const private_key = this.props.match.params.keylockerKey;
    const { onGoToNextScreenWithState, onGoToErrorScreen } = this.props;

    this.setState({ loading: true }, () => {
      Service.auth({ private_key, pin })
        .then(res => {
          this.setState({ loading: false });

          const { box_number: pickUpBackDoor, is_admin: adminOpenDoor } = res?.data?.data || {};

          if (pickUpBackDoor) {
            onGoToNextScreenWithState({ pickUpBackDoor, pickUpBackPin: pin, adminOpenDoor });
          } else onGoToErrorScreen();
        })
        .catch(err => {
          this.setState({ loading: false }, () => onGoToErrorScreen());
          console.error("Error getting communication", err);
        });
    });
  };

  render() {
    const { pinCode, loading } = this.state;
    const { renderLayout, onGoToHelpScreen, onGoToCancelScreen, onGoBack, t } = this.props;

    const headerButtons = [
      <HelpButton disabled={loading} key={1} onClick={onGoToHelpScreen} />,
      <CancelButton disabled={loading} key={2} onClick={onGoToCancelScreen} />,
    ];

    const content = (
      <div className="EnterPinScreen">
        <div className="PinCodeInput">
          <div className="KeyLockerTitle">{t("kl_enter_pin_code").message || "Enter your PIN"}</div>
          <div className="PinCode">
            <div>{pinCode[0]}</div>
            <div>{pinCode[1]}</div>
            <div>{pinCode[2]}</div>
            <div>{pinCode[3]}</div>
            <div>{pinCode[4]}</div>
            <div>{pinCode[5]}</div>
          </div>

          {pinCode.length < 6 && <div className="PinCodeMessage">{t("kl_please_enter_pin_code").message || "Please enter the 6 digit PIN code that you received"}.</div>}
        </div>

        <NumPad onNumPress={this.handleNumPress} onErase={this.handleErasePinCode} />
      </div>
    );

    const leftButton = <NavigationButton back onClick={onGoBack} />;

    const rightButton = <NavigationButton disabled={pinCode.length < 6} loading={loading} onClick={this.handleGoToPickupKeyScreen} />;

    return renderLayout({ headerButtons, leftButton, rightButton, content });
  }
}

export default withRouter(withTranslation()(EnterPIN));
