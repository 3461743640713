import styled from "styled-components";

export const WrappedApp = styled.div`
  .bg-color,
  .bg-color.button.ui {
    background-color: ${props => props.color || "#11ca80"} !important;
  }

  .hover-bg-color:hover {
    background-color: ${props => props.color || "#11ca80"} !important;
    color: #fff;
  }

  .focus-bg-color:focus {
    background-color: ${props => props.color || "#11ca80"} !important;
    color: #fff;
  }

  .color,
  .color.ui.button {
    color: ${props => props.color || "#11ca80"} !important;
  }

  .box-shadow-color,
  .box-shadow-color.ui.button {
    box-shadow: 0 0 0 1px ${props => props.color || "#11ca80"} inset;
  }

  .align-center {
    text-align: center;
  }

  .mobile-heading {
    background-color: #fff;

    h1 {
      color: #1c3e52;
      padding: 20px 0 18px 14px;
      line-height: 24px;
    }
  }
`;
