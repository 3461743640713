import React, { useState } from "react";
import { Input, Icon, Form, TextArea } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import UploadButton from "./uploadButton";
import "./mobileRemarks.scss";

import { faPencil, faFileImage, faTrash } from "@fortawesome/pro-regular-svg-icons";

const MobileRemarks = ({
  errorMsg,
  loading,
  readOnly,
  remark,
  handleChangeRemarkTitle,
  handleChangeRemarkDescription,
  handleUploadURL,
  handleRemoveUploadedFile,
  shown,
}) => {
  const { t } = useTranslation();
  const [showRemark, setShowRemark] = useState(shown);

  return (
    <div className="custom-remarks-container mobile component-container">
      <div className="custom-remarks-header-container" onClick={() => setShowRemark(!showRemark)}>
        <div className="custom-remarks-title-container">
          <FontAwesomeIcon icon={faPencil} className="custom-remarks-header-icon" />
          <h4>{t("cc_additional_remarks").message || "Additional remarks"}</h4>
        </div>
        <Icon name={showRemark ? "caret up" : "caret down"} float="right" />
      </div>
      {showRemark && (
        <div>
          <div>
            <p className="add-remark-label">{t("cc_enter_subject").message || "Enter your subject"}:</p>
            <Input disabled={readOnly} className="add-remark-title-field" value={remark.title} onChange={handleChangeRemarkTitle} />
          </div>
          <div style={{ marginTop: "1em" }}>
            <p className="add-remark-label">{t("cc_description").message || "Description"}:</p>
            <Form>
              <TextArea disabled={readOnly} className="add-remark-description-field" value={remark.description} onChange={handleChangeRemarkDescription} />
            </Form>
          </div>

          {(!readOnly || (remark.attachments && remark.attachments.length > 0)) && (
            <div className="add-remark-upload-files-mobile-container">
              <UploadButton disabled={readOnly} loading={loading} handleUploadURL={handleUploadURL} translation_key="cc_UPLOAD_FILES" />

              {remark.attachments && remark.attachments.length > 0 && (
                <div className="add-remark-uploaded-files-mobile-container" style={{ height: "auto" }}>
                  <h5 className="add-remark-uploaded-files-mobile-title">{t("cc_uploaded_files").message || "Uploaded files"}:</h5>
                  {remark.attachments.map((a, idx) => (
                    <div className="add-remark-uploaded-files-item-container" key={"attachement" + idx}>
                      <div className="add-remark-uploaded-files-file-container">
                        <FontAwesomeIcon className="add-remark-uploaded-files-image-icon" icon={faFileImage} />
                        <a rel="noopener noreferrer" href={a.url} target="_blank">
                          <span className="add-remark-uploaded-files-attachement-name">{a.name}</span>
                        </a>
                      </div>
                      {!readOnly && <FontAwesomeIcon className="add-remark-uploaded-files-icon" icon={faTrash} onClick={() => handleRemoveUploadedFile(idx)} />}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <p className="custom-remarks-error-msg">{errorMsg}</p>
        </div>
      )}
    </div>
  );
};

export default MobileRemarks;
