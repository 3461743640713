import React from "react";
import { withTranslation } from "react-i18next";

import "./index.scss";

let SimpleImageGallery = ({ lettersMap, t }) => {
  if (!Array.isArray(lettersMap) || lettersMap.length < 1) return null;

  return (
    <div className="simple-car-images-container">
      <h4>{t("cc_detail_photo").message || "Detail Photos"}</h4>
      <div>
        {lettersMap.map((lm, offset) => {
          if (Object.keys(lm).length < 1) return null;

          const text = lm[Object.keys(lm)[0]].text;

          return (
            <div className="simple-car-images-description" key={offset}>
              <div className={text ? "simple-car-images-inner-container" : "simple-car-images-inner-container full"}>
                {Object.keys(lm).map(url => (
                  <div className="simple-car-image-wraper" key={url}>
                    <div className="simple-car-img-label">[{lm[url].letter}]</div>
                    <img alt="" src={url} />
                  </div>
                ))}
              </div>
              {text && <div className="simple-car-images-description-text">{text}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

SimpleImageGallery = withTranslation()(SimpleImageGallery);

SimpleImageGallery.getLettersMap = (appointment, fixedItems) => {
  let map = [];
  let currentLetter = 97;

  if (Array.isArray(appointment.final_check_images)) {
    appointment.interventions
      .filter(i => i.mechanic_fixed)
      .forEach(i => {
        let m = [];
        appointment.final_check_images
          .filter(img => img.intervention_index === i.intervention_index)
          .forEach(img => {
            m[img.url] = { letter: String.fromCharCode(currentLetter), text: i.description };
            currentLetter++;
          });

        map.push(m);
      });
  }

  fixedItems.forEach(i => {
    if (Array.isArray(i.images)) {
      let m = [];
      i.images.forEach(img => {
        m[img.url] = { letter: String.fromCharCode(currentLetter), text: i.mechanic_notes };
        currentLetter++;
      });

      map.push(m);
    }
  });

  return map;
};

export default SimpleImageGallery;
