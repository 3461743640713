import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import { ImageLetters } from "../";
import { TYRE_POSITION } from "../../../../util/tyre";
import { ConditionIcon } from "../../../../components/";
import { getItemName, QUESTION_RESULT_STATUS } from "../../../../util/common";

import "./index.scss";

function filterNotWorkedOnItemsWithRemarks(notWorkedOnItems) {
  return notWorkedOnItems.filter(
    r =>
      r.question_status === QUESTION_RESULT_STATUS.NOT_WORKED_ON &&
      ((TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_notes)) || r.mechanic_notes)
  );
}

class NotWorkedOnItems extends Component {
  render() {
    let { notWorkedOnItems, t } = this.props;
    const withRemarks = filterNotWorkedOnItemsWithRemarks(notWorkedOnItems);

    let groups = [];
    let i = -1;
    let last_group = "";

    notWorkedOnItems.forEach(r => {
      if (r.mechanic_notes || (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_notes))) return;

      if (r.group_name !== last_group) {
        last_group = r.group_name;
        i++;
        groups[i] = [];
      }

      groups[i].push(r);
    });

    if (withRemarks.length < 1 && groups.length < 1) return null;

    return (
      <Grid className="report-not-worked-on-items-container component-container">
        <Grid.Row width={16} className="not-worked-on-items-heading">
          <FontAwesomeIcon icon={faTimesCircle} className="not-worked-on-items-heading-icon" />
          <h4 className="not-worked-on-items-heading-title">{t("cc_not_applied_items").message || "Not applied items"}</h4>
        </Grid.Row>

        {withRemarks.length > 0 && (
          <Grid className="not-worked-on-items-with-remarks" verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={7} className="not-worked-on-items-column-title">
                {t("cc_item_name").message || "ITEM NAME"}
              </Grid.Column>
              <Grid.Column width={7} className="not-worked-on-items-column-title">
                {t("cc_remarks").message || "REMARKS"}
              </Grid.Column>
              <Grid.Column width={2} className="not-worked-on-items-column-title align-center">
                {t("cc_photo").message || "PHOTO"}
              </Grid.Column>
            </Grid.Row>

            {withRemarks.map(result => (
              <Grid.Row key={result.question_result_id * 10 + result.tyre_position} className="not-worked-on-items-row">
                <Grid.Column width={7}>
                  <ConditionIcon status={4} size="small" />
                  <span className="not-worked-on-items-title">{getItemName(this.props.t, result)}</span>
                </Grid.Column>
                <Grid.Column width={7} className="not-worked-on-items-remarks">
                  {result.mechanic_notes && <span>{result.mechanic_notes}</span>}
                </Grid.Column>
                <Grid.Column width={2} className="not-worked-on-items-images">
                  <ImageLetters images={result.images} lettersMap={this.props.lettersMap} />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        )}

        <Grid className="not-worked-on-items-groups">
          <Grid.Column width={8}>
            {groups
              .filter((g, i) => i % 2 === 0)
              .map((group, idx) => (
                <Grid.Row key={"evengroup_name" + idx}>
                  <h3 className="not-worked-on-items-group-name-title">{group[0].group_name}</h3>
                  {group.map(result => (
                    <div className="not-worked-on-items-group-name-item-box" key={"evengroup" + idx + "question" + result.question_result_id + result.tyre_position}>
                      <ConditionIcon status={4} size="small" />
                      <span className="not-worked-on-items-group-title">{result.title}</span>
                    </div>
                  ))}
                </Grid.Row>
              ))}
          </Grid.Column>
          <Grid.Column width={8}>
            {groups
              .filter((g, i) => i % 2 !== 0)
              .map((group, idx) => (
                <Grid.Row key={"oddgroup_name" + idx}>
                  <h3 className="not-worked-on-items-group-name-title">{group[0].group_name}</h3>
                  {group.map(result => (
                    <div className="not-worked-on-items-group-name-item-box" key={"oddgroup" + idx + "question" + result.question_result_id + result.tyre_position}>
                      <ConditionIcon status={4} size="small" />
                      <span className="not-worked-on-items-group-title">{result.title}</span>
                    </div>
                  ))}
                </Grid.Row>
              ))}
          </Grid.Column>
        </Grid>
      </Grid>
    );
  }
}

const TranslatedNotWorkedOnItems = withTranslation()(NotWorkedOnItems);

TranslatedNotWorkedOnItems.filterNotWorkedOnItems = results => results.filter(r => r.question_status === QUESTION_RESULT_STATUS.NOT_WORKED_ON);

TranslatedNotWorkedOnItems.filterNotWorkedOnItemsWithRemarks = filterNotWorkedOnItemsWithRemarks;

export default TranslatedNotWorkedOnItems;
