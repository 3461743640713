import axios from "axios";

import ENV from "../../../../../../../util/env-config";

class Service {
  static initPickupScan(requestData) {
    return axios.post(ENV.keylockerBase + "/pickup/init", requestData);
  }

  static verifyPickupScan(requestData) {
    return axios.post(ENV.keylockerBase + "/pickup/verify", requestData);
  }

  static verifyPickupOpened(requestData) {
    return axios.post(ENV.keylockerBase + "/pickup/opened", requestData);
  }

  static relayError(requestData) {
    return axios.post(ENV.keylockerBase + "/keylockers/relay_error", requestData);
  }

  static openKeyLocker(locker, softmode) {
    if (softmode) {
      if (!this.failed) {
        this.failed = true;
        return Promise.reject({ message: "Softmode: Error opening keylocker box" });
      }

      return Promise.resolve();
    }

    return axios.get(`${ENV.keylockerRelayIPAddress}/state.xml?relay${locker}State=2`);
  }
}

export default Service;
