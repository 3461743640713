import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { NavigationButton, HelpButton, CancelButton } from "../../../components";

import { ScheduledServices, OptionalServices, TotalAmount, Agreements, Remarks, CustomerName } from "./components";

import "./Order.scss";

class Order extends Component {
  render() {
    const {
      renderLayout,
      agreements,
      communication,
      keylocker,
      customerName,
      showCustomerNameError,
      showAgreementError,
      onGoToHelpScreen,
      onGoToCancelScreen,
      onGoToNextScreen,
      onGoToEditRemarks,
      onGoToEditCustomerName,
      additionalRemark,
      handleAgreementChecked,
      t,
    } = this.props;

    const headerButtons = [<HelpButton key={1} onClick={onGoToHelpScreen} />, <CancelButton key={2} onClick={onGoToCancelScreen} />];

    const content = (
      <div className="OrderScreen">
        <div className="OrderHeader">
          <div className="reg-num-box">
            <div className="reg-country">
              <div className="reg-country-text">{"NL"}</div>
            </div>
            <div className="reg-num">{communication.appointment.reg_number.split("-").join(" - ")}</div>
          </div>
          <div className="OrderCarMake">
            <FontAwesomeIcon icon={faCar} className="car-icon" />
            {`${communication.appointment.car_make} ${communication.appointment.car_model}`}
          </div>
        </div>
        <div className="OrderServices" id="OrderServices">
          <ScheduledServices communication={communication} keylocker={keylocker} />
          <OptionalServices communication={communication} keylocker={keylocker} />
          <TotalAmount communication={communication} keylocker={keylocker} />
          <Agreements agreements={agreements} showAgreementError={showAgreementError} handleAgreementChecked={handleAgreementChecked} />
          <Remarks additionalRemark={additionalRemark} />
          <CustomerName customerName={customerName} showCustomerNameError={showCustomerNameError} onGoToEditCustomerName={onGoToEditCustomerName} />
        </div>
      </div>
    );

    const rightButton = <NavigationButton onClick={onGoToNextScreen} />;

    const leftButton = (
      <NavigationButton secondary onClick={onGoToEditRemarks}>
        {!additionalRemark && (t("kl_add_remarks").message || "Add Remarks")}
        {additionalRemark && (t("kl_edit_remarks").message || "Edit Remarks")}
      </NavigationButton>
    );

    return renderLayout({ headerButtons, rightButton, leftButton, content });
  }
}

export default withTranslation()(Order);
