import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button } from "semantic-ui-react";

const AgreementModal = ({ isOpen, onClose, selectedAgreement, t }) => {
  return (
    <Modal size={"large"} open={isOpen}>
      <Modal.Header>{selectedAgreement?.name}</Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: selectedAgreement?.text }}></div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} color="grey" size="massive">
          {t("kl_close").message || "Close"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(AgreementModal);
