import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import MainLayout from "../../../layout";

import "./index.scss";

const ThankYouScreen = ({ t, onStartOver }) => {
  setTimeout(() => {
    onStartOver();
  }, 8000);

  const content = (
    <div className="PickUp_Key_ThankYouScreen">
      <div className="KeyLockerTitle">
        <FontAwesomeIcon icon={faThumbsUp} className="ThankYouIcon" />
        {t("kl_thank_you").message || "Thank You"}
      </div>
      <div className="ThankYouMessage">{t("kl_looking_foward_thank_you").message || "We are looking forward to see you again"}</div>
    </div>
  );

  return <MainLayout content={content} />;
};

export default withTranslation()(ThankYouScreen);
