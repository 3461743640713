import React from "react";
import { withTranslation } from "react-i18next";

import "./index.scss";

const Signatures = ({ signatureItems, t, settings, communication }) => {
  const { show_mechanic_name, mechanic_name, is_employee_name_visible } = settings;
  const { driver_title, driver_firstname, driver_surname } = communication.appointment;

  const customerName = `${driver_title} ${driver_firstname} ${driver_surname}`.trim();

  return (
    <div className="signatures-container component-container">
      <div className="signature-title-container">
        {is_employee_name_visible && show_mechanic_name && (
          <div className="checked-by-text">
            <b>{t("cc_checked_by").message || "Checked by"}:</b> <i>{mechanic_name}</i>
          </div>
        )}
      </div>
      <div className="signature-container">
        {Array.isArray(signatureItems) &&
          signatureItems.length > 0 &&
          signatureItems.map(signature => (
            <div className="signature-image-box" key={signature.answer_item.id}>
              <img alt="signature" className="signature-image" src={signature.images[0].url} />

              <div className="signature-name">
                {signature.answer_item.template === "employee_signature" && settings.mechanic_name && (
                  <>
                    {t("cc_signed_by").message || "Signed by"}: {settings.mechanic_name}
                  </>
                )}

                {signature.answer_item.template === "customer_signature" && customerName && (
                  <>
                    {t("cc_signed_by").message || "Signed by"}: {customerName}
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const TranslatedSignatures = withTranslation()(Signatures);

TranslatedSignatures.filterSignatureItems = function(results) {
  return results.filter(result => ["signature", "employee_signature", "customer_signature"].includes(result.answer_item?.template) && result.images?.length > 0);
};

export default TranslatedSignatures;
